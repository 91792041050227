import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';

const mapStateToProps = state => {
	return {
		activeLevel: Select.areas.areaTreeLevels.getActive(state)?.data?.level,
		activePlaces: Select.places.getActiveModels(state),
		activeHydrobasinKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
