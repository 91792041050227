import Select from '../../../Select';
import config from '../../../../config';
import request from '../../../../utils/request';
import ActionTypes from '../../../../constants/ActionTypes';

/**
 * Use data for feature
 * @param featureKeys {Array} feature key
 */
const use = featureKeys => {
	return (dispatch, getState) => {
		const activePlace = Select.places.getActive(getState());
		const placeName = activePlace?.data?.nameInternal;

		if (placeName && featureKeys?.length) {
			featureKeys.forEach(featureKey => {
				const point = Select.worldWater.waterLevel.points.getByKey(
					getState(),
					featureKey
				);
				if (!point) {
					dispatch(ensureDataForPoint(placeName, featureKey));
				}
			});
		}
	};
};

/**
 * @param place {string} Panther place's nameInternal, which is used as directory name
 * @param featureKey {string} feature key, which is used as a filename
 */
const ensureDataForPoint = (place, featureKey) => {
	return dispatch => {
		let url = `${config.timeSerieDataUrl}/${place}/${featureKey}.json`;

		return request(url, 'GET', null, null).then(data => {
			if (data) {
				const point = {
					key: featureKey,
					data,
				};
				dispatch(actionAdd([point]));
			} else {
				console.warn(`No data for feature ${featureKey} in ${place}!`);
			}
		});
	};
};

const actionAdd = data => {
	return {
		type: ActionTypes.WORLD_WATER.WATER_LEVEL.POINTS.ADD,
		data,
	};
};

export default {use};
