import IndicatorSwitcher from './switchers/IndicatorSwitcher';
// import SatelliteSwitcher from './switchers/SatelliteSwitcher';
import ValueTypeSwitcher from './switchers/ValueTypeSwitcher';

import './style.scss';

const WaterLevelPanelConfig = () => {
	return (
		<div className="worldWater-WaterLevelPanelConfig">
			<IndicatorSwitcher icon="ri-water" />
			{/*<SatelliteSwitcher icon="ri-satellite" />*/}
			<ValueTypeSwitcher icon="ri-vertical-align-center" />
		</div>
	);
};

WaterLevelPanelConfig.propTypes = {};

export default WaterLevelPanelConfig;
