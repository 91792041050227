import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../state/Select';
import Action from '../../../../../../state/Action';

import getPlaceKey from '../../../helpers/getPlaceKey';
import placeSelectMapData from '../../../../../../data/maps/placeSelectMap';
import placeSelectSelectionData from '../../../../../../data/selections/placeSelectMapSelection';

import Presentation from './presentation';

const mapStateToProps = state => {
	const currentRouter = Select.router.getCurrent(state);
	const urlPlaceKey = getPlaceKey(currentRouter?.params?.path?.urlPlaceKey);
	const activePlaceKey = Select.places.getActiveKey(state) || urlPlaceKey;
	return {
		activePlaceKey,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.maps.addMap(placeSelectMapData));
			dispatch(Action.selections.add([placeSelectSelectionData]));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
