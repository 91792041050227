import {
	distinctColours,
	statisticsHydrobasinSelectionKey,
} from '../../constants/app';

export default {
	key: statisticsHydrobasinSelectionKey,
	data: {
		distinctItems: true,
		colourPalette: distinctColours,
		featureKeysFilter: {
			keys: [],
		},
		style: {
			outlineWidth: 3,
			outlineColor: '#25ccff',
			outlineOpacity: 1,
			fill: '#25ccff',
			fillOpacity: 0.1,
		},
	},
};
