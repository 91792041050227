// TODO from view.state.components
// TODO metadata structure adopted from VISAT SDG
const theme = {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base20)',
				strokeWidth: 2,
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base20)',
			},
			text: {
				fill: 'var(--base50)',
				fontSize: 12,
			},
		},
		legend: {
			text: {
				fontWeight: 'bold',
				fill: 'var(--base50)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base15)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base40)',
		},
	},
};

/* LINE CHART */
const lineChartMetadata = {
	extendLines: true,
	settings: {
		theme,
		colors: serie => serie.color,
		margin: {top: 10, right: 10, bottom: 40, left: 95},
		xScale: {
			type: 'time',
			format: '%Y-%m-%dT%H:%M:%S%Z',
		},
		xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
		yScale: {
			type: 'linear',
			min: 'auto',
		},
		axisTop: null,
		axisRight: null,
		axisLeft: {
			format: value => value.toLocaleString(),
			tickValues: 6,
		},
		enableGridX: false,
		gridYValues: 6,
		gridXValues: 6,
		pointSize: 0,
		useMesh: true,
		animate: false,
		motionConfig: 'default',
		markers: [
			{
				axis: 'y',
				value: 0,
				lineStyle: {stroke: 'var(--base30)', strokeWidth: 2},
			},
		],
		legends: [],
	},
};

const lineOverviewChartMetadata = {
	settings: {
		theme,
		colors: serie => serie.color,
		margin: {top: 5, right: 0, bottom: 5, left: 0},
		xScale: {
			type: 'time',
			format: '%Y-%m-%dT%H:%M:%S%Z',
		},
		xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
		yScale: {
			type: 'linear',
			min: 'auto',
		},
		axisTop: null,
		axisRight: null,
		axisLeft: null,
		axisBottom: null,
		enableGridX: false,
		enableGridY: false,
		pointSize: 0,
		useMesh: false,
		animate: false,
	},
};

/* SCATTER CHART */
const scatterChartMetadata = {
	settings: {
		theme,
		margin: {top: 10, right: 10, bottom: 40, left: 40},
		xScale: {
			type: 'time',
			format: '%Y-%m-%dT%H:%M:%S%Z',
		},
		xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
		yScale: {
			type: 'linear',
			min: 'auto',
		},
		axisTop: null,
		axisRight: null,
		axisLeft: {
			format: value => value.toLocaleString(),
			tickValues: 6,
		},
		enableGridX: false,
		gridYValues: 6,
		gridXValues: 6,
		nodeSize: 6,
		useMesh: true,
		animate: false,
		markers: [
			{
				axis: 'y',
				value: 0,
				lineStyle: {stroke: 'var(--base30)', strokeWidth: 2},
			},
		],
		layers: [
			'grid',
			'axes',
			'markers',
			'nodes',
			'mesh',
			'legends',
			'annotations',
		],
		legends: [],
	},
};

const scatterOveriewChartMetadata = {
	settings: {
		theme,
		margin: {top: 5, right: 0, bottom: 5, left: 0},
		xScale: {
			type: 'time',
			format: '%Y-%m-%dT%H:%M:%S%Z',
		},
		xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
		yScale: {
			type: 'linear',
			min: 'auto',
		},
		axisTop: null,
		axisRight: null,
		axisLeft: null,
		axisBottom: null,
		enableGridX: false,
		enableGridY: false,
		nodeSize: 4,
		useMesh: false,
		animate: false,
	},
};

export default {
	scatterChart: {
		metadata: scatterChartMetadata,
		overviewChartMetadata: scatterOveriewChartMetadata,
	},
	lineChart: {
		metadata: lineChartMetadata,
		overviewChartMetadata: lineOverviewChartMetadata,
	},
};
