import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {isArray as _isArray} from 'lodash';
import StatisticsSelect from '../StatisticsSelect';
import utils from '../../../../../utils';
import './style.scss';

const PlaceSelect = ({
	activeLevel,
	activePlaceKeys,
	places,
	onActivePlacesChange,
	isMulti,
	onMount,
	onUnmount,
	selectionData,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const options = places?.map(place => {
		return {
			value: place.key,
			label: place.data.nameDisplay,
			color: utils.getSelectedFeaturePrimaryColor(
				place.data.nameInternal,
				selectionData
			),
		};
	});
	const values =
		activePlaceKeys?.length > 0 &&
		options?.filter(option => activePlaceKeys.includes(option.value));

	const setActivePlaceKeys = places => {
		places = _isArray(places) ? places : [places];
		onActivePlacesChange(places.map(place => place.value));
	};

	return (
		<StatisticsSelect
			className="worldWater-StatisticsPlaceSelect"
			placeholder={isMulti ? 'Select countries...' : 'Select country...'}
			options={options}
			value={values}
			isDisabled={activeLevel?.data?.level === 2}
			onChange={setActivePlaceKeys}
			isSearchable
			isMulti={isMulti}
		/>
	);
};

PlaceSelect.propTypes = {
	activeLevel: PropTypes.object,
	activePlaceKeys: PropTypes.array,
	places: PropTypes.array,
	isMulti: PropTypes.bool,
	onActivePlacesChange: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	selectionData: PropTypes.array,
};

export default PlaceSelect;
