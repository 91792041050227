import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {PantherSelect, PantherSelectItem} from '@gisatcz/ptr-atoms';
import ViewCard from './ViewCard';
import './style.scss';

const ViewSelect = ({
	onUnmount,
	loadViews,
	activeView,
	activePlaceKey,
	activeViewKey,
	views = [],
	onViewChange,
	open,
	onSetOpen,
	closable,
	onSetClosable,
}) => {
	const activeViewKeyRef = useRef();

	useEffect(() => {
		if (activeViewKeyRef.current !== activeViewKey) {
			if (!activeViewKey) {
				onSetOpen(true);
				onSetClosable(false);
			} else {
				onSetClosable(true);
				onSetOpen(false, true);
			}
			activeViewKeyRef.current = activeViewKey;
		}
	}, [activeViewKey]);

	const renderCurrent = () => {
		if (activeViewKey) {
			return (
				<div className="worldWater-ViewSelect-current-value">
					<span>View</span>
					<div>{activeView?.data?.nameDisplay}</div>
				</div>
			);
		} else {
			//no indicator
			return (
				<span className="worldWater-ViewSelect-current-placeholder">
					<span>View</span>
					<div>Select...</div>
				</span>
			);
		}
	};

	useEffect(() => {
		return onUnmount;
	}, []);

	useEffect(() => {
		if (activePlaceKey) {
			loadViews();
		}
	}, [activePlaceKey]);

	const setPantherSelectOpen = (open, force) => {
		if (force || closable) {
			onSetOpen(open);
		}
	};

	// Temporary disable water level view
	const isViewDisabled = viewKey =>
		views.some(
			view => view.key === viewKey && view.data.nameInternal === 'water-level'
		);

	return (
		<PantherSelect
			className="worldWater-ViewSelect"
			open={open}
			onSelectClick={() => setPantherSelectOpen(!open)}
			onSelect={viewKey => {
				onViewChange(activePlaceKey, viewKey);
				setPantherSelectOpen(!open, true);
			}}
			currentClasses="worldWater-ViewSelect-current"
			renderCurrent={renderCurrent}
			listClasses="worldWater-ViewSelect-list"
		>
			<div className="worldWater-ViewSelect-content">
				<div className="worldWater-ViewCardsContainer">
					<div>
						{views.map(view => (
							<PantherSelectItem
								className={`worldWater-ViewCard-wrapper ${
									isViewDisabled(view.key) ? '' : ''
								}`}
								itemKey={view.key}
								key={view.key}
							>
								<ViewCard name={view?.data?.nameDisplay} viewName={view?.key} />
							</PantherSelectItem>
						))}
					</div>
				</div>
			</div>
		</PantherSelect>
	);
};

ViewSelect.propTypes = {
	activePlaceKey: PropTypes.string,
	activeView: PropTypes.shape({
		data: PropTypes.shape({
			nameDisplay: PropTypes.any,
		}),
	}),
	activeViewKey: PropTypes.string,
	loadViews: PropTypes.func,
	onUnmount: PropTypes.func,
	onViewChange: PropTypes.func,
	open: PropTypes.bool,
	onSetOpen: PropTypes.func,
	closable: PropTypes.bool,
	onSetClosable: PropTypes.func,
	views: PropTypes.array,
};

export default ViewSelect;
