import PropTypes from 'prop-types';
import './style.scss';

const PlaceSelectMapTooltip = ({showTooltip, name}) => {
	return showTooltip ? (
		<div className="worldWater-PlaceSelectMapTooltip">{name}</div>
	) : null;
};

PlaceSelectMapTooltip.propTypes = {
	name: PropTypes.string,
	showTooltip: PropTypes.bool,
};

export default PlaceSelectMapTooltip;
