import PropTypes from 'prop-types';
import {cloneElement, useEffect, useRef, useState} from 'react';
import NivoChartHover from './NivoChartHover';
import {HoverHandler, getTootlipPosition} from '@gisatcz/ptr-core';

const TOOLTIP_PADDING = 8;

const getHorizontalTooltipStyle = (
	wrapperRef,
	chartType,
	wrapperRefQuery,
	tooltipPosition
) => {
	if (!wrapperRef.current || !chartType) {
		return () => {
			return () => {
				return () => {};
			};
		};
	}

	const referencePoint = tooltipPosition ? tooltipPosition : 'center';

	return () => {
		return () => {
			const windowScrollTop = wrapperRef.current?.getBoundingClientRect().top;
			const windowScrollLeft = wrapperRef.current?.getBoundingClientRect().left;
			const windowHeight = wrapperRef.current?.getBoundingClientRect().height;
			const windowWidth = wrapperRef.current?.getBoundingClientRect().width;

			//map box

			const windowBBox = [
				windowScrollTop,
				windowScrollLeft + windowWidth,
				windowScrollTop + windowHeight,
				windowScrollLeft,
			];

			// return (position,origPosX,origPosY,width,height,hoveredElemen) => {
			return (origPosX, origPosY, width, height) => {
				const lineChartElm = wrapperRef.current.querySelector(
					wrapperRefQuery ? wrapperRefQuery : 'svg>g>g:nth-of-type(6)'
				);
				const scatterChartElm = wrapperRef.current.querySelector(
					wrapperRefQuery ? wrapperRefQuery : 'svg>g>g:nth-of-type(5)>rect'
				);
				const wrapperElm =
					chartType === 'scatterChart' ? scatterChartElm : lineChartElm;

				const wrapperLeft = wrapperElm?.getBoundingClientRect().left;
				const wrapperWidth = wrapperElm?.getBoundingClientRect().width;
				const wrapperTop = wrapperElm?.getBoundingClientRect().top;

				const position = getTootlipPosition(
					referencePoint,
					['top', 'bottom', 'right', 'left'],
					windowBBox,
					TOOLTIP_PADDING
				)(
					referencePoint === 'center'
						? origPosX + wrapperLeft
						: origPosX < wrapperWidth - width - 10
						? origPosX + wrapperLeft + 10
						: origPosX + wrapperLeft - width - 10,
					origPosY + wrapperTop,
					width,
					height
				);
				return {top: position.top, left: position.left};
			};
		};
	};
};
const NivoChartHoverWrapper = ({
	chartType,
	children,
	getHoverContent,
	wrapperRefQuery,
	tooltipPosition,
}) => {
	const wrapperRef = useRef();
	const [getHorizontalTooltipStyleFunction, setHorizontalTooltipStyleFunction] =
		useState();

	useEffect(() => {
		setHorizontalTooltipStyleFunction(
			getHorizontalTooltipStyle(
				wrapperRef,
				chartType,
				wrapperRefQuery,
				tooltipPosition
			)
		);
	}, [chartType, wrapperRef]);

	return (
		<HoverHandler getStyle={getHorizontalTooltipStyleFunction}>
			<NivoChartHover getHoverContent={getHoverContent}>
				{cloneElement(children, {ref: wrapperRef})}
			</NivoChartHover>
		</HoverHandler>
	);
};

NivoChartHoverWrapper.propTypes = {
	getHoverContent: PropTypes.func,
	chartType: PropTypes.string,
	children: PropTypes.node,
	wrapperRefQuery: PropTypes.string,
	tooltipPosition: PropTypes.string,
};

export default NivoChartHoverWrapper;
