import PropTypes from 'prop-types';

const LayerTemplateTitle = ({layerTemplate}) => {
	const title = layerTemplate?.data?.nameDisplay;
	return (
		<div className="worldWater-MapLegendTitle-name">
			{title?.charAt(0).toUpperCase() + title?.slice(1)}
		</div>
	);
};

LayerTemplateTitle.propTypes = {
	layerTemplate: PropTypes.object,
};

export default LayerTemplateTitle;
