import PropTypes from 'prop-types';
import {connect} from '@gisatcz/ptr-state';
import DashboardSection from '../DashboardSection';
import LevelChartSwitcher from './LevelChartSwitcher';
import NivoChartHoverWrapper from '../../../../../../common/NivoChartHover/NivoChartHoverWrapper';
import Select from '../../../../../../../state/Select';
import {waterLevelChartType} from '../../../../../../../constants/app';
import LevelChart from './LevelChart';

import './style.scss';

const ProgressChartSection = ({
	activeChartKey,
	activeIndicatorKey,
	chartRef,
}) => {
	let title = 'Water level over time [m]';
	if (activeIndicatorKey === 'waterVolume') {
		title = (
			<>
				Water volume over time [m<sup>3</sup>]
			</>
		);
	} else if (activeIndicatorKey === 'waterExtent') {
		title = (
			<>
				Water extent over time [km<sup>2</sup>]
			</>
		);
	}

	return (
		<DashboardSection
			title={title}
			className="worldWater-ProgressChartSection"
			Tools={[LevelChartSwitcher]}
			chartRef={chartRef}
		>
			<NivoChartHoverWrapper chartType={activeChartKey}>
				<LevelChart />
			</NivoChartHoverWrapper>
		</DashboardSection>
	);
};

ProgressChartSection.propTypes = {
	activeChartKey: PropTypes.string,
	activeIndicatorKey: PropTypes.string,
	chartRef: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		activeChartKey: Select.components.get(state, waterLevelChartType, 'active'),
		activeIndicatorKey:
			Select.worldWater.waterLevel.indicators.getActiveKey(state),
	};
};

export default connect(mapStateToProps)(ProgressChartSection);
