import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSection from '../components/TourStepsSection';
import TourStepsTitle from '../components/TourStepsTitle';

import '../style.scss';

const Views = () => (
	<TourStepsContainer>
		<TourStepsTitle>Views</TourStepsTitle>
		<TourStepsSection>Select one of the two predefined views:</TourStepsSection>
		<TourStepsSection>
			<div>
				<b>Water Extent</b> - provides access to the basic SWD products in pilot
				areas of interest (AOI).
			</div>
			<div>
				<b>Surface water levels and volume</b> - provides access to long-term
				measurements of water level, volume and extents of selected rivers and
				water bodies in pilot AOI
			</div>
		</TourStepsSection>
	</TourStepsContainer>
);

export default Views;
