import PropTypes from 'prop-types';
import ButtonSwitcher from '../../../../../../../../common/Switcher';
import {useEffect} from 'react';

const SeasonalSwitcher = ({activeOptionKey, onActiveOptionChange, onMount}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
	}, []);

	return (
		<ButtonSwitcher
			condensed
			icon="ri-hourglass"
			activeOptionKey={activeOptionKey}
			options={[
				{key: '1', data: {nameDisplay: '0-1'}},
				{key: '2', data: {nameDisplay: '2-6'}},
				{key: '3', data: {nameDisplay: '7-11 months'}},
			]}
			onActiveOptionChange={onActiveOptionChange}
		/>
	);
};

SeasonalSwitcher.propTypes = {
	activeOptionKey: PropTypes.string,
	onActiveOptionChange: PropTypes.func,
	onMount: PropTypes.func,
};

export default SeasonalSwitcher;
