import PropTypes from 'prop-types';
import {useState} from 'react';
import TimeRangeChart from '../../../../../../../common/TimeRangeChart';
import LineChart from '../../../../../../../common/LineChart';
import ChartWrapper from '../ChartWrapper';
import NivoChartHoverWrapper from '../../../../../../../common/NivoChartHover/NivoChartHoverWrapper';
import moment from 'moment';
import {Node} from '../../../../../../ProductExplorer/components/WaterLevelPanel/WaterLevelDashboard/ProgressChartSection/LevelChart/index';
import ValueTypeSwitcher from '../switchers/ValueTypeSwitcher';
import SeasonalSwitcher from '../switchers/SeasonalSwitcher';

const theme = {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base20)',
				strokeWidth: 2,
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base20)',
			},
			text: {
				fill: 'var(--base50)',
				fontSize: 12,
			},
		},
		legend: {
			text: {
				fontWeight: 'bold',
				fill: 'var(--base50)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base15)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base40)',
		},
	},
};

const overviewChartMetadata = {
	settings: {
		theme,
		colors: serie => serie.color,
		margin: {top: 5, right: 0, bottom: 5, left: 0},
		xScale: {
			type: 'time',
			format: '%Y-%m-%dT%H:%M:%S%Z',
		},
		// xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
		yScale: {
			type: 'linear',
			min: 'auto',
		},
		axisTop: null,
		axisRight: null,
		axisLeft: null,
		axisBottom: null,
		enableGridX: false,
		enableGridY: false,
		pointSize: 0,
		useMesh: false,
		animate: false,
	},
};

const getSeasonalWaterExtentProgress = point => {
	if (!point?.data?.noHover) {
		return (
			<div className="worldWater-nivo-tooltip">
				<div className="worldWater-nivo-tooltip-content">
					<h4>
						<span
							className="worldWater-nivo-tooltip-color"
							style={{background: point.color}}
						/>
						{moment(point?.data?.x).format('YYYY')}
					</h4>
					<span className="worldWater-nivo-tooltip-time"></span>
					<div>
						<span className="worldWater-nivo-tooltip-label">
							{`${
								point?.data.class === 1
									? 'Seasonal water extent (0-1 month): '
									: point?.data.class === 2
									? 'Seasonal water extent (2-6 month): '
									: 'Seasonal water extent (7-11 month): '
							}`}
						</span>
						<span className="worldWater-nivo-tooltip-value">
							{point?.data?.y?.toFixed(2)}
						</span>
						<span className="worldWater-nivo-tooltip-deviation">
							{' '}
							{'[sqkm]'}
						</span>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const SeasonalWaterExtentChart = ({
	data,
	isMultiselect,
	valueType,
	chartKey,
}) => {
	const [dateRange, setDateRange] = useState(null);
	const chartTitle = (
		<>
			Seasonal water extent progress <span>[sqkm]</span>
		</>
	);

	const metadata = {
		extendLines: true,
		settings: {
			theme,
			colors: serie => serie.color,
			margin: {top: 10, right: 10, bottom: 40, left: 40},
			xScale: {
				type: 'time',
				format: '%Y-%m-%dT%H:%M:%S%Z',
			},
			// xFormat: 'time:%Y-%m-%dT%H:%M:%S%Z',
			yScale: {
				type: 'linear',
				min: isMultiselect && valueType === 'rel' ? 'auto' : 0,
			},
			axisTop: null,
			axisRight: null,
			axisLeft: {
				format: value => value.toLocaleString(),
				tickValues: 6,
			},
			enableGridX: false,
			gridYValues: 6,
			gridXValues: 6,
			pointSize: 0,
			useMesh: true,
			animate: false,
			motionConfig: 'default',
			markers: [
				{
					axis: 'y',
					value: 0,
					lineStyle: {stroke: 'var(--base30)', strokeWidth: 2},
				},
			],
			legends: [],
		},
	};

	return (
		<ChartWrapper
			title={chartTitle}
			className={'is-SeasonalWaterExtentChart'}
			data={data?.download}
			Tools={
				isMultiselect ? (
					<>
						<ValueTypeSwitcher chartKey={chartKey} />
						<SeasonalSwitcher chartKey={chartKey} />
					</>
				) : null
			}
		>
			{data ? (
				<NivoChartHoverWrapper
					chartType={'activeChartKey'}
					getHoverContent={getSeasonalWaterExtentProgress}
				>
					<TimeRangeChart
						ChartComponent={LineChart}
						chartComponentSettings={({hovered}) => ({
							pointSymbol: <Node hovered={hovered} />,
							enablePoints: true,
						})}
						data={data?.chart}
						metadata={metadata}
						overviewChartMetadata={overviewChartMetadata}
						onHover={() => {}}
						dateRange={dateRange}
						onDateRangeChange={setDateRange}
						displayTimeFormat="YYYY"
					/>
				</NivoChartHoverWrapper>
			) : null}
		</ChartWrapper>
	);
};

SeasonalWaterExtentChart.propTypes = {
	chartKey: PropTypes.string,
	data: PropTypes.array,
	valueType: PropTypes.string,
	isMultiselect: PropTypes.bool,
};

export default SeasonalWaterExtentChart;
