export default {
	key: 'placeSelectMapLayerStyle',
	data: {
		nameDisplay: 'Countries',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						{
							fill: '#1e7aad',
							fillOpacity: 0.1,
							outlineColor: '#4997bd',
							outlineWidth: 1,
							outlineOpacity: 1,
						},
						{
							attributeKey: 'd',
							attributeValues: {
								y: {
									fillOpacity: 1,
								},
							},
						},
					],
				},
			],
		},
	},
};
