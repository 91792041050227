import PropTypes from 'prop-types';
import RasterMapTooltip from './RasterMapTooltip';
import VectorMapTooltip from './VectorMapTooltip';
import RasterContentWrapper from './RasterContentWrapper';
import './style.scss';

const MapTooltip = ({children, event, raster, vector}) => {
	const vectorIDs = [];
	const rasterIDs = [];
	const vectorContent = vector.reduce((acc, val, i) => {
		// vector tooltip should come here
		if (!vectorIDs.includes(val.layer.id)) {
			vectorIDs.push(val.layer.id);
			return [
				...acc,
				<VectorMapTooltip
					key={`${i}_${val.layer.props.layerKey || val.layer.props.key}`}
					{...{layer: val, event}}
				></VectorMapTooltip>,
			];
		} else {
			return acc;
		}
	}, []);

	const rasterContent = raster.reduce((acc, val) => {
		if (!rasterIDs.includes(val.layer.id)) {
			rasterIDs.push(val.layer.id);

			return [
				...acc,
				<RasterMapTooltip
					key={val.layer.props.layerKey || val.layer.props.key}
					{...{layer: val, event}}
				>
					{children}
				</RasterMapTooltip>,
			];
		} else {
			return acc;
		}
	}, []);

	const isEmpty = raster?.length === 0 && vectorContent?.length === 0;
	return !isEmpty ? (
		<div>
			{vectorContent}
			{raster?.length > 0 ? (
				<RasterContentWrapper {...{event}}>
					{rasterContent}
				</RasterContentWrapper>
			) : null}
		</div>
	) : null;
};

MapTooltip.propTypes = {
	event: PropTypes.object,
	vector: PropTypes.array,
	raster: PropTypes.array,

	children: PropTypes.node,
};

export default MapTooltip;
