//import PropTypes from 'prop-types';
import './style.scss';
import SectionInfoWrapper from '.././components/Wrapper';
import SectionInfoParagraph from '../components/Paragraph';
import SectionInfoSegment from '../components/Segment';
import SectionInfoLabel from '../components/Label';

const WaterExtentSection = () => {
	return (
		<SectionInfoWrapper>
			<SectionInfoSegment>
				<SectionInfoParagraph>
					This combined set of indicators assesses the extent of permanent and
					seasonal water. They give insights into the variation in the presence
					of water bodies over the years, presenting a comprehensive picture of
					water distribution and its temporal dynamics. Seasonal water extent is
					also available as a unique layer which enables the exploration of the
					exact duration of seasonal water extent.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Layer</SectionInfoLabel>
				<SectionInfoParagraph>
					The calculated statistics indicators are derived from annual raster
					datasets, each with a 10-meter resolution. The first indicator
					&#39;Permanent and seasonal water extent&#39; differentiates pixels
					into three categories: no water, permanent water (presence observed in
					more than 75% of the monthly images within a year), and seasonal water
					(presence observed in less than 75% of the monthly images). The second
					indicator further refines the understanding of seasonal water,
					categorizing it based on another approach – the number of months water
					is present in a year, and reclassifying them into three broader
					categories: 1 (1 month), 2 (2 to 6 months), and 3 (7 to 11 months).
					The rasters are projected using the Equal-Area Cylindrical World
					projection to ensure accurate area calculations.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Attributes</SectionInfoLabel>
				<SectionInfoParagraph>
					The displayed attributes represent the average extent of permanent and
					seasonal water bodies for a selected year and the average for the
					2017-2021 period. The seasonal water extent is presented in both
					assessments. They facilitate basic trend analysis over time and allow
					for the assessment of changes in the extent of water bodies.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Charts</SectionInfoLabel>
				<SectionInfoParagraph>
					The line charts depict the extent of permanent and seasonal water
					extent for each year from 2017 to 2021. The first chart &#39;Seasonal
					and permanent water extent progress&#39; presents two lines for
					permanent and seasonal extents, while the second chart &#39;Seasonal
					water extent progress&#39; presents three lines for the presence of
					only seasonal water extent with values of 0-1 month, 2-6 months, and
					7-11 months. Interactive parts of charts permit the selection of
					specific periods within the reference time frame, enabling tailored
					data analysis. The temporal distribution of surface water extent can
					be readily visualized, enhancing understanding of water body
					permanence and seasonal variability. Data and charts can be downloaded
					for further study.
				</SectionInfoParagraph>
			</SectionInfoSegment>
		</SectionInfoWrapper>
	);
};

WaterExtentSection.propTypes = {};

export default WaterExtentSection;
