import PropTypes from 'prop-types';
import Timeline from './Timeline';

import './style.scss';
import {useEffect} from 'react';

const WaterExtentPanel = ({
	activePlaceKey,
	onPlaceChange,
	style,
	componentKey,
}) => {
	useEffect(() => {
		onPlaceChange();
	}, [activePlaceKey]);

	return (
		<div style={style}>
			{activePlaceKey ? <Timeline {...{componentKey}} /> : null}
		</div>
	);
};

WaterExtentPanel.propTypes = {
	style: PropTypes.object,
	activePlaceKey: PropTypes.string,
	componentKey: PropTypes.string,
	onPlaceChange: PropTypes.func,
};

export default WaterExtentPanel;
