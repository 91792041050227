import PropTypes from 'prop-types';
import StatisticsPanel from './StatisticsPanel';
import StatisticsPanelControl from './StatisticsPanel/components/PanelControl';
import StatisticsMap from './StatisticsMap';
import './style.scss';

const StatisticsBody = ({activePlaceKeys}) => {
	return (
		<div className="worldWater-StatisticsBody">
			{activePlaceKeys?.length ? <StatisticsPanel /> : null}
			{activePlaceKeys?.length ? <StatisticsPanelControl /> : null}
			<StatisticsMap />
		</div>
	);
};

StatisticsBody.propTypes = {
	activePlaceKeys: PropTypes.array,
};

export default StatisticsBody;
