import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const WaterPresence = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Monthly maps of surface water presence" />
		<MetadataItemInfoSectionParagraph text="The monthly water presence masks shows where water is present during a particular month derived from monthly aggregated SAR and optical imagery." />
		<MetadataItemInfoSectionParagraph text="This is a basic information layer for the situation in a specific month." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Monthly." />
		<MetadataItemInfoSectionLegendInfo
			items={['0: Not water', '1: Water', '255: No data']}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/water_presence.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default WaterPresence;
