import ActionTypes from '../../../../constants/ActionTypes';

const INITIAL_STATE = {
	byPlaceNameInternal: {},
};

const add = (state, placeNameInternal, data) => {
	if (placeNameInternal && data) {
		return {
			...state,
			byPlaceNameInternal: {
				...state.byPlaceNameInternal,
				[placeNameInternal]: data,
			},
		};
	} else {
		return state;
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.WORLD_WATER.STATISTICS.COUNTRIES.ADD:
			return add(state, action.placeNameInternal, action.data);
		default:
			return state;
	}
};
