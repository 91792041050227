import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import '../../style.scss';

const Timeline = () => (
	<TourStepsContainer>
		<TourStepsTitle>Timeline</TourStepsTitle>
		<TourStepsSection>
			See and scroll through the list of products that overlap with current AOI
			and add them to the map by clicking on corresponding bar. Check visually
			the duration of the season. Zoom with the mouse wheel in the timeline.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Timeline;
