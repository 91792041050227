import {createElement, useEffect, useState} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {utils} from '@gisatcz/ptr-utils';
import {Mouse, Months, Years, MapTimeline} from '@gisatcz/ptr-timeline';
import {connects} from '@gisatcz/ptr-state';

import PanelControlButton from '../../../../../common/PanelControlButton';
import MapTimelineLegend from './Legend/MapTimelineLegend';
import Layer from './Layer';

import './style.scss';

const MapTimelinePresentation = MapTimeline.MapTimelinePresentation;
const LayerRowPresentation = MapTimeline.LayerRowPresentation;
const LayerRowItemPresentation = MapTimeline.LayerRowItemPresentation;
const LayerRowPeriodItemPresentation =
	MapTimeline.LayerRowPeriodItemPresentation;

const LayerRowComponent = connects.timeline.LayerRow(LayerRowPresentation);
const LayerRowItemComponent = connects.timeline.LayerRowItem(
	LayerRowItemPresentation
);
const LayerRowPeriodItemComponent = connects.timeline.LayerRowPeriodItem(
	LayerRowPeriodItemPresentation
);

const LayerRowItemComponentWrapped = props => (
	<LayerRowItemComponent
		{...props}
		LayerRowPeriodItemComponent={LayerRowPeriodItemComponent}
	/>
);
const LayerRowComponentWrapped = props => (
	<LayerRowComponent
		{...props}
		LayerRowItemComponent={LayerRowItemComponentWrapped}
	/>
);

// TODO dynamic
const periodLimit = {
	start: '2017-01-02',
	end: '2022-01-01',
};

const MIN_TIMELINE_HEIGHT = 8;

const LEVELS = [
	{
		level: 'year',
		end: 2,
	},
	{
		level: 'month',
		end: 5,
	},
];

const Levels = ({activeLevel, ...restProps}) => {
	switch (activeLevel) {
		case 'year':
			return createElement(Years, {...restProps, key: 'year'});
		case 'month':
			return createElement(Months, {...restProps, key: 'month'});
	}
	return createElement(Months, {...restProps, key: 'month'});
};

Levels.propTypes = {
	activeLevel: PropTypes.string,
};

const getHoverContent = (x, time, evt, hoverContext, layerRows) => {
	const clientY = evt.clientY;

	// remove timeline as a overlay
	const hoveredOverlays = hoverContext?.hoveredItems?.filter(
		i => i.key !== 'timeline'
	);

	let top = 0;
	// select row by mouse position
	const layerRowMouseIntersection = layerRows?.find(layerRow => {
		top = top + (layerRow.lineHeight - layerRow.elementHeight) / 2;
		const layerRowTop = top;
		top = top + layerRow.elementHeight;
		const layerRowBottom = top;
		top = top + (layerRow.lineHeight - layerRow.elementHeight) / 2;
		const mouseIntersectRow =
			layerRowTop <= clientY && layerRowBottom >= clientY;
		return mouseIntersectRow;
	});

	const descriptionElm = (
		<div>{`${hoveredOverlays?.[0]?.overlay?.origin?.originPeriod?.data?.nameDisplay}`}</div>
	);

	const intersectionOverlaysElms =
		hoveredOverlays?.length > 0 && layerRowMouseIntersection ? (
			<div
				key={hoveredOverlays[0].overlay.key}
				className={'ptr-timeline-tooltip-layer'}
			>
				<div>
					<span
						className="dot"
						style={{
							backgroundColor: layerRowMouseIntersection.items[0].colors.basic,
						}}
					></span>
				</div>
				<div>
					<div>
						<Layer
							className={'ptr-timeline-tooltip-layer'}
							layerTemplateKey={
								layerRowMouseIntersection.legend.layerTemplateKey
							}
						/>
						{descriptionElm}
					</div>
				</div>
			</div>
		) : null;

	return (
		<div>
			<div className={'ptr-timeline-tooltip-time'}>
				{time ? (
					<>
						<b>{`${time?.format('YYYY')}`}</b>-<b>{`${time?.format('MM')}`}</b>-
						<b>{`${time?.format('DD')}`}</b>
					</>
				) : null}
			</div>
			{intersectionOverlaysElms}
		</div>
	);
};

const minTimelineHeight = MIN_TIMELINE_HEIGHT * utils.getRemSize();

const Timeline = ({
	activeMapKey,
	activePlaceKey,
	onLayerClick,
	isInteractivityLimited,
	layers,
	isCollapsed,
	onCollapse,
}) => {
	const [reload, setReload] = useState(false);

	useEffect(() => {
		onCollapse(false);

		if (onCollapse && typeof onCollapse === 'function') {
			return () => onCollapse(true);
		}
	}, []);

	//fake remove and add timeline component to let it reload data properly
	useEffect(() => {
		setReload(true);
	}, [activePlaceKey]);

	const classes = classnames('worldWater-Timeline', {
		disabled: isInteractivityLimited,
		'is-collapsed': isCollapsed,
	});

	//fake remove and add timeline component to let it reload data properly
	if (reload) {
		setTimeout(() => {
			setReload(false);
		}, 100);
	}
	return (
		<div className={classes}>
			<PanelControlButton collapsed={isCollapsed} onClick={onCollapse} />
			{layers && !reload ? (
				<MapTimelinePresentation
					LayerRowComponent={LayerRowComponentWrapped}
					mapKey={activeMapKey}
					getHoverContent={(...rest) => getHoverContent(...rest, layers)}
					periodLimit={periodLimit}
					initPeriod={periodLimit}
					vertical={false}
					levels={LEVELS}
					selectMode={true}
					layers={layers}
					onLayerClick={onLayerClick}
					minTimelineHeight={minTimelineHeight}
					LegendComponent={MapTimelineLegend}
				>
					<Levels />
					<Mouse mouseBufferWidth={20} key="mouse" />
				</MapTimelinePresentation>
			) : null}
		</div>
	);
};

Timeline.propTypes = {
	activeLayers: PropTypes.array,
	activeMapKey: PropTypes.string,
	onLayerClick: PropTypes.func,
	isInteractivityLimited: PropTypes.bool,
	layers: PropTypes.array,
	activePlaceKey: PropTypes.string,
	onCollapse: PropTypes.func,
	isCollapsed: PropTypes.bool,
};

export default Timeline;
