import PropTypes from 'prop-types';
import './style.scss';

const Header = ({children}) => {
	return <div className="worldWater-Header">{children}</div>;
};

Header.propTypes = {
	children: PropTypes.node,
};

export default Header;
