export default {
	swe: {
		1: '0-1 month',
		2: '2-6 months',
		3: '7-11 months',
	},
	sswe: {
		sea: 'Seasonal',
	},
	pswe: {
		per: 'Permanent',
	},
	swcminA: {
		1: 'Gain water',
		2: 'Loss water',
	},
	swcminB: {
		1: 'Gain water',
		2: 'Loss water',
	},
	swcmaxA: {
		1: 'Gain water',
		2: 'Loss water',
	},
	swcmaxB: {
		1: 'Gain water',
		2: 'Loss water',
	},
};
