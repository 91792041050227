import {includes as _includes} from 'lodash';
import indicatorsActions from './indicators/actions';
import pointActions from './points/actions';
import featuresActions from './features/actions';
import satellitesActions from './satellites/actions';
import valueTypesActions from './valueTypes/actions';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import Select from '../../Select';

function setSelectedPointForStatistics(key, componentKey) {
	return (dispatch, getState) => {
		if (key) {
			dispatch(CommonAction.components.set(componentKey, 'key', key));
		} else {
			const available = Select.worldWater.waterLevel.getSelectedPointKeys(
				getState()
			);
			const currentlySelected = Select.components.get(
				getState(),
				componentKey,
				'key'
			);

			if (
				!currentlySelected ||
				(currentlySelected && !_includes(available, currentlySelected))
			) {
				dispatch(
					CommonAction.components.set(componentKey, 'key', available?.[0])
				);
			}
		}
	};
}

function handleReferenceLayer() {
	return (dispatch, getState) => {
		const state = getState();
		const activePlace = Select.places.getActive(state);
		const placeName = activePlace?.data?.nameInternal;

		if (placeName) {
			dispatch(featuresActions.use(placeName));
		}
	};
}

export default {
	setSelectedPointForStatistics,
	handleReferenceLayer,
	indicators: indicatorsActions,
	points: pointActions,
	features: featuresActions,
	satellites: satellitesActions,
	valueTypes: valueTypesActions,
};
