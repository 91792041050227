import PropTypes from 'prop-types';
import {createElement} from 'react';
import classNames from 'classnames';
import './style.scss';

const DashboardSection = ({title, Tools, children, className, chartRef}) => {
	const classes = classNames('worldWater-DashboardSection', {}, className);

	return (
		<div className={classes} ref={chartRef}>
			<div className="worldWater-DashboardSection-header">
				<div className="worldWater-DashboardSection-title">{title}</div>
				<div
					className="worldWater-DashboardSection-tools"
					data-html2canvas-ignore
				>
					{Tools?.map((Tool, index) => createElement(Tool, {key: index}))}
				</div>
			</div>
			<div className="worldWater-DashboardSection-body">{children}</div>
		</div>
	);
};

DashboardSection.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	Tools: PropTypes.array,
	className: PropTypes.string,
	children: PropTypes.node,
	chartRef: PropTypes.object,
};

export default DashboardSection;
