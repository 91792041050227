import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		activeViewKey: Select.views.getActiveKey(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.worldWater.statistics.init());
		},
		onUnmount: () => {
			dispatch(Action.worldWater.statistics.destroy());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
