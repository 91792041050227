import {
	defaultStatisticsMapView,
	statisticsMapKey,
	statisticsMapSetKey,
} from '../../constants/app';
import statisticsMapLayer from '../layers/statisticsMapLayer';
import worldCountriesMapLayer from '../layers/worldCountriesMapLayer';

export default {
	key: '14b6b265-13a0-47dc-ac48-1b72397b6411',
	data: {
		nameInternal: 'statistics',
		state: {
			maps: {
				maps: {
					[statisticsMapKey]: {
						key: statisticsMapKey,
						data: {
							view: {},
							layers: [statisticsMapLayer, worldCountriesMapLayer],
						},
						name: null,
					},
				},
				sets: {
					[statisticsMapSetKey]: {
						key: statisticsMapSetKey,
						data: {
							backgroundLayer: {
								key: 'esri_WorldGrayCanvas',
								name: 'ESRI Grey Canvas',
								type: 'wmts',
								options: {
									url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
									maxNativeZoom: 16,
								},
							},
							view: defaultStatisticsMapView,
							viewLimits: {
								boxRange: [5000, 1500000],
							},
						},
						maps: [statisticsMapKey],
						sync: {
							roll: true,
							tilt: true,
							range: true,
							center: true,
							heading: true,
							boxRange: true,
						},
						activeMapKey: statisticsMapKey,
					},
				},
				activeSetKey: statisticsMapSetKey,
			},
			worldWater: {
				configuration: {
					mapSetTools: {
						[statisticsMapSetKey]: {
							open: [
								'zoomControls',
								'backgroundLayersControl',
								'mapAttribution',
								'scale',
								'coordinates',
							],
							available: [
								'zoomControls',
								'backgroundLayersControl',
								'coordinates',
							],
							componentsByKey: {
								zoomControls: {
									icon: 'plus-thick',
									title: 'Zoom controls',
								},
								mapAttribution: {
									icon: 'info',
									title: 'Map Attribution',
								},
								backgroundLayersControl: {
									icon: 'layers',
									title: 'Background layers control',
								},
								coordinates: {
									icon: '',
									title: 'Coordinates',
								},
							},
						},
					},
				},
			},
		},
	},
};
