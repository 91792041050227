import config from '../../../../config';
import request from '../../../../utils/request';
import ActionTypes from '../../../../constants/ActionTypes';
import Action from '../../../Action';

/**
 * Use data for WaterLevel vector layer
 */
const use = placeName => {
	return dispatch => {
		dispatch(ensureDataForPlace(placeName)).then(() => {
			dispatch(Action.worldWater.statistics.refreshMapLayer(placeName));
		});
	};
};

/**
 * @param place {string} Panther place's nameInternal, which is used as directory name
 */
const ensureDataForPlace = place => {
	return dispatch => {
		// TODO for now, request allways for data
		let url = `${config.statisticsDataRepository}/boundaries/${place}.json`;

		return request(url, 'GET', null, null).then(data => {
			if (data?.features) {
				if (place === 'GRL') {
					dispatch(
						actionAdd(
							place,
							data?.features.filter(feature => !feature.properties.noData)
						)
					);
				} else {
					dispatch(actionAdd(place, data?.features));
				}
			} else {
				console.warn(`No data for place ${place}!`);
			}
		});
	};
};

const actionAdd = (place, features) => {
	return {
		type: ActionTypes.WORLD_WATER.STATISTICS.FEATURES.ADD,
		place,
		features,
	};
};

export default {use};
