import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../../../state/Select';
import Action from '../../../../../../../../state/Action';
import Presentation from './presentation';

const componentKey = 'SelectedPointSelect';

const mapStateToProps = state => {
	return {
		points: Select.worldWater.waterLevel.getSelectedPoints(state),
		selectedKey: Select.components.get(state, componentKey, 'key'),
		selectionData: Select.selections.getActive(state)?.data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSelectedKeyChange: key => {
			dispatch(
				Action.worldWater.waterLevel.setSelectedPointForStatistics(
					key,
					componentKey
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
