import PropTypes from 'prop-types';

const SingleProductLabelHeader = ({product, color, children, date}) => {
	return (
		<div className="worldWater-ProductLabelHeader">
			{color ? (
				<div
					className="worldWater-ProductLabelHeader-color"
					style={{background: color}}
				/>
			) : null}
			<div className="worldWater-ProductLabelHeader-body">
				<div className="worldWater-ProductLabelHeader-title">
					<span className="worldWater-ProductLabelHeader-product">
						{product}
					</span>
				</div>
				<div className="worldWater-ProductLabelHeader-period">{date}</div>
				<div className="worldWater-ProductLabelHeader-value">{children}</div>
			</div>
		</div>
	);
};

SingleProductLabelHeader.propTypes = {
	color: PropTypes.string,
	product: PropTypes.string,
	children: PropTypes.node,
	date: PropTypes.string,
};

const ProductTooltipContent = ({
	layerTemplate,
	period,
	color,
	label,
	value,
	componentsByLayer,
}) => {
	const componentByLayer = componentsByLayer?.find(
		c => c.layerTemplateKey === layerTemplate?.key
	);

	const unit = componentByLayer?.legend?.props?.unit;

	const title = layerTemplate?.data?.nameDisplay || '';
	return (
		<>
			<SingleProductLabelHeader
				product={title.charAt(0).toUpperCase() + title.slice(1)}
				date={period}
			>
				<div className="worldWater-ProductLabelLegendItem">
					{color ? (
						<div
							className="worldWater-ProductLabelHeader-color"
							style={{background: `rgba(${color})`}}
						/>
					) : null}
					{value ? `${label}: ${value}${unit ? ` ${unit}` : ''}` : `${label}`}
				</div>
			</SingleProductLabelHeader>
		</>
	);
};

ProductTooltipContent.propTypes = {
	period: PropTypes.object,
	response: PropTypes.object,
	mapKey: PropTypes.string,
	layerKey: PropTypes.string,
	layerTemplate: PropTypes.object,
	productMetadata: PropTypes.object,
	color: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	componentsByLayer: PropTypes.array,
};

export default ProductTooltipContent;
