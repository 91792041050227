import {forIn as _forIn} from 'lodash';
import {
	Action as CommonAction,
	commonActions as commonActions,
} from '@gisatcz/ptr-state';
import {map as mapUtils} from '@gisatcz/ptr-utils';
import Select from '../../Select';
import Action from '../../Action';
import features from './features/actions';
import availableCountries from '../../../data/geometries/availableCountries_statistics.json';
import basin from './basin/actions';
import countryActions from './countries/actions';
import statisticsView from '../../../data/views/statisticsView';
import statisticsMapCountrySelection from '../../../data/selections/statisticsMapCountrySelection';
import statisticsMapHydrobasinSelection from '../../../data/selections/statisticsMapHydrobasinSelection';
import areaTreeLevels from '../../../data/metadata/areaTreeLevels';
import attributes from '../../../data/metadata/attributes';

import ActionTypes from '../../../constants/ActionTypes';
import {
	statisticsMapLayerKey,
	statisticsHydrobasinSelectionKey,
	statisticsCountrySelectionKey,
	defaultStatisticsMapView,
	hydrobasinsLevelFidColumnName,
	worldCountriesMapLayerKey,
	statisticsMapKey,
} from '../../../constants/app';

function init() {
	return dispatch => {
		dispatch(CommonAction.views.add([statisticsView]));
		dispatch(
			Action.worldWater.setActiveViewForStatistics(statisticsView?.key, Action)
		);
		dispatch(
			CommonAction.selections.add([
				statisticsMapCountrySelection,
				statisticsMapHydrobasinSelection,
			])
		);
		dispatch(
			commonActions.add(ActionTypes.AREAS.AREA_TREE_LEVELS)(areaTreeLevels)
		);
		dispatch(CommonAction.attributes.add(attributes));
		dispatch(
			CommonAction.selections.setActiveKey(statisticsMapCountrySelection?.key)
		);
		dispatch(
			CommonAction.areas.areaTreeLevels.setActiveKey(areaTreeLevels?.[0]?.key)
		);
	};
}

function destroy() {
	return dispatch => {
		dispatch(CommonAction.views.setActiveKey(null));
		dispatch(CommonAction.places.setActiveKeys(null));
	};
}

function setActivePlaceKeysByActiveSelectionFeatureKeys(
	selectedFeatureKeys,
	stateMapKey,
	layerKey
) {
	return (dispatch, getState) => {
		const state = getState();

		const selectedKeys = selectedFeatureKeys.filter(featureKey =>
			Select.worldWater.statistics.isCountryAvailableByFeatureKey(
				state,
				featureKey
			)
		);

		if (selectedKeys) {
			const placeKeyByFeatureKey = Select.app.getConfiguration(
				state,
				'placeKeyByCountryFeatureKey'
			);

			const placeKeys = selectedKeys.map(
				featureKey => placeKeyByFeatureKey[featureKey]
			);

			dispatch(
				CommonAction.places.setActiveKeys(placeKeys.length ? placeKeys : null)
			);
			dispatch(
				Action.maps.setLayerSelectedFeatureKeys(
					stateMapKey,
					layerKey,
					selectedKeys
				)
			);
			// Clear hydrobasins selection
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(
					statisticsHydrobasinSelectionKey,
					[]
				)
			);
		}
	};
}

/**
 * Set selected feature keys in active selection by place keys. The linking should be stored in app configuration.
 */
function setActiveSelectionFeatureKeysByActivePlaceKeys() {
	return (dispatch, getState) => {
		const placeKeyByFeatureKey = Select.app.getConfiguration(
			getState(),
			'placeKeyByCountryFeatureKey'
		);

		const placeKeys = Select.places.getActiveKeys(getState());

		if (placeKeyByFeatureKey) {
			const featureKeys = [];
			placeKeys?.forEach(placeKey => {
				_forIn(placeKeyByFeatureKey, (value, featureKey) => {
					if (placeKey === value) {
						featureKeys.push(featureKey);
					}
				});
			});

			// dispatch(clearCountryLevelSelection());
			dispatch(
				CommonAction.selections.setActiveSelectionFeatureKeysFilterKeys(
					featureKeys
				)
			);
		}
	};
}

function handleLevelSwitch() {
	return (dispatch, getState) => {
		const state = getState();
		const activeLevel =
			Select.areas.areaTreeLevels.getActive(state)?.data?.level;
		if (activeLevel === 2) {
			// apply features for whole world
			dispatch(refreshMapLayer());
			// Get data for selected country and apply them
			const featureKey =
				Select.selections.getActive(state)?.data?.featureKeysFilter?.keys?.[0];
			dispatch(features.use(featureKey));

			// Zoom to selected country
			dispatch(zoomToActivePlace());

			// Set selections
			dispatch(
				CommonAction.selections.setActiveKey(statisticsHydrobasinSelectionKey)
			);
			dispatch(
				CommonAction.maps.setMapLayerOption(
					statisticsMapKey,
					statisticsMapLayerKey,
					'fidColumnName',
					hydrobasinsLevelFidColumnName
				)
			);
		} else if (activeLevel === 1) {
			// apply features for whole world
			dispatch(refreshMapLayer());

			// Zoom to whole world
			dispatch(zoomToWorld());

			// Set selections
			dispatch(
				CommonAction.selections.setActiveKey(statisticsCountrySelectionKey)
			);
			dispatch(
				CommonAction.maps.setMapLayerOption(
					statisticsMapKey,
					statisticsMapLayerKey,
					'fidColumnName',
					'g'
				)
			);
		}
	};
}

function zoomToActivePlace() {
	return (dispatch, getState) => {
		const activePlaceKeys = Select.places.getActiveKeys(getState());
		if (activePlaceKeys?.length === 1) {
			const activePlaceKey = activePlaceKeys[0];
			const activePlace = Select.places.getByKey(getState(), activePlaceKey);
			const geometry = activePlace?.data?.geometry;

			if (geometry) {
				const view = mapUtils.view.getViewFromGeometry(
					activePlace.data.geometry
				);
				const mapSetKey = Select.maps.getActiveSetKey(getState());
				dispatch(CommonAction.maps.updateSetView(mapSetKey, view));
			}
		}
	};
}

function zoomToWorld() {
	return (dispatch, getState) => {
		const mapSetKey = Select.maps.getActiveSetKey(getState());
		dispatch(
			CommonAction.maps.updateSetView(mapSetKey, defaultStatisticsMapView)
		);
	};
}

function refreshMapLayer(placeName) {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
		let layerKey = statisticsMapLayerKey;
		const activeLevel =
			Select.areas.areaTreeLevels.getActive(state)?.data?.level;

		let features = null;
		if (activeLevel === 1) {
			features = availableCountries?.features;
			dispatch(
				CommonAction.maps.setMapLayerOpacity(
					mapKey,
					worldCountriesMapLayerKey,
					0.5
				)
			);
		} else if (activeLevel === 2) {
			features = Select.worldWater.statistics.features.getByParentFeatureKey(
				state,
				placeName
			);
			dispatch(
				CommonAction.maps.setMapLayerOpacity(
					mapKey,
					worldCountriesMapLayerKey,
					0
				)
			);
		}

		if (mapKey && layerKey && features) {
			dispatch(
				CommonAction.maps.setMapLayerOption(
					mapKey,
					layerKey,
					'features',
					features || []
				)
			);
		}
	};
}

function onLayerClick(selectedFeatureKeys, stateMapKey, layerKey) {
	return (dispatch, getState) => {
		const state = getState();
		const activeLevel =
			Select.areas.areaTreeLevels.getActive(state)?.data?.level;
		const multiselectEnabled =
			Select.worldWater.statistics.isMultiselectEnabled();
		const selectedKeys = multiselectEnabled
			? selectedFeatureKeys
			: [selectedFeatureKeys?.[0]];

		if (activeLevel === 1) {
			dispatch(
				setActivePlaceKeysByActiveSelectionFeatureKeys(
					selectedKeys,
					stateMapKey,
					layerKey
				)
			);
			dispatch(Action.worldWater.statistics.countries.use());
		} else {
			//on basin click
			dispatch(
				Action.maps.setLayerSelectedFeatureKeys(
					stateMapKey,
					layerKey,
					selectedKeys
				)
			);
			//get by active place
			dispatch(basin.use());
		}
	};
}

export default {
	init,
	destroy,
	handleLevelSwitch,
	onLayerClick,
	setActiveSelectionFeatureKeysByActivePlaceKeys,
	refreshMapLayer,
	features,
	basin,
	countries: countryActions,
};
