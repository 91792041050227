export default [
	{
		key: 'wp',
		data: {
			nameInternal: 'attr-water_presence',
			nameDisplay: 'Water presence',
			unit: 'sqkm',
		},
	},
	{
		key: 'swe',
		data: {
			nameInternal: 'attr-seasonal_water_extent',
			nameDisplay: 'Seasonal water extent',
			unit: 'sqkm',
		},
	},
	{
		key: 'pswe',
		data: {
			nameInternal: 'attr-permanent_seasonal_water_extent',
			nameDisplay: 'Permanent water extent',
			unit: 'sqkm',
		},
	},
	{
		key: 'sswe',
		data: {
			nameInternal: 'attr-seasonal_seasonal_water_extent',
			nameDisplay: 'Seasonal water extent',
			unit: 'sqkm',
		},
	},
];
