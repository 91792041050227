import PropTypes from 'prop-types';
import './style.scss';

const NoDataPlaceholder = ({children}) => {
	return <p className="worldWater-NoDataPlaceholder">{children}</p>;
};

NoDataPlaceholder.propTypes = {
	children: PropTypes.node,
};

export default NoDataPlaceholder;
