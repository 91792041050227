import {createSelector} from 'reselect';
import {isEmpty as _isEmpty} from 'lodash';
import {Select} from '@gisatcz/ptr-state';
import {
	statisticsCountrySelectionKey,
	statisticsHydrobasinSelectionKey,
} from '../../../../constants/app';

const getAllAsObject = state => state.worldWater.statistics.basin.byPlaceKey;

/** Returns all statistics for place and basin
 * @param {string} placeKey
 * @param {string} basinKey
 * @returns {object}
 */
const getStatisticsForBasinByKeyAndPlaceKey = createSelector(
	[
		getAllAsObject,
		(state, placeKey) => placeKey,
		(state, placeKey, basinKey) => basinKey,
	],
	(data, placeKey, basinKey) => {
		if (data && placeKey && basinKey) {
			return data?.[placeKey]?.[basinKey] || null;
		} else {
			return null;
		}
	}
);

const getStatsForActiveSelection = createSelector(
	[getAllAsObject, Select.selections.getAllAsObject],
	(data, selections) => {
		const countryKey =
			selections[statisticsCountrySelectionKey]?.data.featureKeysFilter
				?.keys?.[0];
		const basinKeys =
			selections[statisticsHydrobasinSelectionKey]?.data.featureKeysFilter
				?.keys;
		if (!_isEmpty(data) && countryKey && basinKeys?.length) {
			return basinKeys.map(basinKey => data?.[countryKey]?.[basinKey] || null);
		} else {
			return null;
		}
	}
);

const getDataForAttribute = createSelector(
	[getStatsForActiveSelection, (state, attribute) => attribute],
	(data, attribute) => {
		if (data?.length && attribute) {
			return data.map(item => item?.data?.[attribute]);
		} else {
			return null;
		}
	}
);

const getValuesForAttribute = createSelector([getDataForAttribute], data => {
	return data?.map(item => item?.values || null);
});

export default {
	getStatsForActiveSelection,
	getStatisticsForBasinByKeyAndPlaceKey,
	getValuesForAttribute,
};
