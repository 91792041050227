import PropTypes from 'prop-types';
import ButtonSwitcher from '../../../../../../../../common/Switcher';
import {useEffect} from 'react';

const ValueTypeSwitcher = ({
	activeOptionKey,
	onActiveOptionChange,
	onMount,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
	}, []);

	return (
		<ButtonSwitcher
			condensed
			icon="ri-vertical-align-center"
			activeOptionKey={activeOptionKey}
			options={[
				{key: 'abs', data: {nameDisplay: 'Absolute'}},
				{key: 'rel', data: {nameDisplay: 'Relative'}},
			]}
			onActiveOptionChange={onActiveOptionChange}
		/>
	);
};

ValueTypeSwitcher.propTypes = {
	activeOptionKey: PropTypes.string,
	onActiveOptionChange: PropTypes.func,
	onMount: PropTypes.func,
};

export default ValueTypeSwitcher;
