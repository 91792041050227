export default [
	{
		key: '511fc5df-5a0e-40e8-a797-fc643a9132db',
		data: {
			nameInternal: 'AOI',
			nameDisplay: 'Countries',
			level: 1,
			areaTreeKey: '905d5b3b-7e9c-4e6b-8ae4-7166d189b9d9',
		},
	},
	{
		key: 'fe0063b2-a3b2-4b8a-8b84-90db90fc139d',
		data: {
			nameInternal: 'Basins',
			nameDisplay: 'Hydrobasins',
			level: 2,
			areaTreeKey: '905d5b3b-7e9c-4e6b-8ae4-7166d189b9d9',
		},
	},
];
