import PropTypes from 'prop-types';
import {connects} from '@gisatcz/ptr-state';
import {DeckGlMap, MapSet, PresentationMap} from '@gisatcz/ptr-maps';
import MapComponentsGroup from '../../../../common/Maps/MapComponentsGroup';
import BackgroundLayersControl from '../../../../common/Maps/BackgroundLayersControl';
import ZoomControls from '../../../../common/Maps/ZoomControls';
import MapAttribution from '../../../../common/Maps/MapAttribution';
import Scale from '../../../../common/Maps/Scale';
import StatisticsMapContainer from './StatisticsMapContainer';
import PlaceSelectMapTooltip from './Tooltip';
import CoordinatesControl from '../../../../common/Maps/CoordinatesControl';
import './style.scss';
import InfoBox from '../../../../common/Maps/Info';

const ConnectedMap = StatisticsMapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const StatisticsMap = ({mapSetKey}) => {
	return (
		<div className="worldWater-StatisticsMap">
			<ConnectedMapSet
				stateMapSetKey={mapSetKey}
				mapComponent={DeckGlMap}
				connectedMapComponent={ConnectedMap}
				Tooltip={PlaceSelectMapTooltip}
			>
				<MapComponentsGroup className="worldWater-statistics-MapSetControls">
					<CoordinatesControl mapSetKey={mapSetKey} />
					<BackgroundLayersControl mapSetKey={mapSetKey} />
					<ZoomControls />
				</MapComponentsGroup>
				<MapComponentsGroup
					className="worldWater-MapInfoElements"
					key={'MapComponentsGroup_2'}
				>
					<MapComponentsGroup
						className="worldWater-AttributionScaleContainer"
						key={'MapComponentsGroup_3'}
					>
						<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
						<Scale key={'Scale'} />
					</MapComponentsGroup>
				</MapComponentsGroup>
				<MapComponentsGroup
					className="worldWater-InfoBox"
					key={'MapComponentsGroup_5'}
				>
					<InfoBox hideAfterSeconds={3}>
						Tip: Use CTRL / ⌘ + click to select multiple features.
					</InfoBox>
				</MapComponentsGroup>
			</ConnectedMapSet>
		</div>
	);
};

StatisticsMap.propTypes = {
	mapSetKey: PropTypes.string,
};

export default StatisticsMap;
