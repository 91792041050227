import PropTypes from 'prop-types';
import './style.scss';
import StatisticsLayerControl from './StatisticsLayerControl';

const StatisticsLayers = ({layers}) => {
	return (
		<div className="worldWater-StatisticsLayers">
			{layers.map(layer => (
				<StatisticsLayerControl key={layer?.key} name={layer?.name} />
			))}
		</div>
	);
};

StatisticsLayers.propTypes = {
	layers: PropTypes.array,
};

export default StatisticsLayers;
