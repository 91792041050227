import {includes as _includes} from 'lodash';
import {commonActions} from '@gisatcz/ptr-state';
import ActionTypes from '../../../../constants/ActionTypes';
import Select from '../../../Select';

const setActiveKey = commonActions.setActiveKey(
	ActionTypes.WORLD_WATER.WATER_LEVEL.INDICATORS
);

const handleDisabledKeys = disabledKeys => {
	return (dispatch, getState) => {
		if (disabledKeys?.length) {
			const activeKey = Select.worldWater.waterLevel.indicators.getActiveKey(
				getState()
			);
			if (_includes(disabledKeys, activeKey)) {
				dispatch(setActiveKey('waterLevel'));
			}
		}
	};
};

export default {
	setActiveKey,
	handleDisabledKeys,
};
