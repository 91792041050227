import {createSelector} from 'reselect';
const getAllAsObject = state =>
	state.worldWater.statistics.features.byParentFeatureKey;

const getByParentFeatureKey = createSelector(
	[getAllAsObject, (state, place) => place],
	(data, place) => {
		if (data && place) {
			return data[place] || null;
		} else {
			return null;
		}
	}
);

export default {
	getAllAsObject,
	getByParentFeatureKey,
};
