import {placeSelectMapSelectionKey} from '../../constants/app';

export default {
	key: placeSelectMapSelectionKey,
	data: {
		featureKeysFilter: {
			keys: [],
		},
		style: {
			outlineWidth: 3,
			outlineColor: '#25ccff',
			fill: '#25ccff',
			fillOpacity: 0.5,
		},
	},
};
