import PropTypes from 'prop-types';
import {useState} from 'react';

import LineChart from '../../../../../../../common/LineChart';
import ChartWrapper from '../ChartWrapper';
import {multiplePointSelectionColors} from '../../../../../../../../constants/app';
import TimeRangeChart from '../../../../../../../common/TimeRangeChart';
import NivoChartHoverWrapper from '../../../../../../../common/NivoChartHover/NivoChartHoverWrapper';
import ValueTypeSwitcher from '../switchers/ValueTypeSwitcher';
import DataTypeSwitcher from '../switchers/DataTypeSwitcher';

import './style.scss';

const theme = {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base20)',
				strokeWidth: 2,
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base20)',
			},
			text: {
				fill: 'var(--base50)',
				fontSize: 12,
			},
		},
		legend: {
			text: {
				fontWeight: 'bold',
				fill: 'var(--base50)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base15)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base40)',
		},
	},
};

const WaterPresenceChart = ({data, isMultiselect, chartKey, dataType}) => {
	const [dateRange, setDateRange] = useState(null);

	const chartTitle = (
		<>
			Water presence progress <span>[sqkm]</span>
		</>
	);

	let chartData = data?.chart;
	let downloadData = data?.download;

	let finalChartData = [];
	let colors = [];

	if (chartData?.length) {
		if (isMultiselect) {
			for (let i = 0; i < chartData?.length / 2; i++) {
				const index = dataType === 'measured' ? 2 * i + 1 : 2 * i;
				const values = chartData?.[index];

				finalChartData.push({
					data: values?.data,
					id: values?.id,
					color: values?.color,
				});
			}
		} else {
			colors = [multiplePointSelectionColors[0], 'var(--base25)'];
			finalChartData = [
				{data: chartData?.[1]?.data, id: 'Measured values'},
				{
					data: chartData?.[0]?.data,
					id: 'Average (2017 - 2021)',
				},
			];
		}
	}

	const metadata = {
		extendLines: true,
		settings: {
			theme,
			colors: isMultiselect ? serie => serie.color : colors,
			defs: [
				{
					colors: [
						{
							color: 'transparent',
							offset: 0, // required by default
						},
					],
					id: 'transparent',
					type: 'linearGradient', // required by default
				},
			],
			fill: [{match: {id: 'Measured values'}, id: 'transparent'}],
			enableArea: !isMultiselect,
			areaOpacity: 0.6,
			margin: {top: 10, right: 10, bottom: 40, left: 60},
			xScale: {
				type: 'time',
				format: '%Y-%m-%dT%H:%M:%S%Z',
			},
			yScale: {
				type: 'linear',
				min: 'auto',
			},
			axisTop: null,
			axisRight: null,
			axisLeft: {
				format: value => value.toLocaleString(),
				tickValues: 6,
			},
			enableGridX: false,
			enableGridY: true,
			gridYValues: 6,
			gridXValues: 6,
			pointSize: 0,
			useMesh: true,
			animate: false,
			motionConfig: 'default',
			markers: [
				{
					axis: 'y',
					value: 0,
					lineStyle: {stroke: 'var(--base30)', strokeWidth: 2},
				},
			],
			legends: [],
			enableSlices: 'x',
		},
	};

	const overviewChartMetadata = {
		settings: {
			theme,
			colors: isMultiselect ? serie => serie.color : colors,
			margin: {top: 5, right: 0, bottom: 5, left: 0},
			xScale: {
				type: 'time',
				format: '%Y-%m-%dT%H:%M:%S%Z',
			},
			yScale: {
				type: 'linear',
				min: 'auto',
			},
			axisTop: null,
			axisRight: null,
			axisLeft: null,
			axisBottom: null,
			enableGridX: false,
			enableGridY: false,
			pointSize: 0,
			useMesh: false,
			animate: false,
		},
	};
	return (
		<ChartWrapper
			title={chartTitle}
			data={downloadData}
			Tools={
				isMultiselect ? (
					<>
						<ValueTypeSwitcher chartKey={chartKey} />
						<DataTypeSwitcher chartKey={chartKey} />
					</>
				) : null
			}
		>
			{finalChartData ? (
				<NivoChartHoverWrapper
					chartType={'activeChartKey'}
					tooltipPosition={'corner'}
					wrapperRefQuery={'svg>g>g:nth-of-type(5)'}
				>
					<TimeRangeChart
						ChartComponent={LineChart}
						data={finalChartData}
						metadata={metadata}
						overviewChartMetadata={overviewChartMetadata}
						onHover={() => {}}
						dateRange={dateRange}
						onDateRangeChange={setDateRange}
						displayTimeFormat="MMMM YYYY"
					/>
				</NivoChartHoverWrapper>
			) : null}
		</ChartWrapper>
	);
};

WaterPresenceChart.propTypes = {
	chartKey: PropTypes.string,
	data: PropTypes.array,
	isMultiselect: PropTypes.bool,
	dataType: PropTypes.string,
};

export default WaterPresenceChart;
