import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const WaterSeasonality = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Yearly maps of water seasonality" />
		<MetadataItemInfoSectionParagraph text="Each pixel shows the number of observed months when water is present." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Yearly." />
		<MetadataItemInfoSectionLegendInfo
			items={[
				'0: Not water',
				'1: 1 month of water',
				'12: 12 months of water',
				'255: No data',
			]}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/water_seasonality.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default WaterSeasonality;
