import PropTypes from 'prop-types';
import './style.scss';

const StatisticsPanelHeader = ({
	activePlaces,
	activeLevel,
	activeHydrobasinKeys,
}) => {
	const countryNames = activePlaces?.map(
		activePlace => activePlace.data.nameDisplay
	);
	const countries = countryNames && countryNames.join(', ');

	const numOfHydrobasins = activeHydrobasinKeys?.length;
	const showStatsForHydrobasins = activeLevel === 2 && numOfHydrobasins;

	const hydrobasins = showStatsForHydrobasins
		? activeHydrobasinKeys.join(', ')
		: '';

	const countryContent = showStatsForHydrobasins ? (
		countries
	) : (
		<b>&nbsp;{countries}</b>
	);

	const hydrobasinsContent = showStatsForHydrobasins ? (
		<>
			{`/ ${numOfHydrobasins > 1 ? 'hydrobasins' : 'hydrobasin'}`}
			<b>&nbsp;{hydrobasins}</b>
		</>
	) : (
		''
	);

	return (
		<div className="worldWater-StatisticsPanel-Header">
			{'Displaying statistics for'}
			{countryContent} {hydrobasinsContent}
		</div>
	);
};

StatisticsPanelHeader.propTypes = {
	activeLevel: PropTypes.number,
	activePlaces: PropTypes.array,
	places: PropTypes.array,
	activeHydrobasinKeys: PropTypes.array,
};

export default StatisticsPanelHeader;
