import classnames from 'classnames';
import {getRouter} from '../../../router';
import {Icon} from '@gisatcz/ptr-atoms';
import Link from '../../common/Link';
import EsaLogo from '../../common/EsaLogo';
import logo from '../../../assets/img/worldWater_logo_only.png';
import TourIcon from '../../common/TourIcon';

import './style.scss';

const HomePage = () => {
	const router = getRouter();
	const classes = className =>
		classnames('worldWater-Home-card', {}, className);
	return (
		<div className="worldWater-Home">
			<div className="worldWater-Home-overlay">
				<div className="worldWater-Home-content">
					<a
						target="_blank"
						rel="noreferrer noopener"
						className="worldWater-Home-webLink"
						href="https://worldwater.earth/news/"
					>
						About project
						<Icon icon="external-link" />
					</a>
					<TourIcon isHome />
					<div className="worldWater-Home-mainSection">
						<div className="worldWater-Home-header">
							<img className="worldWater-Home-headerlogo" src={logo} />
							<div className="worldWater-Home-headerText">
								<h1 className="worldWater-Home-title">
									World<span>Water</span>
								</h1>
								<p className="worldWater-Home-subtitle">
									surface water dynamics from space
								</p>
							</div>
						</div>
						<div className="worldWater-Home-cards">
							<Link
								classes="worldWater-Home-card-link"
								name={'/product-explorer'}
								router={router}
								recoverParams={true}
							>
								<div className={classes()}>
									<div className="worldWater-Home-card-icon">
										<Icon icon={'ri-imgSearch'} />
									</div>
									<div>
										<h2 className="worldWater-Home-card-title">
											Product explorer
										</h2>
									</div>
								</div>
							</Link>
							<Link
								classes="worldWater-Home-card-link"
								name={'/statistics'}
								router={router}
								recoverParams={true}
							>
								<div className={classes()}>
									<div className="worldWater-Home-card-icon">
										<Icon icon={'ri-statistics'} />
									</div>
									<div>
										<h2 className="worldWater-Home-card-title">Statistics</h2>
									</div>
								</div>
							</Link>
							<a
								href="https://worldwater-processing.geoville.com/"
								target="_blank"
								rel="noreferrer noopener"
								className="worldWater-Home-card-link"
							>
								<div className={classes('is-outsideLink')}>
									<div className="worldWater-Home-card-icon">
										<Icon icon={'ri-services'} />
									</div>
									<div className="worldWater-Home-card-titleContainer">
										<h2 className="worldWater-Home-card-title">Processing</h2>
										<Icon
											icon={'external-link'}
											className="worldWater-Home-card-title-externalLink"
										/>
									</div>
								</div>
							</a>
							{/*<Link classes="worldWater-Home-card-link disabled">*/}
							{/*	<div className={classes('is-outsideLink')}>*/}
							{/*		<div className="worldWater-Home-card-icon">*/}
							{/*			<Icon icon={'ri-tools'} />*/}
							{/*		</div>*/}
							{/*		<div className="worldWater-Home-card-titleContainer">*/}
							{/*			<h2 className="worldWater-Home-card-title">Monitoring</h2>*/}
							{/*			<Icon*/}
							{/*				icon={'external-link'}*/}
							{/*				className="worldWater-Home-card-title-externalLink"*/}
							{/*			/>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</Link>*/}
						</div>
					</div>
					<div className="worldWater-Home-footer">
						<EsaLogo />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
