import {useState} from 'react';
import PropTypes from 'prop-types';
import HeaderTitle from '../../../../common/HeaderTitle';
import Header from '../../../../common/Header';
import PlaceSelect from './PlaceSelect';
import ViewSelect from './ViewSelect';
import PlacesProvider from '../../../../common/PlacesProvider';
import Toolbar from './Toolbar';

import './style.scss';

const ProductExplorerHeader = ({
	isPlaceOpen,
	setPlaceOpen,
	isViewOpen,
	setViewOpen,
}) => {
	const [isPlaceClosable, setPlaceClosable] = useState(false);
	const [isViewClosable, setViewClosable] = useState(false);

	const handlePlaceOpen = open => {
		setPlaceOpen(open);
		if (open && isViewClosable) {
			setViewOpen(false);
		}
	};

	const handleViewOpen = open => {
		setViewOpen(open);
		if (open && isPlaceClosable) {
			setPlaceOpen(false);
		}
	};

	return (
		<Header>
			<HeaderTitle>Product explorer</HeaderTitle>
			<div className="worldWater-ProductExplorerHeader-places">
				<PlacesProvider>
					<PlaceSelect
						open={isPlaceOpen}
						onSetOpen={handlePlaceOpen}
						closable={isPlaceClosable}
						onSetClosable={setPlaceClosable}
					/>
				</PlacesProvider>
			</div>
			<div className="worldWater-ProductExplorerHeader-views">
				<ViewSelect
					open={isViewOpen}
					onSetOpen={handleViewOpen}
					closable={isViewClosable}
					onSetClosable={setViewClosable}
				/>
			</div>
			<Toolbar />
		</Header>
	);
};

ProductExplorerHeader.propTypes = {
	isPlaceOpen: PropTypes.bool,
	setPlaceOpen: PropTypes.func,
	isViewOpen: PropTypes.bool,
	setViewOpen: PropTypes.func,
};

export default ProductExplorerHeader;
