import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSection from '../components/TourStepsSection';
import TourStepsTitle from '../components/TourStepsTitle';

import '../style.scss';

const Title = () => (
	<TourStepsContainer>
		<TourStepsTitle>Product explorer</TourStepsTitle>
		<TourStepsSection>
			The product explorer application provides access and view on the basic
			surface water dynamic (SWD) products.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Title;
