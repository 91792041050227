import HomeIntro from './home/Intro';
import HomeCards from './home/Cards';
import ProductExplorerTitle from './productExplorer/Title';
import ProductExplorerMap from './productExplorer/Map';
import ProductExplorerViews from './productExplorer/Views';
import ProductExplorerCardsHeader from './productExplorer/Header';
import WaterExtentMap from './productExplorer/waterExtent/Map';
import WaterExtentAddMap from './productExplorer/waterExtent/AddMap';
import WaterExtentTimeline from './productExplorer/waterExtent/Timeline';
import WaterLevelMap from './productExplorer/waterLevel/Map';
import WaterLevelPanel from './productExplorer/waterLevel/Panel';
import StatisticsTitle from './productExplorer/statistics/Title';
import StatisticsHeader from './productExplorer/statistics/Header';
import StatisticsPanel from './productExplorer/statistics/Panel';

export default [
	{
		selector: '.worldWater-Home-header',
		content: HomeIntro,
	},
	{
		selector: '.worldWater-Home-cards',
		content: HomeCards,
	},
	{
		selector: '.worldWater-HeaderTitle',
		content: ProductExplorerTitle,
	},
	{
		selector: '.worldWater-Header',
		content: ProductExplorerCardsHeader,
	},
	{
		selector: '.worldWater-PlaceMapContainer',
		content: ProductExplorerMap,
	},
	{
		selector: '.worldWater-ViewCardsContainer',
		content: ProductExplorerViews,
	},
	{
		selector: '.worldWater-productExplorer-content',
		content: WaterExtentMap,
	},
	{
		selector: '.worldWater-Timeline',
		content: WaterExtentTimeline,
	},
	{
		selector: '.worldWater-productExplorer-content',
		content: WaterExtentAddMap,
	},
	{
		selector: '.worldWater-productExplorer-content',
		content: WaterLevelMap,
	},
	{
		selector: '.worldWater-WaterLevelPanel',
		content: WaterLevelPanel,
	},
	{
		selector: '.worldWater-HeaderTitle',
		content: StatisticsTitle,
	},
	{
		selector: '.worldWater-Header',
		content: StatisticsHeader,
	},
	{
		selector: '.worldWater-StatisticsPanel',
		content: StatisticsPanel,
	},
];
