import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../../../state/Select';
import Presentation from './StatisticItem';

const mapStateToProps = state => {
	return {
		value: Select.worldWater.waterLevel.getPointSatellite(state),
	};
};

export default connect(mapStateToProps)(Presentation);
