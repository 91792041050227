import {worldCountriesMapLayerKey} from '../../constants/app';
import worldCountries from '../geometries/worldCountries.json';
import placeSelectMapLayerStyle from '../mapStyles/placeSelectMapLayerStyle';

export default {
	key: worldCountriesMapLayerKey,
	type: 'vector',
	options: {
		features: worldCountries?.features,
		fidColumnName: 'g',
		style: placeSelectMapLayerStyle?.data?.definition,
	},
};
