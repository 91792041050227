import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useRef} from 'react';
import './style.scss';
import DownloadChart from '../../../../../../../common/DownloadChart';

const ChartWrapper = ({children, title, className, data, Tools}) => {
	const chartRef = useRef();

	return (
		<div
			className={classNames(
				'worldWater-AdvancedStatistics-chartWrapper',
				{},
				className
			)}
			ref={chartRef}
		>
			<div className="worldWater-AdvancedStatistics-chartHeader">
				<h4 className="worldWater-AdvancedStatistics-chartTitle">{title}</h4>
				{Tools ? (
					<div className="worldWater-AdvancedStatistics-chartTools">
						{Tools}
					</div>
				) : null}
				<DownloadChart chartRef={chartRef} label={title} data={data} />
			</div>
			<div
				className={classNames(
					'worldWater-AdvancedStatistics-chart',
					{},
					className
				)}
			>
				{children}
			</div>
		</div>
	);
};

ChartWrapper.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	Tools: PropTypes.node,
};

export default ChartWrapper;
