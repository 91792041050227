import DashboardSection from '../DashboardSection';
import './style.scss';
import MaxLevel from './items/MaxLevel';
import MinLevel from './items/MinLevel';
import AvgLevel from './items/AvgLevel';
import Type from './items/Type';
import SelectedPointSelect from './SelectedPointsSelect';
import Satellite from './items/Satellite';
import Select from '../../../../../../../state/Select';
import {connect} from '@gisatcz/ptr-state';
import PropTypes from 'prop-types';

const PointStatisticsSection = ({activeIndicatorKey}) => {
	let indicator = 'level';
	if (activeIndicatorKey === 'waterVolume') {
		indicator = 'volume';
	} else if (activeIndicatorKey === 'waterExtent') {
		indicator = 'extent';
	}

	return (
		<DashboardSection
			title="Point info & statistics"
			className="worldWater-PointStatisticsSection"
		>
			<SelectedPointSelect />
			<div className="worldWater-PointStatisticsSection-content">
				<div>
					<MaxLevel title={`Maximum ${indicator}`} />
					<MinLevel title={`Minimum ${indicator}`} />
					<AvgLevel title={`Average ${indicator}`} />
				</div>
				<div>
					<Type title="Point type" />
					<Satellite title="Satellite" />
				</div>
			</div>
		</DashboardSection>
	);
};

PointStatisticsSection.propTypes = {
	activeIndicatorKey: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		activeIndicatorKey:
			Select.worldWater.waterLevel.indicators.getActiveKey(state),
	};
};

export default connect(mapStateToProps)(PointStatisticsSection);
