import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const ChangeWaterFrequency = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Change intensity in surface water occurrences" />
		<MetadataItemInfoSectionParagraph text="This indicator is calculated as regression across the five annual surface water frequency products. The slope of the regression indicates the intensity of change (gain and loss) in percent. This product shows the long term trend of a water body’s extent. In the product, the period between 2017 and 2021 were taken into account. However, the longer the time series, the more reliable the result." />
		<MetadataItemInfoSectionParagraph
			title="Frequency:"
			text=" One time (of the maximum observation period)."
		/>
		<MetadataItemInfoSectionLegendInfo
			items={[
				'-100: Decrease',
				'0: No change',
				'100: Increase',
				'126: Unchanged not water',
				'127: No data',
			]}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/change_water_frequency.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default ChangeWaterFrequency;
