import PropTypes from 'prop-types';

const SectionInfoWrapper = ({children}) => {
	return (
		<div className="worldWater-StatisticsSection-infoWrapper">{children}</div>
	);
};

SectionInfoWrapper.propTypes = {
	children: PropTypes.node,
};

export default SectionInfoWrapper;
