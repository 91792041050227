// import {useEffect} from 'react';
// import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import './style.scss';

const PeriodSwitch = () => {
	return (
		<ButtonSwitch
			ghost
			className="worldWater-PeriodSwitch worldWater-ButtonSwitch"
			onClick={() => {}}
		>
			<ButtonSwitchOption
				// key={level.key}
				// value={level.key}
				active
				// disabled={activePlaceKeys?.length !== 1 || countryLevelDisabled}
			>
				2017 - 2021
				<div className="ptr-button-caption alternative">17 - 21</div>
			</ButtonSwitchOption>
		</ButtonSwitch>
	);
};

PeriodSwitch.propTypes = {};

export default PeriodSwitch;
