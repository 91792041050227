import PropTypes from 'prop-types';
import Card from '../../../../../../common/Card';
import Graphic from './Graphic';
import './style.scss';

const ViewCard = ({name, viewName, disabled}) => {
	return (
		<Card className="worldWater-ViewCard" floating disabled={disabled}>
			<div className="worldWater-ViewCard-title">
				<span>{name}</span>
			</div>
			<div className="worldWater-ViewCard-content">
				<Graphic view={viewName} />
			</div>
		</Card>
	);
};

ViewCard.propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
	viewName: PropTypes.string,
};

export default ViewCard;
