import PropTypes from 'prop-types';
import ProgressChartSection from './ProgressChartSection';
import PointStatisticsSection from './PointStatisticsSection';

import './style.scss';
import NoDataPlaceholder from '../../../../../common/NoDataPlaceholder';

const WaterLevelDashboard = ({noData, chartRef}) => {
	return (
		<div className="worldWater-WaterLevelDashboard">
			{noData ? (
				<NoDataPlaceholder>
					Select point in map to display data...
				</NoDataPlaceholder>
			) : (
				<>
					<ProgressChartSection chartRef={chartRef} />
					<PointStatisticsSection />
				</>
			)}
		</div>
	);
};

WaterLevelDashboard.propTypes = {
	noData: PropTypes.bool,
	chartRef: PropTypes.object,
};

export default WaterLevelDashboard;
