import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useTour} from '@reactour/tour';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import {useEffect, useState} from 'react';

import './style.scss';

const TourIcon = ({isHome}) => {
	const {isOpen, setIsOpen} = useTour();
	const [isAnimating, setIsAnimating] = useState();

	useEffect(() => {
		setIsAnimating(true);
		setTimeout(() => {
			setIsAnimating(false);
		}, 8000);
	}, []);

	return (
		<IconTool
			className={classnames('worldWater-Home-tourIcon', {
				'is-active': isOpen,
				'is-Home': isHome,
				'is-animating': isAnimating && !isOpen,
			})}
			icon={'ri-help'}
			onClick={() => {
				setIsOpen(true);
				setIsAnimating(false);
			}}
			tooltip={{text: 'TourGuide', position: 'left', component: Tooltip}}
		/>
	);
};

TourIcon.propTypes = {
	isHome: PropTypes.bool,
};

export default TourIcon;
