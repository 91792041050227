import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';
import TourStepsSubtitle from '../../components/TourStepsSubtitle';

import '../../style.scss';

const Panel = () => (
	<TourStepsContainer>
		<TourStepsTitle>Statistics panel</TourStepsTitle>
		<TourStepsSubtitle>
			We selected 2 points for you. See bellow the statistics about these
			points.
		</TourStepsSubtitle>
		<TourStepsSection>
			You can pick either water level, volume or extent and choose between
			absolute or relative data.
			<br />
			Control the chart, toggle between lines or points and filter the data
			using the timeline bellow the chart.
			<br />
			On the right side, you can view summary statistics of a point, select one
			of the active points and display its statistics.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Panel;
