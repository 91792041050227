import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {TourProvider} from '@reactour/tour';

import steps from './steps';
import {getRouter} from '../../router';

import './style.scss';

const waterExtentDefaultLayer = {
	layerTemplateKey: '8c15b70b-416a-4002-a693-725c6bf98ca1',
	filterByActive: {
		application: true,
		place: true,
	},
	metadataModifiers: {
		applicationKey: 'esaWorldWater',
		placeKey: 'c657f887-a734-4ffe-b904-725632193318',
		periodKey: '004dcdb3-42db-47fe-8f2a-deb66a5fa1b0',
	},
	key: 'c94db833-bba0-40d5-a97a-d29aad4ff308',
};

const style = {
	popover: base => ({
		...base,
		maxWidth: '80%',
		'--reactour-accent': 'hsl(200.35deg, 100%, 45.1%)',
	}),
	badge: base => ({
		...base,
		backgroundColor: 'hsl(200.35deg, 100%, 45.1%)',
		color: 'black',
		//height: step > 8 ? '2.5em' : '1.875em',
		//lineHeight: step > 8 ? 2.6 : 2,
	}),
	dot: base => ({
		...base,
		cursor: 'inherit',
	}),
};

const viewKeys = [
	'e832cbc9-b660-4ec0-aebf-6540a036e54c',
	'a969ad80-887c-47d4-9d98-0e2fb747fcc7',
];

const Tour = ({
	children,
	activeView,
	setActivePlaceByKeyExplorer,
	setPlaceOpen,
	setViewOpen,
	addTourLayerToMap,
	activeLayers,
	isViewOpen,
	isPlaceOpen,
	activeMapKey,
	controlTimeline,
	addNewMapWindow,
	setMapWindow,
	activeMapWindows,
	setActivePlaceForStatistics,
	openChartSection,
	activePlaceKeys,
	timelineIsOpen,
	setWaterLevelActiveSelection,
	activeMapLayers,
	clearWaterLevelActiveSelection,
}) => {
	const [step, setStep] = useState(0);
	const [tourIsOpen, setTourIsOpen] = useState();
	const [loadingStep, setLoadingStep] = useState(false);

	const router = getRouter();

	useEffect(() => {
		if (
			activeView?.params?.parsedQueryString?.view === viewKeys[0] &&
			!activeLayers &&
			tourIsOpen &&
			step === 6
		) {
			addTourLayerToMap(activeMapKey, waterExtentDefaultLayer);
		}
		if (tourIsOpen && loadingStep === 8) {
			activeMapWindows?.length === 1 ? addNewMapWindow() : null;
			setTimeout(() => {
				setStep(8);
			}, 400);
			timelineIsOpen ? controlTimeline(false) : null;
			setLoadingStep(false);
		}
	}, [activeLayers, activeView]);

	if (
		tourIsOpen &&
		loadingStep === 10 &&
		activeView?.name === '/product-explorer'
	) {
		router.nav(
			'/product-explorer/c657f887-a734-4ffe-b904-725632193318?view=a969ad80-887c-47d4-9d98-0e2fb747fcc7'
		);
	} else if (
		tourIsOpen &&
		loadingStep === 10 &&
		activeView?.params?.queryString ===
			'view=a969ad80-887c-47d4-9d98-0e2fb747fcc7' &&
		activeMapLayers?.[0]?.key === 'waterLevel'
	) {
		setTimeout(() => {
			setWaterLevelActiveSelection(activeMapKey);
		}, 400);

		setTimeout(() => {
			setStep(10);
		}, 800);

		setLoadingStep(false);
	}

	// on tour open
	const onTourOpen = () => {
		setTourIsOpen(true);
		switch (activeView?.name) {
			case '/home':
				setStep(0);
				break;
			case '/product-explorer':
				setStep(2);
				break;
			case '/product-explorer/place':
				if (isViewOpen) {
					setActivePlaceByKeyExplorer();
					setStep(5);
				} else if (isPlaceOpen) {
					setStep(2);
				} else {
					setActivePlaceByKeyExplorer();
					if (activeView?.params?.parsedQueryString?.view === viewKeys[0]) {
						if (!activeLayers) {
							addTourLayerToMap(activeMapKey, waterExtentDefaultLayer);
						}
						setStep(6);
					} else {
						setStep(9);
					}
				}
				break;
			case '/statistics':
				setStep(11);
				break;
			default:
				setStep(0);
				break;
		}
	};

	const setCurrentStep = step => {
		switch (step) {
			case 0:
				router.nav('/home');
				setStep(step);
				break;
			case 1:
				router.nav('/home');
				setStep(step);
				break;
			case 2:
				router.nav('/product-explorer');
				setPlaceOpen(true);
				setStep(step);
				break;
			case 3:
				setStep(step);
				break;
			case 4:
				setPlaceOpen(true);
				setViewOpen(false);
				setStep(step);
				break;
			case 5:
				setActivePlaceByKeyExplorer();
				setPlaceOpen(false);
				setViewOpen(true);
				setStep(step);
				break;
			case 6:
				router.nav(
					'/product-explorer/c657f887-a734-4ffe-b904-725632193318?view=e832cbc9-b660-4ec0-aebf-6540a036e54c'
				);
				setViewOpen(false);
				controlTimeline(false);
				!activeLayers
					? addTourLayerToMap(activeMapKey, waterExtentDefaultLayer)
					: null;
				setStep(step);
				break;
			case 7:
				controlTimeline(true);
				setTimeout(() => {
					setStep(step);
				}, 400); // timeline's css transition duration
				break;
			case 8:
				activeView?.params?.parsedQueryString?.view === viewKeys[1]
					? (router.nav(
							'/product-explorer/c657f887-a734-4ffe-b904-725632193318?view=e832cbc9-b660-4ec0-aebf-6540a036e54c'
					  ),
					  setTimeout(() => {
							addTourLayerToMap(activeMapKey, waterExtentDefaultLayer);
					  }, 400),
					  setLoadingStep(8))
					: (activeMapWindows?.length > 1 ? setMapWindow() : addNewMapWindow(),
					  setTimeout(() => {
							setStep(step);
					  }, 100));
				controlTimeline(false);
				break;
			case 9:
				router.nav(
					'/product-explorer/c657f887-a734-4ffe-b904-725632193318?view=a969ad80-887c-47d4-9d98-0e2fb747fcc7'
				);
				setStep(step);
				break;
			case 10:
				activeView?.name === '/statistics'
					? (router.nav('/product-explorer'), setLoadingStep(10))
					: (setWaterLevelActiveSelection(activeMapKey),
					  setTimeout(() => {
							setStep(step);
					  }, 400));
				break;
			case 11:
				clearWaterLevelActiveSelection();
				router.nav(
					'/statistics?urlPlaceKey=c657f887-a734-4ffe-b904-725632193318&view=14b6b265-13a0-47dc-ac48-1b72397b6411'
				);
				setStep(step);
				break;
			case 12:
				!activePlaceKeys ? setActivePlaceForStatistics() : null;
				setStep(step);
				break;
			case 13:
				openChartSection();
				setStep(step);
				break;
			default:
				break;
		}
	};

	return (
		<TourProvider
			styles={style}
			steps={steps}
			currentStep={step}
			setCurrentStep={setCurrentStep}
			afterOpen={onTourOpen}
			beforeClose={() => setTourIsOpen(false)}
			disableInteraction
			disableDotsNavigation
		>
			{children}
		</TourProvider>
	);
};

Tour.propTypes = {
	children: PropTypes.node,
	activeView: PropTypes.object,
	setActivePlaceByKeyExplorer: PropTypes.func,
	setPlaceOpen: PropTypes.func,
	setViewOpen: PropTypes.func,
	addTourLayerToMap: PropTypes.func,
	activeLayers: PropTypes.array,
	isViewOpen: PropTypes.bool,
	isPlaceOpen: PropTypes.bool,
	activeMapKey: PropTypes.string,
	controlTimeline: PropTypes.func,
	addNewMapWindow: PropTypes.func,
	setMapWindow: PropTypes.func,
	activeMapWindows: PropTypes.array,
	addPointForWaterLevel: PropTypes.func,
	setActivePlaceForStatistics: PropTypes.func,
	openChartSection: PropTypes.func,
	activePlaceKeys: PropTypes.array,
	timelineIsOpen: PropTypes.bool,
	setWaterLevelActiveSelection: PropTypes.func,
	clearWaterLevelActiveSelection: PropTypes.func,
	activeMapLayers: PropTypes.array,
};

export default Tour;
