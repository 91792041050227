import {connect, Select} from '@gisatcz/ptr-state';

import Presentation from './presentation';
import Action from '../../../state/Action';

// TODO
const filterByActive = {application: true};
const filter = {};
const order = [['nameDisplay', 'ascending']];
const start = 1;
const length = 1000;
const componentId = 'worldWaterProductViewer_PlacesControl';

const mapStateToProps = state => {
	return {
		places: Select.places.getIndexed(
			state,
			filterByActive,
			filter,
			order,
			start,
			length
		),
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		return {
			onMount: () => {
				dispatch(
					Action.places.useIndexed(
						filterByActive,
						filter,
						order,
						start,
						length,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.places.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
