import {commonReducers} from '@gisatcz/ptr-state';
import ActionTypes from '../../../../constants/ActionTypes';

const INITIAL_STATE = {
	activeKey: 'absolute',
	byKey: {
		absolute: {
			key: 'absolute',
			data: {
				nameDisplay: 'Absolute',
				nameInternal: 'Abs',
			},
		},
		relative: {
			key: 'relative',
			data: {
				nameDisplay: 'Relative',
				nameInternal: 'Rel',
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.WORLD_WATER.WATER_LEVEL.VALUE_TYPES.SET_ACTIVE_KEY:
			return commonReducers.setActive(state, action);
		default:
			return state;
	}
};
