import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const MinimumExtent = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Yearly maps of minimum water extent" />
		<MetadataItemInfoSectionParagraph text="The minimum water extent layer includes only those pixels that have been classified as inundated/water covered in all available images during one year." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Yearly." />
		<MetadataItemInfoSectionLegendInfo
			items={['0: Not water', '1: Water', '255: No data']}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/minimum_extent.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default MinimumExtent;
