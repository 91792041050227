import PropTypes from 'prop-types';
import WaterPresence from './WaterPresence';
import SeasonalPermanentWaterExtent from './SeasonalPermanentWaterExtent';
import SeasonalWaterExtent from './SeasonalWaterExtent';
import SurfaceWaterChanges from './SurfaceWaterChanges';

const Chart = ({componentKey, ...props}) => {
	switch (componentKey) {
		case 'WaterPresence':
			return <WaterPresence {...props} chartKey={componentKey} store="basin" />;
		case 'WaterPresenceCountry':
			return (
				<WaterPresence {...props} chartKey={componentKey} store="countries" />
			);
		case 'SeasonalPermanentWaterExtent':
			return (
				<SeasonalPermanentWaterExtent
					{...props}
					chartKey={componentKey}
					store="basin"
				/>
			);
		case 'SeasonalPermanentWaterExtentCountry':
			return (
				<SeasonalPermanentWaterExtent
					{...props}
					chartKey={componentKey}
					store="countries"
				/>
			);
		case 'SeasonalWaterExtent':
			return (
				<SeasonalWaterExtent {...props} chartKey={componentKey} store="basin" />
			);
		case 'SeasonalWaterExtentCountry':
			return (
				<SeasonalWaterExtent
					{...props}
					chartKey={componentKey}
					store="countries"
				/>
			);
		case 'SurfaceWaterChanges':
			return (
				<SurfaceWaterChanges {...props} chartKey={componentKey} store="basin" />
			);
		case 'SurfaceWaterChangesCountry':
			return (
				<SurfaceWaterChanges
					{...props}
					chartKey={componentKey}
					store="countries"
				/>
			);
		default:
			return null;
	}
};

Chart.propTypes = {
	componentKey: PropTypes.string,
	sources: PropTypes.array,
};

export default Chart;
