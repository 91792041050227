import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import classes_enumerations from '../../../../../../../data/classes_enumerations';
import './style.scss';

const ItemRow = ({children, className}) => {
	const classes = classnames(
		'worldWater-StatisticsPanelAttributeRow',
		{},
		className
	);

	return <div className={classes}>{children}</div>;
};

ItemRow.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

const ItemCell = ({children, className}) => {
	const classes = classnames(
		'worldWater-StatisticsPanelAttributeCell',
		{},
		className
	);

	return <div className={classes}>{children}</div>;
};

ItemCell.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

const ItemHeader = ({
	name,
	classLabel,
	latest,
	average,
	wholePeriod,
	lastYearAverage,
	shiftedTitle,
}) => {
	const titleClasses = classnames('title', {
		'is-shifted': shiftedTitle,
	});

	return (
		<ItemRow className="header">
			<ItemCell className={titleClasses}>
				{name} <span>{classLabel ? `(${classLabel})` : ''}</span>
			</ItemCell>
			{latest ? <ItemCell className="valueName">{latest}</ItemCell> : null}
			{lastYearAverage ? (
				<ItemCell className="valueName">Average ({lastYearAverage})</ItemCell>
			) : null}
			{average ? (
				<ItemCell className="valueName">Average {average}</ItemCell>
			) : null}
			{wholePeriod ? (
				<ItemCell className="valueName">{wholePeriod}</ItemCell>
			) : null}
			<ItemCell className="trend" />
		</ItemRow>
	);
};

ItemHeader.propTypes = {
	name: PropTypes.string,
	classLabel: PropTypes.string,
	latest: PropTypes.string,
	average: PropTypes.string,
	wholePeriod: PropTypes.string,
	lastYearAverage: PropTypes.string,
	shiftedTitle: PropTypes.bool,
};

const StatisticsPanelAttribute = ({attributeKey, title, data, unit}) => {
	const numOfItems = data?.length;
	if (numOfItems) {
		const firstItem = data[0].data;
		const {
			average,
			latest,
			lastYearAverage,
			wholePeriod,
			class: cls,
		} = firstItem;
		const classLabel = classes_enumerations[attributeKey]?.[cls];

		return (
			<div className="worldWater-StatisticsPanelAttribute">
				<ItemHeader
					name={title}
					classLabel={classLabel}
					latest={latest?.name}
					average={average?.name}
					wholePeriod={wholePeriod?.name}
					lastYearAverage={lastYearAverage?.name}
					shiftedTitle={numOfItems === 1}
				/>
				{data.map(item => {
					const {name, data, color} = item;
					const average = data?.average;
					const latest = data?.latest;
					const wholePeriod = data?.wholePeriod;
					const lastYearAverage = data?.lastYearAverage;
					let trendValue = null;
					if (latest && average?.value) {
						trendValue =
							((latest?.value - average?.value) * 100) / average?.value;
					} else if (lastYearAverage?.value && average?.value) {
						trendValue =
							((lastYearAverage?.value - average?.value) * 100) /
							average?.value;
					} else if (latest && wholePeriod?.value) {
						trendValue =
							((latest?.value - wholePeriod?.value) * 100) / wholePeriod?.value;
					}

					const trendClasses = classnames('trend', {
						'is-positive': trendValue >= 1,
						'is-very-positive': trendValue >= 50,
						'is-negative': trendValue <= -1,
						'is-very-negative': trendValue <= -50,
						'is-neutral': Math.abs(trendValue) < 1,
					});

					return (
						<ItemRow key={name} className="item">
							<ItemCell className="areaName">
								{numOfItems > 1 ? (
									<>
										{
											<div className="worldWater-StatisticsPanelAttributeCell-color">
												<span style={{backgroundColor: color}} />
											</div>
										}
										{name}
									</>
								) : null}
							</ItemCell>
							{latest ? (
								<ItemCell className="value">
									{latest?.value || latest?.value === 0 ? (
										<>
											{latest.value ? latest.value?.toFixed(3) : 0}{' '}
											<span>{unit}</span>
										</>
									) : (
										'-'
									)}
								</ItemCell>
							) : null}
							{lastYearAverage ? (
								<ItemCell className="value">
									{lastYearAverage?.value || lastYearAverage?.value === 0 ? (
										<>
											{lastYearAverage.value
												? lastYearAverage.value?.toFixed(3)
												: 0}{' '}
											<span>{unit}</span>
										</>
									) : (
										'-'
									)}
								</ItemCell>
							) : null}
							{average ? (
								<ItemCell className="value">
									{average?.value || average?.value === 0 ? (
										<>
											{average.value ? average.value?.toFixed(3) : 0}{' '}
											<span>{unit}</span>
										</>
									) : (
										'-'
									)}
								</ItemCell>
							) : null}
							{wholePeriod ? (
								<ItemCell className="value">
									{wholePeriod?.value?.toFixed(3)} <span>{unit}</span>
								</ItemCell>
							) : null}
							{trendValue || trendValue === 0 ? (
								<ItemCell className={trendClasses}>
									<Icon icon="ri-back" />
									{trendValue.toFixed(2)} <span>%</span>
								</ItemCell>
							) : (
								<ItemCell className={trendClasses}>-</ItemCell>
							)}
						</ItemRow>
					);
				})}
			</div>
		);
	} else {
		return null;
	}

	// TODO vzít z první názvy
	// TODO řádek per stát, když jen jeden řádek, tak bez názvu státu
};

StatisticsPanelAttribute.propTypes = {
	attributeKey: PropTypes.string,
	title: PropTypes.string,
	data: PropTypes.array,
	unit: PropTypes.string,
};

export default StatisticsPanelAttribute;
