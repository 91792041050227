import PropTypes from 'prop-types';

const VectorMapTooltip = ({layer}) => {
	if (layer?.layer?.id === 'waterLevel') {
		const {id, name, type} = layer.object.properties;
		return (
			<div className="worldWater-WaterLevelMapTooltip">
				<div className="worldWater-WaterLevelMapTooltip-type">{type}</div>
				<div className="worldWater-WaterLevelMapTooltip-title">
					{name || id}
				</div>
				{name ? (
					<div className="worldWater-WaterLevelMapTooltip-id">ID: {id}</div>
				) : null}
			</div>
		);
	} else {
		return null;
	}
};

VectorMapTooltip.propTypes = {
	layer: PropTypes.object,
};

export default VectorMapTooltip;
