import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../../state/Select';
import Action from '../../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		data: Select.worldWater.statistics?.getSwcChartData(
			state,
			ownProps.store,
			ownProps.chartKey,
			ownProps.sources,
			ownProps.isMultiselect
		),
		activePlaces: Select.places.getActiveModels(state),
		activeOptionKey: Select.components.get(
			state,
			ownProps.chartKey,
			'valueType'
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(Action.components.set(ownProps.chartKey, 'valueType', 'abs'));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
