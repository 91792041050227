import PropTypes from 'prop-types';
import ProductExplorer from '../pages/ProductExplorer';
import Home from '../pages/Home';
import Statistics from '../pages/Statistics';
import Unknown from '../pages/Unknown';

const getActiveScreenComponent = page => {
	switch (page) {
		case 'home':
			return <Home />;
		case '/product-explorer':
		case '/product-explorer/place':
			return <ProductExplorer />;
		case '/statistics':
			return <Statistics />;
		default:
			return <Unknown />;
	}
};

const Router = ({page}) => {
	const ActiveScreenComponent = getActiveScreenComponent(page);
	return <>{ActiveScreenComponent}</>;
};

Router.propTypes = {
	page: PropTypes.string,
};

export default Router;
