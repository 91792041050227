import {createSelector} from 'reselect';
import {
	compact as _compact,
	difference as _difference,
	find as _find,
	flatten as _flatten,
	uniq as _uniq,
} from 'lodash';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import indicatorsSelectors from './indicators/selectors';
import pointsSelectors from './points/selectors';
import featuresSelectors from './features/selectors';
import satellitesSelectors from './satellites/selectors';
import valueTypesSelectors from './valueTypes/selectors';
import utils from '../../../utils';

/**
 * Simple safari detection based on user agent test
 */
// const isSafari = () =>
// 	/^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// const buildURI = (csv, uFEFF) => {
// 	const type = isSafari() ? 'application/csv' : 'text/csv';
// 	const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], {type});
// 	const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;
//
// 	const URL = window.URL || window.webkitURL;
//
// 	return typeof URL.createObjectURL === 'undefined'
// 		? dataURI
// 		: URL.createObjectURL(blob);
// };

// const getUriData = createRecomputeSelector(props => {
// 	const waterLevelChartData = getWaterLevelChartData(props);
// 	if (waterLevelChartData) {
// 		const tooltipAttributeByName =
// 			getTooltipAttributeByName(deviationAttribute);
//
// 		let csv = 'Period,Level,Deviation\n';
// 		const sortedData = _sortBy(
// 			waterLevelChartData.data[0].data,
// 			i => i.period.nameDisplay
// 		);
// 		sortedData.forEach(record => {
// 			const deviation = Object.hasOwn(record, tooltipAttributeByName.key)
// 				? record[tooltipAttributeByName.key]
// 				: '-';
// 			csv += `${record.period.nameDisplay},${
// 				record[waterLevelChartData.ySourcePath]
// 			},${deviation}`;
// 			csv += '\n';
// 		});
//
// 		return buildURI(csv);
// 	} else {
// 		return null;
// 	}
// });

const getDataForLineChart = createSelector(
	[
		CommonSelect.places.getActive,
		CommonSelect.selections.getActive,
		pointsSelectors.getAllAsObject,
		indicatorsSelectors.getActiveKey,
		valueTypesSelectors.isRelative,
	],
	(activePlace, activeSelection, points, activeIndicatorKey, isRelative) => {
		if (activePlace && activeSelection && points && activeIndicatorKey) {
			const placeName = activePlace?.data?.nameInternal;
			const featureKeys = activeSelection?.data?.featureKeysFilter?.keys;
			if (placeName && featureKeys?.length) {
				const chartData = [];
				featureKeys.forEach(featureKey => {
					const point = points[featureKey];
					const pointData = point?.data?.data;
					const timeSerie = pointData?.[activeIndicatorKey]?.timeserie;

					let data = [];
					if (timeSerie?.length) {
						//sort data by time
						timeSerie?.sort((a, b) => {
							if (a.time < b.time) {
								return -1;
							}
							if (a.time > b.time) {
								return 1;
							}
						});
						data = timeSerie?.map(item => {
							let type = 'Level';
							let typeUnit = 'm';
							let unit = 'm';
							if (activeIndicatorKey === 'waterVolume') {
								type = 'Volume';
								typeUnit = (
									<>
										m<sup>3</sup>
									</>
								);
								unit = 'm3';
							} else if (activeIndicatorKey === 'waterExtent') {
								type = 'Extent';
								typeUnit = (
									<>
										km<sup>2</sup>
									</>
								);
								unit = 'km2';
							}
							const deviationType = activeIndicatorKey;
							return {
								x: item.time,
								y: isRelative ? item.relValue : item.value,
								type,
								deviationType,
								[deviationType]: item.std,
								pointId: featureKey,
								typeUnit,
								unit,
								color: utils.getSelectedFeaturePrimaryColor(
									featureKey,
									activeSelection?.data
								),
							};
						});
					}

					chartData.push({
						id: featureKey,
						name: point?.data?.name,
						color: utils.getSelectedFeaturePrimaryColor(
							featureKey,
							activeSelection?.data
						),
						data,
					});
				});
				return chartData?.length ? chartData : null;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getSelectedPointKeys = createSelector(
	[CommonSelect.selections.getActive],
	activeSelection => {
		return activeSelection?.data?.featureKeysFilter?.keys;
	}
);

const getSelectedPoints = createSelector(
	[getSelectedPointKeys, pointsSelectors.getAllAsObject],
	(selectedPointKeys, points) => {
		if (selectedPointKeys && points) {
			return selectedPointKeys.map(key => {
				return points?.[key] || null;
			});
		} else {
			return null;
		}
	}
);

const getDataForDownload = createSelector(
	[
		CommonSelect.places.getActive,
		CommonSelect.selections.getActive,
		pointsSelectors.getAllAsObject,
		indicatorsSelectors.getActiveKey,
		valueTypesSelectors.isRelative,
	],
	(activePlace, activeSelection, points, activeIndicatorKey, isRelative) => {
		if (activePlace && activeSelection && points && activeIndicatorKey) {
			const placeName = activePlace?.data?.nameInternal;
			const featureKeys = activeSelection?.data?.featureKeysFilter?.keys;
			if (placeName && featureKeys?.length) {
				const chartData = [];
				featureKeys.forEach(featureKey => {
					const point = points[featureKey];
					const pointData = point?.data?.data;
					const timeSerie = pointData?.[activeIndicatorKey]?.timeserie;

					let data = [];
					if (timeSerie?.length) {
						//sort data by time
						timeSerie?.sort((a, b) => {
							if (a.time < b.time) {
								return -1;
							}
							if (a.time > b.time) {
								return 1;
							}
						});
						data = timeSerie?.map(item => {
							let unit = 'm';
							if (activeIndicatorKey === 'waterVolume') {
								unit = 'm3';
							} else if (activeIndicatorKey === 'waterExtent') {
								unit = 'km2';
							}
							return {
								date: item.time,
								value: isRelative ? item.relValue : item.value,
								type: activeIndicatorKey,
								deviation: item.std,
								unit,
							};
						});
					}

					chartData.push({
						name: point?.data?.name,
						id: featureKey,
						data,
					});
				});
				return chartData?.length ? chartData : null;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getDisabledIndicatorKeys = createSelector(
	[getSelectedPoints, indicatorsSelectors.getKeys],
	(selectedPoints, indicatorKeys) => {
		if (selectedPoints) {
			const availableMeasurements = _compact(
				_uniq(
					_flatten(
						selectedPoints.map(point => point?.data?.availableMeasurements)
					)
				)
			);

			if (availableMeasurements) {
				return _difference(indicatorKeys, availableMeasurements);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getSelectedPointForPointInfo = createSelector(
	[
		getSelectedPoints,
		state => CommonSelect.components.get(state, 'SelectedPointSelect', 'key'),
	],
	(points, selectedFeatureKey) => {
		if (points && selectedFeatureKey) {
			return _find(points, point => point?.key === selectedFeatureKey);
		} else {
			return null;
		}
	}
);

const getPointType = createSelector([getSelectedPointForPointInfo], point => {
	return point?.data?.type || null;
});

const getPointSatellite = createSelector(
	[getSelectedPointForPointInfo],
	point => {
		return point?.data?.sat || point?.data?.track || null;
	}
);

const getStatistics = createSelector(
	[
		CommonSelect.places.getActive,
		state => CommonSelect.components.get(state, 'SelectedPointSelect', 'key'),
		pointsSelectors.getAllAsObject,
		indicatorsSelectors.getActiveKey,
	],
	(activePlace, selectedFeatureKey, points, activeIndicatorKey) => {
		if (activePlace && selectedFeatureKey && points && activeIndicatorKey) {
			const placeName = activePlace?.data?.nameInternal;
			if (placeName && selectedFeatureKey) {
				const pointData = points[selectedFeatureKey]?.data?.data;
				return pointData?.[activeIndicatorKey]?.statistics;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getMaximum = createSelector(
	[getStatistics, valueTypesSelectors.isRelative],
	(statistics, isRelative) => {
		if (statistics) {
			if (isRelative) {
				return {
					value: statistics.maxValue - statistics.avgValue,
					deviation: statistics.maxValueDeviation,
				};
			} else {
				return {
					value: statistics.maxValue,
					deviation: statistics.maxValueDeviation,
				};
			}
		} else {
			return null;
		}
	}
);

const getMinimum = createSelector(
	[getStatistics, valueTypesSelectors.isRelative],
	(statistics, isRelative) => {
		if (statistics) {
			if (isRelative) {
				return {
					value: statistics.minValue - statistics.avgValue,
					deviation: statistics.minValueDeviation,
				};
			} else {
				return {
					value: statistics.minValue,
					deviation: statistics.minValueDeviation,
				};
			}
		} else {
			return null;
		}
	}
);

const getAverage = createSelector(
	[getStatistics, valueTypesSelectors.isRelative],
	(statistics, isRelative) => {
		if (statistics) {
			if (isRelative) {
				return {
					value: 0,
					deviation: statistics.avgDeviation,
				};
			} else {
				return {
					value: statistics.avgValue,
					deviation: statistics.avgDeviation,
				};
			}
		} else {
			return null;
		}
	}
);

export default {
	// getUriData,

	getSelectedPointKeys,
	getSelectedPoints,
	getDataForLineChart,
	getDataForDownload,
	getDisabledIndicatorKeys,
	getStatistics,
	getAverage,
	getMaximum,
	getMinimum,
	getPointType,
	getPointSatellite,

	points: pointsSelectors,
	features: featuresSelectors,
	indicators: indicatorsSelectors,
	satellites: satellitesSelectors,
	valueTypes: valueTypesSelectors,
};
