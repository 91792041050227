import PropTypes from 'prop-types';

import '../style.scss';

const TourStepsSubtitle = ({children}) => (
	<p className="worldWater-Tour-steps-subtitle">{children}</p>
);

TourStepsSubtitle.propTypes = {
	children: PropTypes.node,
};

export default TourStepsSubtitle;
