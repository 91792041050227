import {connect} from '@gisatcz/ptr-state';
import Presentation from './Attribute';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		items:
			ownProps.store === 'basin'
				? Select.selections
						.getActive(state)
						?.data?.featureKeysFilter?.keys.map(item => {
							return {
								key: item,
								name: item,
							};
						})
				: Select.places.getActiveModels(state)?.map(item => {
						return {
							key: item?.data?.nameInternal,
							name: item?.data?.nameDisplay,
						};
				  }),
		data: Select.worldWater.statistics.getSwcAttributeData(
			state,
			ownProps.store,
			ownProps.attributeKey,
			ownProps.attributeLatestKey,
			ownProps.type
		),
		selectionData: Select.selections.getActive(state)?.data,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
