import PropTypes from 'prop-types';
import Chart from '../../components/Chart';

import './style.scss';

const SingleHydrobasinCharts = ({charts}) => {
	return (
		<>
			{charts?.map(chart => {
				const {componentKey, sources} = chart;
				if (componentKey) {
					return (
						<Chart
							key={componentKey}
							componentKey={componentKey}
							sources={sources}
						/>
					);
				} else {
					return null;
				}
			})}
		</>
	);
};

SingleHydrobasinCharts.propTypes = {
	charts: PropTypes.array,
	chartComponentKeyByChartKey: PropTypes.object,
};

export default SingleHydrobasinCharts;
