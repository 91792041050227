import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSection from '../components/TourStepsSection';
import TourStepsTitle from '../components/TourStepsTitle';
import TourStepsSubtitle from '../components/TourStepsSubtitle';

import '../style.scss';

const Intro = () => (
	<TourStepsContainer>
		<TourStepsTitle>Introduction</TourStepsTitle>
		<TourStepsSubtitle>
			Welcome to the <b>WorldWater portal</b>.
		</TourStepsSubtitle>
		<TourStepsSection>
			This is a tour guide that presents the main functionalities and principles
			of this web portal. If you need help at any point of the portal, just
			click on the tour icon and you will be taken on a tour in the current
			location.
		</TourStepsSection>
		<TourStepsSection>
			Use the arrows below to move back and forth. Click outside the window to
			close the tour.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Intro;
