export default [
	{
		key: '0b5fbe11-7cd2-44a8-a61b-47dd948aea70',
		data: {
			nameDisplay: 'Frequency',
			nameInternal: '1.6_Water_Frequency',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeScale: {
									fill: {
										inputInterval: [1, 25, 50, 75, 100],
										outputInterval: [
											'#fbfecb',
											'#a1dab4',
											'#41b6c4',
											'#2c7fb8',
											'#253494',
										],
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: '7acaeed5-665f-4d16-934a-183d156ad979',
		data: {
			nameInternal: '1.3_Maximum extent',
			nameDisplay: 'Maximum extent',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeValues: {
									1: {
										fill: '#38acff',
										name: 'Water',
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: '2958d772-2fd0-486b-9292-dfa6ab896639',
		data: {
			nameInternal: '1.1_Monthly_surface_water, 1.4_Minimum extent',
			nameDisplay: 'Minimum extent',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeValues: {
									1: {
										fill: '#0000ff',
										name: 'Water',
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: 'eae83a07-c4fd-4269-8846-1e1bc18e1ad8',
		data: {
			nameInternal: '1.2_Water_classification',
			nameDisplay: 'Water classification',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								bandIndex: 0,
								legend: true,
								values: {
									1: {
										color: '#0000ff',
										name: 'Permanent water',
									},
									2: {
										color: '#38acff',
										name: 'Seasonal Water',
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: '8c9d0141-32b8-45c3-93fd-8350643f8103',
		data: {
			nameInternal: '1.5_Water_seasonality',
			nameDisplay: 'Water seasonality',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeScale: {
									fill: {
										inputInterval: [1, 6, 12],
										outputInterval: ['#38acff', '#3076cf', '#253494'],
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: '6094ad1d-a815-4a6e-a6ee-32662660a68e',
		data: {
			nameInternal: '2.1_2.3_2.4',
			nameDisplay: 'Change',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeValues: {
									1: {
										fill: '#33a02c',
										name: 'Gain water',
									},
									2: {
										fill: '#a50003',
										name: 'Loss water',
									},
									3: {
										fill: '#0000ff',
										name: 'Unchanged water',
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: '3fcf6bf4-6733-41d2-8fb0-e64a06b869d5',
		data: {
			nameInternal: '2.3',
			nameDisplay: 'Change_maximum',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeValues: {
									1: {
										fill: '#b5e61d',
										name: 'Gain water',
									},
									2: {
										fill: '#e6a1aa',
										name: 'Loss water',
									},
									3: {
										fill: '#38acff',
										name: 'Unchanged water',
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: '4707ac97-dbf0-46e6-80fb-b4dddd535b1e',
		data: {
			nameInternal: '2.2',
			nameDisplay: 'Change water classification',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeValues: {
									1: {
										fill: '#33a02c',
										name: 'Gain permanent water',
									},
									2: {
										name: 'Loss permanent water',
										fill: '#a50003',
									},
									3: {
										name: 'Unchanged permanent water',
										fill: '#0000ff',
									},
									4: {
										name: 'Gain seasonal water',
										fill: '#b5e61d',
									},
									5: {
										name: 'Loss seasonal water',
										fill: '#e6a1aa',
									},
									6: {
										name: 'Unchanged seasonal water',
										fill: '#38acff',
									},
									7: {
										name: 'Permanent to seasonal',
										fill: '#ffc90e',
									},
									8: {
										name: 'Seasonal to permanent',
										fill: '#ff7f27',
									},
								},
							},
						],
					},
				],
			},
		},
	},
	{
		key: 'ef0e6b41-9506-4b1a-84ef-01df835a4477',
		data: {
			nameInternal: '3.1_change_intensity',
			nameDisplay: 'Change intensity',
			definition: {
				rules: [
					{
						styles: [
							{},
							{
								attributeKey: 'temporary',
								attributeScale: {
									fill: {
										inputInterval: [-100, -50, 0, 50, 100],
										outputInterval: [
											'#a50003',
											'#530002',
											'#000000',
											'#1a5016',
											'#33a02c',
										],
									},
								},
							},
						],
					},
				],
			},
		},
	},
];
