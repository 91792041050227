import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../state/Select';
import Action from '../../../../../../state/Action';
import {waterExtentTimeline} from '../../../../../../constants/app';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const mapSetKey = Select.maps.getActiveSetKey(state);

	return {
		layers: Select.worldWater.waterExtent.getLayers(
			state,
			ownProps.componentKey
		),
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, mapSetKey),
		activePlaceKey: Select.places.getActiveKey(state),
		isCollapsed: !Select.components.get(state, waterExtentTimeline, 'open'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleProductInActiveMap: data => {
			dispatch(Action.worldWater.waterExtent.handleLayerInActiveMap(data));
		},
		onCollapse: collapsed => {
			dispatch(Action.components.set(waterExtentTimeline, 'open', !collapsed));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
