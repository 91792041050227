import {create as createRouter} from '@gisatcz/ptr-router';
import Action from './state/Action';
import {getParsedQueryString} from './state/router/utils';
// base mapStyles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import './styles/index.scss';

let router;

const setupView = (dispatch, queryString) => {
	const parsedQueryString = getParsedQueryString(queryString);
	if (parsedQueryString.view) {
		//todo validate view
		const viewKey = parsedQueryString.view;
		dispatch(Action.views.setActiveKey(viewKey));
		dispatch(Action.worldWater.waterLevel.handleReferenceLayer());
	} else {
		//if active view key exist, it needs to override
		dispatch(Action.views.setActiveKey(null));
	}
};

function createRoutes(Store) {
	return {
		'': {
			name: 'home',
			handler: request => {
				setupView(Store.dispatch, request.queryString);
				request.context.next();
			},
		},
		'/product-explorer': {
			name: '/product-explorer',
			handler: request => {
				setupView(Store.dispatch, request.queryString);
				request.context.next();
			},
			children: {
				'/:urlPlaceKey': {
					name: '/product-explorer/place',
					handler: () => {
						Store.dispatch(Action.worldWater.waterLevel.handleReferenceLayer());
					},
				},
			},
		},
		'/statistics': {
			name: '/statistics',
			handler: request => {
				request.context.next();
			},
		},
	};
}

/**
 * Creates router instance that can be used to manipulate urls.
 *
 * App handler updates store with current page and it's up to views to react to the change.
 * In case of url change, redux selector possibly retrieves different data and passes them
 * into some the component.
 *
 */
const initRouter = (absPath, currentUrl, Store, isPreloaded, navHandler) => {
	router = createRouter({
		rootUrl: absPath,
		currentUrl,
		routes: createRoutes(Store, isPreloaded),
		navHandler,
		store: Store,
		generateUrlsOptions: {
			stringifyQueryParams: params => new URLSearchParams(params).toString(),
		},
	});

	return router;
};

const getRouter = () => router;

export {getRouter, initRouter};
