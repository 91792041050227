import {useEffect, Children, isValidElement, cloneElement} from 'react';
import PropTypes from 'prop-types';

const PlacesProvider = ({onMount, onUnmount, children, places}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const childrenWithProps = Children.map(children, child => {
		if (isValidElement(child)) {
			return cloneElement(child, {places});
		}
		return child;
	});

	return <>{childrenWithProps}</>;
};

PlacesProvider.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	children: PropTypes.node,
	places: PropTypes.array,
};

export default PlacesProvider;
