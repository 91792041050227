import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';

const mapStateToProps = () => {
	return {
		sections:
			Select.worldWater.statistics.getSectionsByType('singleHydrobasin'),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
