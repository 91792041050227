import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = state => {
	return {
		isOpen: Select.components.get(state, 'StatisticsPanel', 'isOpen'),
		activePlaceKeys: Select.places.getActiveKeys(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setOpen: value => {
			dispatch(Action.components.set('StatisticsPanel', 'isOpen', value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
