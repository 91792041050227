import PropTypes from 'prop-types';

export const MetadataTitle = ({title, period}) => {
	return (
		<>
			<h3 className="worldWater-MetadataInfoTitle">
				{title}
				<span className="worldWater-MetadataInfoTitle-period">({period})</span>
			</h3>
		</>
	);
};

MetadataTitle.propTypes = {
	period: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	title: PropTypes.string,
};

export default MetadataTitle;
