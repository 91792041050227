import classes_enumerations from '../../../data/classes_enumerations';

const getDataSum = (data, prop) => {
	let sum = 0;
	for (let i = 0; i < data.length; i++) {
		sum += data[i][prop];
	}
	return sum;
};

const getAverage = (data, prop) => {
	return getDataSum(data, prop) / data.length;
};

const getRelativeData = (data, valueProp, sortingProp) => {
	const avg = getAverage(data, valueProp);
	const finalData = [];
	for (let i = 0; i < data.length; i++) {
		const value = data[i]?.[valueProp];

		finalData.push({
			...data[i],
			y: value || value === 0 ? value - avg : null,
		});
	}

	return finalData?.length ? sortByDate(finalData, sortingProp) : finalData;
};

const sortByDate = (data, prop) =>
	data?.sort(function (a, b) {
		return new Date(a[prop]) - new Date(b[prop]);
	});

const getDownloadData = data => {
	return data.map(item => {
		return {date: item.x, value: item.y, unit: item.unit};
	});
};

const getPreparedData = (featureData, sources, valueType, isMultiselect) => {
	return sources.map(source => {
		const {attribute, charts} = source;
		const chartKey = charts?.[0]; //TODO for now one chart per attribute
		const data = featureData?.[attribute]?.charts?.[chartKey]?.data;

		if (data?.[0]?.id) {
			let finalChartData = [];
			let finalDownloadData = [];

			data.forEach(item => {
				let chartData;
				if (item.data && valueType === 'rel' && isMultiselect) {
					chartData = getRelativeData(item.data, 'y', 'x');
				}

				let downloadData = getDownloadData(
					chartData || sortByDate(item.data, 'x')
				);

				finalChartData.push({
					...item,
					data: chartData || sortByDate(item.data, 'x'),
				});
				finalDownloadData.push({
					...item,
					data: downloadData,
				});
			});
			return {
				chart: {
					attribute,
					data: finalChartData,
				},
				download: {
					attribute,
					data: finalDownloadData,
				},
			};
		} else {
			let finalData;
			if (data && valueType === 'rel' && isMultiselect) {
				finalData = getRelativeData(data, 'y', 'x');
			}
			let downloadData = getDownloadData(finalData || sortByDate(data, 'x'));
			return {
				chart: {data: finalData || sortByDate(data, 'x'), attribute},
				download: {data: downloadData},
			};
		}
	});
};

const getSwcPreparedData = (featureData, sources, valueType) => {
	const chart = [];
	const download = [];

	sources.map(source => {
		const {attribute, charts, label} = source;
		const chartKey = charts?.[0];
		const data = featureData?.[attribute]?.charts?.[chartKey]?.data;

		const finalChartData = [];
		const finalDownloadData = [];

		data.map(item => {
			if (classes_enumerations[attribute]?.[item.class]) {
				finalChartData.push({
					id: item.id,
					x: classes_enumerations[attribute]?.[item.class],
					y: item.value,
					label: classes_enumerations[attribute]?.[item.class],
					unit: valueType === 'rel' ? '[%]' : '[sqkm]',
				});
				finalDownloadData.push({
					change: classes_enumerations[attribute]?.[item.class],
					value: item.value,
					unit: valueType === 'rel' ? '[%]' : '[sqkm]',
				});
			}
		});

		const relativeChartData = finalChartData.map(item => {
			return {
				...item,
				y: parseFloat(
					((item.y / getDataSum(finalChartData, 'y')) * 100).toFixed(2)
				),
			};
		});

		const relativeDownloadData = finalDownloadData.map(item => {
			return {
				...item,
				value: parseFloat(
					((item.value / getDataSum(finalDownloadData, 'value')) * 100).toFixed(
						2
					)
				),
			};
		});

		chart.push({
			attribute,
			id: label,
			data: valueType === 'rel' ? relativeChartData : finalChartData,
		});
		download.push({
			attribute,
			type: label,
			data: valueType === 'rel' ? relativeDownloadData : finalDownloadData,
		});
	});
	return {chart: chart, download: download};
};

export default {
	getAverage,
	getRelativeData,
	sortByDate,
	getPreparedData,
	getSwcPreparedData,
};
