import PropTypes from 'prop-types';
import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../../../state/Select';
import chartDefs from './chartDefs';
import LineChart from '../../../../../../../common/LineChart';
import ScatterChart from '../../../../../../../common/ScatterChart';
import {waterLevelChartType} from '../../../../../../../../constants/app';

import presentation from './presentation';

const Cross = ({node, hovered}) => {
	const y = node.y;
	const x = node.x;
	const samePointAsHovered =
		node.data.pointId === hovered?.[0]?.point?.data?.pointId &&
		node.formattedX === hovered?.[0]?.point?.formattedX &&
		node.formattedY === hovered?.[0]?.point?.formattedY;
	const radius = (node.size / 2) * (samePointAsHovered ? 2 : 1);
	return (
		<path
			stroke={node.data.color || node.color}
			strokeWidth={samePointAsHovered ? 2 : 1}
			d={`M${x},${y - radius} L${x},${y + radius} M${x - radius},${y} L${
				x + radius
			},${y}`}
		/>
	);
};

Cross.propTypes = {
	node: PropTypes.object,
	hovered: PropTypes.array,
};

export const Node = ({point, hovered}) => {
	const samePointAsHovered = point.datum.pointId
		? point.datum.pointId === hovered?.[0]?.point?.data?.pointId
		: true &&
		  point.datum.xFormatted === hovered?.[0]?.point?.data?.xFormatted &&
		  point.datum.yFormatted === hovered?.[0]?.point?.data?.yFormatted;

	return (
		<circle
			r={samePointAsHovered ? '4' : '0'} // it should depend on points count in chart/density
			fill={`${samePointAsHovered ? '#FFFFFF' : point.color}`}
			stroke={point.color}
			strokeWidth="2"
			style={{pointerEvents: 'none'}}
		></circle>
	);
};

Node.propTypes = {
	point: PropTypes.object,
	hovered: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
	const activeChartComponent = Select.components.get(
		state,
		waterLevelChartType,
		'active'
	);

	return {
		ChartComponent:
			activeChartComponent === 'scatterChart' ? ScatterChart : LineChart,
		data: Select.worldWater.waterLevel.getDataForLineChart(state),
		metadata: chartDefs?.[activeChartComponent]?.metadata,
		overviewChartMetadata:
			chartDefs?.[activeChartComponent]?.overviewChartMetadata,
		chartComponentSettings:
			activeChartComponent === 'scatterChart'
				? {nodeComponent: <Cross hovered={ownProps.hovered} />}
				: {
						pointSymbol: <Node hovered={ownProps.hovered} />,
						enablePoints: true,
				  },
	};
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(
	presentation
);
