import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		isMinimised: Select.components.get(state, ownProps.sectionKey, 'minimised'),
		isAdvancedStatisticsOpen: Select.components.get(
			state,
			ownProps.sectionKey,
			'advancedOpen'
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMinimisedChange: minimised => {
			dispatch(
				Action.components.set(ownProps.sectionKey, 'minimised', minimised)
			);
		},
		onAdvancedStatisticsOpenChange: open => {
			dispatch(
				Action.components.set(ownProps.sectionKey, 'advancedOpen', open)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
