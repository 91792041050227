// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Icon, Button} from '@gisatcz/ptr-atoms';
import WaterFrequency from './metadata/WaterFrequency';
import MaximumExtent from './metadata/MaximumExtent';
import MinimumExtent from './metadata/MinimumExtent';
import WaterClassification from './metadata/WaterClassification';
import WaterSeasonality from './metadata/WaterSeasonality';
import WaterPresence from './metadata/WaterPresence';
import ChangeWaterFrequency from './metadata/ChangeWaterFrequency';
import ChangeMaximumExtent from './metadata/ChangeMaximumExtent';
import ChangeMinimumExtent from './metadata/ChangeMinimumExtent';
import ChangeWaterClassification from './metadata/ChangeWaterClassification';
import ChangeWaterPresence from './metadata/ChangeWaterPresence';

const LayerTemplateMetadataSection = ({layerTemplateMetadataComponentKey}) => {
	switch (layerTemplateMetadataComponentKey) {
		case 'waterFrequency':
			return <WaterFrequency />;
		case 'maximumExtent':
			return <MaximumExtent />;
		case 'minimumExtent':
			return <MinimumExtent />;
		case 'waterClassification':
			return <WaterClassification />;
		case 'waterSeasonality':
			return <WaterSeasonality />;
		case 'waterPresence':
			return <WaterPresence />;
		case 'changeWaterFrequency':
			return <ChangeWaterFrequency />;
		case 'changeMaximumExtent':
			return <ChangeMaximumExtent />;
		case 'changeMinimumExtent':
			return <ChangeMinimumExtent />;
		case 'changeWaterClassification':
			return <ChangeWaterClassification />;
		case 'changeWaterPresence':
			return <ChangeWaterPresence />;
		default:
			return null;
	}
};

LayerTemplateMetadataSection.propTypes = {
	layerTemplateMetadataComponentKey: PropTypes.string,
};

const MetadataInfoItem = ({
	wmsUrl,
	downloadTiffLink,
	downloadSldLink,
	layerTemplateMetadataComponentKey,
}) => {
	return (
		<div className="worldWater-MetadataInfo">
			<div className="worldWater-MetadataInfoItem">
				<div className="worldWater-MetadataInfoUrl">
					<div className="worldWater-MetadataInfoUrlItem">
						<Button
							className="worldWater-MetadataInfoUrlItem-button"
							onClick={() => window.open(downloadTiffLink)}
						>
							<Icon icon={'ri-download'} />
							Download data (TIFF)
						</Button>
					</div>
					<div className="worldWater-MetadataInfoUrlItem">
						<Button
							className="worldWater-MetadataInfoUrlItem-button"
							onClick={() => window.open(downloadSldLink)}
						>
							<Icon icon={'ri-download'} />
							Download style (SLD)
						</Button>
					</div>
					<div className="worldWater-MetadataInfoUrlItem">
						<Button
							className="worldWater-MetadataInfoUrlItem-button"
							onClick={() => navigator.clipboard.writeText(wmsUrl)}
						>
							<Icon icon={'ri-clipboard-copy'} />
							Copy WMS URL
						</Button>
						{/*<div className="worldWater-MetadataInfoUrlItem-wmsUrl">*/}
						{/*	<input*/}
						{/*		className="worldWater-MetadataInfoUrlItem-wmsUrlText"*/}
						{/*		type="text"*/}
						{/*		readOnly*/}
						{/*		value={wmsUrl}*/}
						{/*	/>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className="worldWater-Metadata-LayerTemplateItem">
					<LayerTemplateMetadataSection
						layerTemplateMetadataComponentKey={
							layerTemplateMetadataComponentKey
						}
					/>
				</div>
			</div>
		</div>
	);
};

MetadataInfoItem.propTypes = {
	wmsUrl: PropTypes.string,
	downloadTiffLink: PropTypes.string,
	downloadSldLink: PropTypes.string,
	layerTemplateMetadataComponentKey: PropTypes.string,
};

export default MetadataInfoItem;
