import config from '../../../../config';
import request from '../../../../utils/request';
import Select from '../../../Select';
import ActionTypes from '../../../../constants/ActionTypes';
import {statisticsHydrobasinSelectionKey} from '../../../../constants/app';

const actionAdd = (placeKey, basinKey, data) => {
	return {
		type: ActionTypes.WORLD_WATER.STATISTICS.BASINS.ADD,
		placeKey,
		basinKey,
		data,
	};
};

/**
 * Loads and add statistics for given place and basin
 * @param {string} placeKey
 * @param {string} basinKey
 * @returns
 */
const loadStatisticsForBasin = (placeKey, basinKey) => {
	return dispatch => {
		// TODO for now, request allways for data
		let url = `${config.statisticsDataRepository}/basins/${placeKey}/${basinKey}.json`;

		return request(url, 'GET', null, null).then(data => {
			if (data) {
				dispatch(actionAdd(placeKey, basinKey, data));
			} else {
				console.warn(`No data for place ${placeKey} and ${basinKey}!`);
			}
		});
	};
};

/**
 * If statistics are not loaded, then loads and add statistics for active place and basin key
 * @param {placeKey} placeKey
 * @param {string} basinKey
 * @returns
 */
function ensureBasinStatistics(placeKey, basinKey) {
	return (dispatch, getState) => {
		//check if statistics are loaded
		const basinStatistics =
			Select.worldWater.statistics.basin.getStatisticsForBasinByKeyAndPlaceKey(
				getState(),
				placeKey,
				basinKey
			);

		if (!basinStatistics) {
			// load statistics for basin
			dispatch(loadStatisticsForBasin(placeKey, basinKey));
		}
	};
}

const use = () => {
	return (dispatch, getState) => {
		const state = getState();
		const selectionsAsObject = Select.selections.getAllAsObject(state);
		const basinKeys =
			selectionsAsObject[statisticsHydrobasinSelectionKey]?.data
				?.featureKeysFilter?.keys;
		const places = Select.places
			.getActiveModels(state)
			?.map(place => place?.data?.nameInternal);
		if (basinKeys?.length && places?.length) {
			places.forEach(place => {
				basinKeys.forEach(basin => {
					dispatch(ensureBasinStatistics(place, basin));
				});
			});
		}
	};
};

export default {
	use,
};
