import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import React, {useRef, useState, useEffect} from 'react';
import './style.scss';

const useOnClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
};

const DownloadChart = ({chartRef, label, data}) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const menu = useRef();

	const handleClickOutside = () => {
		if (menuIsOpen) {
			setMenuIsOpen(false);
		}
	};

	useOnClickOutside(menu, handleClickOutside);

	const downloadChart = async () => {
		const element = chartRef.current;

		const canvas = await html2canvas(element, {
			height: element.querySelector('.ptr-TimeRangeSelector')
				? element.scrollHeight -
				  element.querySelector('.ptr-TimeRangeSelector').scrollHeight
				: null,
		});

		const data = canvas.toDataURL('image/jpg');
		const link = document.createElement('a');

		link.href = data;

		if (label) {
			if (typeof label === 'object') {
				link.download = label?.props?.children?.[0];
			} else {
				link.download = label;
			}
		} else {
			link.download = 'WorldWater chart';
		}

		link.click();
	};

	const downloadJSON = async () => {
		var dataLink =
			'data:text/json;charset=utf-8,' +
			encodeURIComponent(JSON.stringify(data));

		const link = document.createElement('a');
		link.href = dataLink;

		if (label) {
			if (typeof label === 'object') {
				link.download = label?.props?.children?.[0] + '.json';
			} else {
				link.download = label + '.json';
			}
		} else {
			link.download = 'WorldWater chart data.json';
		}

		link.click();
	};

	const downloadCSV = async () => {
		const headers = [];
		const csvValues = [];

		if (data && data?.length) {
			data.forEach(element => {
				let sortableData = [];
				for (var item in element) {
					sortableData.push([item, element[item]]);
				}

				sortableData.sort(a => {
					if (typeof a[1] === 'object' && a[1] !== null) {
						return 1;
					} else {
						return -1;
					}
				});

				let dataSorted = {};
				sortableData.forEach(function (item) {
					dataSorted[item[0]] = item[1];
				});

				let values = Object.values(dataSorted);
				let prevValues = [];

				if (values?.length) {
					const mapData = (x, val2) => {
						if (x?.length) {
							x.forEach((item, i) => {
								if (!item) {
									prevValues.push('null');
									headers.push(Object.keys(dataSorted)?.[i]);
								} else if (typeof item === 'string') {
									prevValues.push(item);
									headers.push(Object.keys(dataSorted)?.[i]);
								} else if (typeof item === 'object' && item?.length) {
									item.forEach(objValue => {
										const row = [];
										Object.values(objValue).forEach((value, index) => {
											if (value?.type !== React.Fragment) {
												if (!value) {
													row.push('null');
													headers.push(Object.keys(objValue)?.[index]);
												} else if (typeof value === 'object') {
													mapData(value, [...prevValues, ...row]);
												} else {
													row.push(value);
													headers.push(Object.keys(objValue)?.[index]);
												}
											}
											if (
												(typeof value !== 'object' ||
													value?.type === React.Fragment) &&
												Object.values(objValue).length - 1 === index
											) {
												if (val2) {
													csvValues.push([...val2, ...row]);
												} else if (prevValues) {
													csvValues.push([...prevValues, ...row]);
												} else {
													csvValues.push(row);
												}
											}
										});
									});
								} else if (typeof item === 'object') {
									const row = [];
									Object.values(item).forEach((value, index) => {
										if (value?.type !== React.Fragment) {
											if (!value) {
												row.push('null');
												headers.push(Object.keys(item)?.[index]);
											} else if (typeof value === 'object') {
												mapData(value, [...prevValues, ...row]);
											} else {
												row.push(value);
												headers.push(Object.keys(item)?.[index]);
											}
										}
										if (
											(typeof value !== 'object' ||
												value?.type === React.Fragment) &&
											Object.values(item).length - 1 === index
										) {
											if (val2) {
												csvValues.push([...val2, ...row]);
											} else if (prevValues) {
												csvValues.push([...prevValues, ...row]);
											} else {
												csvValues.push(row);
											}
										}
									});
								}
							});
						} else {
							return;
						}
					};

					mapData(values);
				}
			});

			//const csvHeaders = headers.slice(0, csvValues?.[0]?.length);
			const csvHeaders = headers.filter((value, index, array) => {
				return array.indexOf(value) === index;
			});

			const refinedData = [csvHeaders, ...csvValues];
			let csvContent = '';

			refinedData.forEach(row => {
				csvContent += row.join(',') + '\n';
			});

			const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8,'});

			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;

			if (label) {
				if (typeof label === 'object') {
					link.download = label?.props?.children?.[0] + '.csv';
				} else {
					link.download = label + '.csv';
				}
			} else {
				link.download = 'chart_data.csv';
			}

			link.click();
		}
	};

	return (
		<div
			className="worldWater-DownloadChart"
			data-html2canvas-ignore={true}
			ref={menu}
		>
			<IconTool
				icon="ri-download"
				onClick={() => setMenuIsOpen(!menuIsOpen)}
				small
				className={classNames('worldWater-DownloadChart-btn', {
					'is-active': menuIsOpen,
				})}
				tooltip={{
					text: 'Download chart',
					position: 'top',
					component: Tooltip,
				}}
				disabled={!data && !chartRef}
			/>
			<div
				className={classNames('worldWater-DownloadChart-menu', {
					'is-open': menuIsOpen,
				})}
			>
				<div className="worldWater-DownloadChart-menuConnect" />
				<div className="worldWater-DownloadChart-menuContent">
					{data ? (
						<>
							<span
								className="worldWater-DownloadChart-menuContent-item"
								onClick={downloadJSON}
							>
								Download chart data (JSON)
							</span>
							<span
								className="worldWater-DownloadChart-menuContent-item"
								onClick={downloadCSV}
							>
								Download chart data (CSV)
							</span>
						</>
					) : null}
					{chartRef ? (
						<span
							className="worldWater-DownloadChart-menuContent-item"
							onClick={downloadChart}
						>
							Download chart as image (PNG)
						</span>
					) : null}
				</div>
			</div>
		</div>
	);
};

DownloadChart.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	chartRef: PropTypes.object,
};

export default DownloadChart;
