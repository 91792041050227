import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const ChangeMinimumExtent = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Yearly changes of minimum surface water extent" />
		<MetadataItemInfoSectionParagraph text="The product shows gains (green) and losses (red) of the maximum water extent between two consecutive years, along with unchanged water and no water areas." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Yearly." />
		<MetadataItemInfoSectionLegendInfo
			items={[
				'0: Unchanged not water',
				'1: Gain minimum water',
				'2: Loss minimum water',
				'3: Unchanged minimum water',
				'255: No data',
			]}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/change_minimum_extent.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default ChangeMinimumExtent;
