import {useEffect} from 'react';
import PropTypes from 'prop-types';
import StatisticsBody from './StatisticsBody';
import StatisticsHeader from './StatisticsHeader';

import './style.scss';

const Statistics = ({activeViewKey, onMount, onUnmount}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	return activeViewKey ? (
		<div className="worldWater-Statistics">
			<StatisticsHeader />
			<StatisticsBody />
		</div>
	) : null;
};

Statistics.propTypes = {
	activeViewKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default Statistics;
