import PropTypes from 'prop-types';

import '../style.scss';

const TourStepsContainer = ({children}) => (
	<div className="worldWater-Tour-steps-container">{children}</div>
);

TourStepsContainer.propTypes = {
	children: PropTypes.node,
};

export default TourStepsContainer;
