import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';
import {
	waterExtentTimeline,
	waterLevelPanel,
} from '../../../../../../constants/app';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const mapSetKey = Select.maps.getActiveSetKey(state);
	const levelPanelOpen = Select.components.get(state, waterLevelPanel, 'open');
	const timelineOpen = Select.components.get(
		state,
		waterExtentTimeline,
		'open'
	);
	return {
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, mapSetKey),
		mapSetMapKeys: Select.maps.getMapSetMapKeys(state, mapSetKey),
		layersState: Select.maps.getLayersStateByMapKey(state, ownProps.mapKey),
		isWaterExtentView: Select.worldWater.waterExtent.isWaterExtentView(state),
		timelineOpen,
		chartOpen: levelPanelOpen,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		removeMap: mapKey => {
			dispatch(Action.maps.removeMap(mapKey));
		},
		removeAllLayers: mapKey => {
			dispatch(Action.maps.removeAllMapLayers(mapKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
