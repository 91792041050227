import PropTypes from 'prop-types';
import {Toggle} from '@gisatcz/ptr-atoms';
import LayerLabelTool from '../LayerLabelTool';
import './style.scss';

const LegendControl = ({options, onLegendVisibilityChange}) => {
	return (
		<LayerLabelTool
			title="Show legend"
			key="legend"
			onClick={() =>
				onLegendVisibilityChange('legendHidden', !options?.legendHidden)
			}
		>
			<Toggle notInteractive on={!options?.legendHidden} />
		</LayerLabelTool>
	);
};

LegendControl.propTypes = {
	layerKey: PropTypes.string,
	mapKey: PropTypes.string,
	onLegendVisibilityChange: PropTypes.func,
	options: PropTypes.object,
};

export default LegendControl;
