import {commonSelectors} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';

const getSubstate = state => state.worldWater.waterLevel.indicators;
const getActiveKey = commonSelectors.getActiveKey(getSubstate);
const getActive = commonSelectors.getActive(getSubstate);
const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);

const getKeys = createSelector([getAllAsObject], byKey => {
	if (byKey) {
		return Object.keys(byKey);
	} else {
		return null;
	}
});

export default {
	getActiveKey,
	getActive,
	getAll,
	getKeys,
};
