import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../../../state/Select';
import Action from '../../../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		activeOptionKey: Select.components.get(
			state,
			ownProps.chartKey,
			'selectedClass'
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(Action.components.set(ownProps.chartKey, 'selectedClass', '3'));
		},
		onActiveOptionChange: value => {
			dispatch(
				Action.components.set(ownProps.chartKey, 'selectedClass', value)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
