import PropTypes from 'prop-types';
import {isArray as _isArray} from 'lodash';
import {useEffect} from 'react';
import StatisticsSelect from '../StatisticsSelect';
import {hydrobasinsLevelFidColumnName} from '../../../../../constants/app';
import utils from '../../../../../utils';
import './style.scss';

const HydrobasinSelect = ({
	activeHydrobasinKeys,
	hydrobasins,
	disabled,
	onActiveHydrobasinChange,
	isMulti,
	onMount,
	onUnmount,
	selectionData,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const options = hydrobasins?.map(hydrobasin => {
		return {
			value: hydrobasin?.properties?.[hydrobasinsLevelFidColumnName],
			label: hydrobasin?.properties?.[hydrobasinsLevelFidColumnName],
			color: utils.getSelectedFeaturePrimaryColor(
				hydrobasin?.properties?.[hydrobasinsLevelFidColumnName],
				selectionData
			),
		};
	});

	const setActiveHydrobasinKeys = hydrobasins => {
		hydrobasins = _isArray(hydrobasins) ? hydrobasins : [hydrobasins];
		onActiveHydrobasinChange(hydrobasins.map(place => place.value));
	};

	const values =
		activeHydrobasinKeys?.length > 0 &&
		options?.filter(option => activeHydrobasinKeys.includes(option.value));

	return (
		<StatisticsSelect
			placeholder={isMulti ? 'Select hydrobasins...' : 'Select hydrobasin...'}
			className="worldWater-HydrobasinSelect"
			options={options}
			value={values}
			onChange={setActiveHydrobasinKeys}
			isDisabled={disabled}
			isSearchable
			isMulti={isMulti}
		/>
	);
};

HydrobasinSelect.propTypes = {
	activeHydrobasinKeys: PropTypes.array,
	hydrobasins: PropTypes.array,
	disabled: PropTypes.bool,
	onActiveHydrobasinChange: PropTypes.func,
	isMulti: PropTypes.bool,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	selectionData: PropTypes.array,
};

export default HydrobasinSelect;
