import {commonReducers} from '@gisatcz/ptr-state';
import ActionTypes from '../../../../constants/ActionTypes';

const INITIAL_STATE = {
	activeKey: 'waterLevel',
	disabledKeys: ['waterVolume'],
	byKey: {
		waterLevel: {
			key: 'waterLevel',
			data: {
				nameDisplay: 'Water level',
				nameInternal: 'Level',
			},
		},
		waterVolume: {
			key: 'waterVolume',
			data: {
				nameDisplay: 'Water volume',
				nameInternal: 'Volume',
			},
		},
		waterExtent: {
			key: 'waterExtent',
			data: {
				nameDisplay: 'Water extent',
				nameInternal: 'Extent',
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.WORLD_WATER.WATER_LEVEL.INDICATORS.SET_ACTIVE_KEY:
			return commonReducers.setActive(state, action);
		default:
			return state;
	}
};
