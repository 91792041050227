import PropTypes from 'prop-types';
import {colors} from '../colors';
import './style.scss';

const SurfaceWaterChangesLegend = ({data}) => {
	if (data) {
		return (
			<div className="worldWater-AdvancedStatistics-SurfaceWaterChangesLegend">
				{data.map((item, index) => (
					<div
						className="worldWater-AdvancedStatistics-SurfaceWaterChangesLegend-item"
						title={'Legend'}
						key={index}
					>
						<div
							className="worldWater-AdvancedStatistics-SurfaceWaterChangesLegend-icon"
							style={{background: colors[index]}}
						/>
						<div className="worldWater-AdvancedStatistics-SurfaceWaterChangesLegend-label">
							{item.label}
						</div>
					</div>
				))}
			</div>
		);
	} else {
		return null;
	}
};

SurfaceWaterChangesLegend.propTypes = {
	data: PropTypes.array,
};

export default SurfaceWaterChangesLegend;
