import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const featureProps = ownProps.vector?.[0]?.object?.properties;
	const featureKey = featureProps?.g;
	const name = featureProps?.c;

	return {
		showTooltip: !!Select.app.getConfiguration(
			state,
			`placeKeyByCountryFeatureKey.${featureKey}`
		),
		name,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
