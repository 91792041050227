import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {find as _find} from 'lodash';
import {PantherSelect} from '@gisatcz/ptr-atoms';
import {DeckGlMap} from '@gisatcz/ptr-maps';
import PlaceSelectMap from './PlaceSelectMap';
import {placeSelectMapKey} from '../../../../../../constants/app';
import PlaceSelectMapTooltip from './PlaceSelectMap/Tooltip';

import './style.scss';

const PlaceSelect = ({
	activePlaceKey,
	places,
	onMount,
	onUnmount,
	open,
	onSetOpen,
	closable,
	onSetClosable,
}) => {
	const activePlaceKeyRef = useRef();

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	useEffect(() => {
		if (!activePlaceKey) {
			onSetOpen(true);
			onSetClosable(false);
		} else {
			onSetClosable(true);
			onSetOpen(false, true);
		}
		activePlaceKeyRef.current = activePlaceKey;
	}, [activePlaceKey]);

	const renderCurrent = () => {
		if (activePlaceKey) {
			const activePlace = _find(places, place => place.key === activePlaceKey);
			return (
				<div
					className="worldWater-PlaceSelect-current-value"
					title={activePlaceKey}
				>
					<span>Area of interest</span>
					<div>{activePlace?.data?.nameDisplay}</div>
				</div>
			);
		} else {
			//no indicator
			return (
				<span className="worldWater-PlaceSelect-current-placeholder">
					<span>Area of interest</span>
					<div>Select in map...</div>
				</span>
			);
		}
	};

	const setPantherSelectOpen = (open, force) => {
		if (force || closable) {
			onSetOpen(open);
		}
	};

	return (
		<PantherSelect
			className="worldWater-PlaceSelect"
			open={open}
			onSelectClick={() => setPantherSelectOpen(!open)}
			currentClasses="worldWater-PlaceSelect-current"
			renderCurrent={renderCurrent}
			listClasses="worldWater-PlaceSelect-list"
		>
			<div className="worldWater-PlaceSelect-content">
				<div className="worldWater-PlaceMapContainer">
					<div className="worldWater-PlaceSelect-SimpleSelect"></div>
					<PlaceSelectMap
						onPlaceSelect={() => onSetOpen(false)}
						mapComponent={DeckGlMap}
						stateMapKey={placeSelectMapKey}
						Tooltip={PlaceSelectMapTooltip}
					/>
				</div>
			</div>
		</PantherSelect>
	);
};

PlaceSelect.propTypes = {
	activePlaceKey: PropTypes.string,
	places: PropTypes.array,
	open: PropTypes.bool,
	onSetOpen: PropTypes.func,
	closable: PropTypes.bool,
	onSetClosable: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default PlaceSelect;
