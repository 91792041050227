import {placeSelectMapKey} from '../../constants/app';
import placeSelectMapLayer from '../layers/placeSelectMapLayer';
import worldCountriesMapLayer from '../layers/worldCountriesMapLayer';

export default {
	key: placeSelectMapKey,
	data: {
		view: {
			boxRange: 15000000,
			center: {
				lat: 30,
				lon: 0,
			},
		},
		layers: [placeSelectMapLayer, worldCountriesMapLayer],
	},
};
