import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import {Icon} from '@gisatcz/ptr-atoms';

import '../../style.scss';

const AddMap = () => (
	<TourStepsContainer>
		<TourStepsTitle>Multiple maps</TourStepsTitle>
		<TourStepsSection>
			It is possible to open multiple map windows and add different products to
			each of them. The blue border color indicates the active map window.
		</TourStepsSection>
		<TourStepsSection>
			<Icon icon={'ri-add-map'} small /> <b>Add</b> - Opens a new map window.
		</TourStepsSection>
		<TourStepsSection>
			<Icon icon={'ri-compare'} small /> <b>Compare</b> - Creates a slider
			between the map windows that enables comparison of the maps.
		</TourStepsSection>
		<TourStepsSection>
			<Icon icon={'ri-close'} v /> <b>Close</b> - Removes the map window.
		</TourStepsSection>
	</TourStepsContainer>
);

export default AddMap;
