import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../state/Select';
import helpers from '../../../../../../state/worldWater/helpers';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const layer = Select.maps.getLayerStateByLayerKeyAndMapKey(
		state,
		ownProps.mapKey,
		ownProps.layer.layer.props.layerKey
	);
	const layerTemplate = Select.layerTemplates.getByKey(
		state,
		layer.layerTemplateKey
	);
	const period = Select.periods.getByKey(
		state,
		layer.metadataModifiers.periodKey
	);

	//get style by layerkey
	const styleKey = Select.worldWater.getStyleKeyByLayerTemplateKey(
		layer.layerTemplateKey
	);

	const style = Select.styles.getByKey(state, styleKey);

	//get value title by style
	const labelValuePair = helpers.getValueAndLabelByStyle(
		ownProps.response?.[0].value_list,
		style
	);

	return {
		color: ownProps.layer?.pixelColor?.toString(),
		period: period?.data?.period,
		layerTemplate: layerTemplate,
		value: labelValuePair?.value,
		label: labelValuePair?.label,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
