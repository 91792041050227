import PropTypes from 'prop-types';

const SectionInfoSegment = ({children}) => {
	return (
		<div className="worldWater-StatisticsSection-infoSegment">{children}</div>
	);
};

SectionInfoSegment.propTypes = {
	children: PropTypes.node,
};

export default SectionInfoSegment;
