import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';
import classNames from 'classnames';

import './style.scss';
import {useEffect} from 'react';

const StatisticsPanelControl = ({isOpen, setOpen, activePlaceKeys}) => {
	useEffect(() => {
		setOpen(true);
	}, [activePlaceKeys]);
	return (
		<div
			className={classNames('worldWater-StatisticsPanel-PanelControlBtn', {
				'is-open': isOpen,
			})}
			onClick={() => {
				setOpen(!isOpen);
			}}
		>
			<Icon icon="ri-chevron-right" />
		</div>
	);
};

StatisticsPanelControl.propTypes = {
	isOpen: PropTypes.bool,
	setOpen: PropTypes.func,
	activePlaceKeys: PropTypes.array,
};

export default StatisticsPanelControl;
