import PropTypes from 'prop-types';
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from 'react-compare-slider';
import classNames from 'classnames';
import {DeckGlMap, MapSet, PresentationMap} from '@gisatcz/ptr-maps';
import {connects} from '@gisatcz/ptr-state';
import MapWrapper from './MapWrapper';
import AddMapControl from './AddMapControl';
import MapComponentsGroup from '../../../../common/Maps/MapComponentsGroup';
import MapAttribution from '../../../../common/Maps/MapAttribution';
import Scale from '../../../../common/Maps/Scale';
import CompareMapsControl from './CompareMapsControl';
import BackgroundLayersControl from '../../../../common/Maps/BackgroundLayersControl';
import ZoomControls from '../../../../common/Maps/ZoomControls';
import MapTooltip from '../../../../common/Maps/MapTooltip';
import StyleBasedLegend from '../../../../common/Maps/MapLegends/StyleBasedLegend';
import TooltipContent from './TooltipContent';

import {MAX_MAPS_IN_MAP_SET} from '../../../../../constants/app';
import './style.scss';
import CoordinatesControl from '../../../../common/Maps/CoordinatesControl';
import MapContainer from './MapContainer';
import InfoBox from '../../../../common/Maps/Info';
import {useEffect, useState} from 'react';

const ConnectedMap = MapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const Map = MapContainer(PresentationMap);

const Tooltip = props => {
	return (
		<MapTooltip {...props}>
			<TooltipContent
				mapKey={props.mapKey}
				componentsByLayer={props.componentsByLayer}
			/>
		</MapTooltip>
	);
};

Tooltip.propTypes = {
	mapKey: PropTypes.string,
	componentsByLayer: PropTypes.array,
};

const Maps = ({
	maps,
	mapSetKey,
	mode,
	waterLevelPanelOpen,
	timelineOpen,
	layerTemplateKeysForLegend,
	activeView,
}) => {
	const [renderInfoBox, setRenderInfoBox] = useState(false);
	useEffect(() => {
		if (activeView?.data?.nameInternal === 'extent') {
			setRenderInfoBox(false);
		} else if (activeView?.data?.nameInternal === 'water-level') {
			setRenderInfoBox(true);
		}
	}, [activeView]);

	//TODO -> invent some selector?
	const componentsByLayer = Object.keys(layerTemplateKeysForLegend).map(
		ltKey => ({
			layerTemplateKey: ltKey,
			legend: {
				component: StyleBasedLegend,
				props: {
					noData: false,
					expandable: !!layerTemplateKeysForLegend[ltKey]?.expandable,
					...(layerTemplateKeysForLegend[ltKey]?.unit
						? {unit: layerTemplateKeysForLegend[ltKey]?.unit}
						: {}),
				},
			},
		})
	);

	const classes = classNames('worldWater-MapSetTools', {
		'has-chart-open': waterLevelPanelOpen,
		'has-timeline-open': timelineOpen,
	});

	return mode === 'compare' ? (
		<ReactCompareSlider
			onlyHandleDraggable
			handle={
				<ReactCompareSliderHandle
					buttonStyle={{position: 'absolute', top: 'calc(50% - 30px)'}}
				/>
			}
			className="worldWater-CompareSlider"
			itemOne={
				<Map
					wrapper={MapWrapper}
					wrapperProps={{noTools: true, componentsByLayer}}
					mapComponent={DeckGlMap}
					stateMapKey={maps?.[0]?.key}
					Tooltip={props => <Tooltip {...props} mapKey={maps[0].key} />}
					tooltipProps={{}}
				/>
			}
			itemTwo={
				<Map
					wrapper={MapWrapper}
					wrapperProps={{labelsRight: true, noTools: true, componentsByLayer}}
					mapComponent={DeckGlMap}
					stateMapKey={maps?.[1]?.key}
					Tooltip={props => <Tooltip {...props} mapKey={maps[1].key} />}
					tooltipProps={{}}
				>
					<MapComponentsGroup className={classes} key={'MapComponentsGroup_1'}>
						<MapComponentsGroup
							className="worldWater-MapInfoElements"
							key={'MapComponentsGroup_2'}
						>
							<MapComponentsGroup
								className="worldWater-AttributionScaleContainer"
								key={'MapComponentsGroup_3'}
							>
								<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
								<Scale key={'Scale'} />
							</MapComponentsGroup>
						</MapComponentsGroup>
						<MapComponentsGroup
							className="worldWater-MapSetControls"
							key={'MapComponentsGroup_4'}
						>
							<CoordinatesControl
								mapSetKey={mapSetKey}
								key={'CoordinatesControl'}
							/>
							<AddMapControl
								mapSetKey={mapSetKey}
								maxMapsCount={MAX_MAPS_IN_MAP_SET}
								key={'AddMapControl'}
							/>
							<CompareMapsControl
								mapSetKey={mapSetKey}
								key={'CompareMapsControl'}
							/>
							<BackgroundLayersControl
								mapSetKey={mapSetKey}
								key={'BackgroundLayersControl'}
							/>
							<ZoomControls key={'ZoomControls'} />
						</MapComponentsGroup>
					</MapComponentsGroup>
				</Map>
			}
		/>
	) : (
		<>
			<ConnectedMapSet
				stateMapSetKey={mapSetKey}
				mapComponent={DeckGlMap}
				connectedMapComponent={ConnectedMap}
				wrapper={MapWrapper}
				wrapperProps={{
					componentsByLayer,
				}}
				Tooltip={props => <Tooltip {...props} />}
				tooltipProps={{
					componentsByLayer,
				}}
			>
				<MapComponentsGroup className={classes} key={'MapComponentsGroup_1'}>
					<MapComponentsGroup
						className="worldWater-MapSetControls"
						key={'MapComponentsGroup_2'}
					>
						<CoordinatesControl
							mapSetKey={mapSetKey}
							key={'CoordinatesControl'}
						/>
						<AddMapControl
							mapSetKey={mapSetKey}
							maxMapsCount={MAX_MAPS_IN_MAP_SET}
							key={'AddMapControl'}
						/>
						<CompareMapsControl
							mapSetKey={mapSetKey}
							key={'CompareMapsControl'}
						/>
						<BackgroundLayersControl
							mapSetKey={mapSetKey}
							key={'BackgroundLayersControl'}
						/>
						<ZoomControls key={'ZoomControls'} />
					</MapComponentsGroup>
					<MapComponentsGroup
						className="worldWater-MapInfoElements"
						key={'MapComponentsGroup_3'}
					>
						<MapComponentsGroup
							className="worldWater-AttributionScaleContainer"
							key={'MapComponentsGroup_4'}
						>
							<MapAttribution mapSetKey={mapSetKey} key={'MapAttribution'} />
							<Scale key={'Scale'} />
						</MapComponentsGroup>
					</MapComponentsGroup>
					<MapComponentsGroup
						className="worldWater-InfoBox"
						key={'MapComponentsGroup_5'}
					>
						{renderInfoBox ? (
							<InfoBox hideAfterSeconds={3}>
								Tip: Use CTRL / ⌘ + click to select multiple features.
							</InfoBox>
						) : null}
					</MapComponentsGroup>
				</MapComponentsGroup>
			</ConnectedMapSet>
		</>
	);
};

Maps.propTypes = {
	waterLevelPanelOpen: PropTypes.any,
	timelineOpen: PropTypes.bool,
	mode: PropTypes.any,
	mapSetKey: PropTypes.string,
	maps: PropTypes.any,
	layerTemplateKeysForLegend: PropTypes.object,
	activeView: PropTypes.object,
};

export default Maps;
