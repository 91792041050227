import PropTypes from 'prop-types';
import './style.scss';
import {Icon, IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import {cloneElement, useState} from 'react';
import classNames from 'classnames';
import SectionInfo from './SectionInfo';

const StatisticsPanelSection = ({
	children,
	sectionKey,
	heading,
	AdvancedStatistics,
	isAdvancedStatisticsOpen,
	isMinimised,
	onAdvancedStatisticsOpenChange,
	onMinimisedChange,
}) => {
	const [sectionInfoIsOpen, setSectionInfoIsOpen] = useState(false);

	return (
		<div className="worldWater-StatisticsSection">
			<div className="worldWater-StatisticsSection-header">
				{heading ? (
					<h3 className="worldWater-StatisticsSection-headerText">{heading}</h3>
				) : (
					<div />
				)}
				<div className="worldWater-StatisticsSection-headerTools">
					<IconTool
						icon="ri-info"
						className="worldWater-StatisticsSection-headerToggle"
						medium
						onClick={() => {
							setSectionInfoIsOpen(true);
						}}
						tooltip={{
							text: 'Show description',
							position: 'top',
							component: Tooltip,
						}}
					/>
					<IconTool
						icon={isMinimised ? 'ri-plus' : 'ri-minimise'}
						medium
						onClick={() => {
							onMinimisedChange(!isMinimised);
						}}
						className="worldWater-StatisticsSection-headerToggle"
						tooltip={{
							text: isMinimised ? 'Expand section' : 'Minimize section',
							position: 'top',
							component: Tooltip,
						}}
					/>
				</div>
			</div>
			<div
				className={classNames('worldWater-StatisticsSection-content', {
					'is-minimised': isMinimised,
				})}
			>
				<div className="worldWater-StatisticsSection-contentWrapper">
					{children}
					{AdvancedStatistics ? (
						<div
							className={classNames(
								'worldWater-StatisticsSection-contentToggle',
								{
									'is-open': isAdvancedStatisticsOpen,
								}
							)}
						>
							<span
								className="worldWater-StatisticsSection-contentToggleBtn"
								onClick={() => {
									onAdvancedStatisticsOpenChange(!isAdvancedStatisticsOpen);
								}}
							>
								{isAdvancedStatisticsOpen ? 'Hide' : 'Show'} charts
								<Icon
									icon={'ri-chevron-down'}
									className={classNames(
										'worldWater-StatisticsSection-contentToggleIcon',
										{
											'is-open': isAdvancedStatisticsOpen,
										}
									)}
								/>
							</span>
						</div>
					) : null}
				</div>
				{AdvancedStatistics
					? cloneElement(AdvancedStatistics, {
							isOpen: isAdvancedStatisticsOpen,
					  })
					: null}
			</div>
			<SectionInfo
				sectionKey={sectionKey}
				sectionInfoIsOpen={sectionInfoIsOpen}
				setSectionInfoIsOpen={setSectionInfoIsOpen}
				title={heading}
			/>
		</div>
	);
};

StatisticsPanelSection.propTypes = {
	children: PropTypes.node,
	sectionKey: PropTypes.string,
	heading: PropTypes.string,
	AdvancedStatistics: PropTypes.node,
	isMinimised: PropTypes.bool,
	onMinimisedChange: PropTypes.func,
	isAdvancedStatisticsOpen: PropTypes.bool,
	onAdvancedStatisticsOpenChange: PropTypes.func,
};

export default StatisticsPanelSection;
