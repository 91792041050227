export default {
	multiselectEnabled: true,
	availableCountries: [
		'5d82c522-bb21-4fd3-9a22-68cc70eeeadb',
		'3820d115-3d35-4a41-9946-c0796f1f21e4',
		'62a36ed0-8dbe-48c2-bbcd-d1b90ec66be5',
		'c657f887-a734-4ffe-b904-725632193318',
		'a27ad3ab-1646-4f9f-b43f-75bfe341c4ba',
		'c8100e7f-bc98-4b63-a0ee-bf3059e259c0',
		'46d62296-0030-40cd-ba43-b9b1ce2705b0',
		'946b4590-4b4b-4f3a-82f1-3c93725fd839',
		'1c70b3f0-5cea-4699-9a44-30d751ac8c4f',
		'424d4ee1-a87e-4c63-98ec-50df5c2cc06f',
		'623d008a-5627-4fef-a14d-232223fb03ae',
		'd5080821-b65e-4e71-8a40-7d2c3a479999',
	],
	getSectionsByType: {
		singleCountry: [
			{
				key: 'water-presence',
				title: 'Water presence',
				attributes: ['wp'],
				charts: [
					{
						componentKey: 'WaterPresenceCountry',
						sources: [
							{
								attribute: 'swa',
								charts: ['swa'],
							},
							{
								attribute: 'wp',
								charts: ['wp'],
							},
						],
					},
				],
				// layers: [
				// 	{
				// 		key: 'water-presence',
				// 		name: 'Water presence',
				// 	},
				// 	{
				// 		key: 'change-water-presence',
				// 		name: 'Change water presence',
				// 	},
				// ],
			},
			{
				key: 'permanent-seasonal-water-extent',
				title: 'Permanent and seasonal water extent',
				attributes: ['pswe', 'sswe', 'swe'],
				charts: [
					{
						componentKey: 'SeasonalPermanentWaterExtentCountry',
						sources: [
							{
								attribute: 'pswe',
								charts: ['pswe'],
							},
							{
								attribute: 'sswe',
								charts: ['sswe'],
							},
						],
					},
					{
						componentKey: 'SeasonalWaterExtentCountry',
						sources: [
							{
								attribute: 'swe',
								charts: ['swe'],
							},
						],
					},
				],
				layers: [],
			},
			{
				key: 'surface-water-changes',
				title: 'Surface water changes',
				attributes: ['swcminA', 'swcmaxA', 'swcminB', 'swcmaxB'],
				charts: [
					{
						componentKey: 'SurfaceWaterChangesCountry',
						sources: [
							{
								attribute: 'swcminA',
								label: 'Min 2017-2021',
								charts: ['swcminA'],
								units: 'sqkm',
							},
							{
								attribute: 'swcmaxA',
								label: 'Max 2017-2021',
								charts: ['swcmaxA'],
								units: 'sqkm',
							},
							{
								attribute: 'swcminB',
								label: 'Min 2020-2021',
								charts: ['swcminB'],
								units: 'sqkm',
							},
							{
								attribute: 'swcmaxB',
								label: 'Max 2020-2021',
								charts: ['swcmaxB'],
								units: 'sqkm',
							},
						],
					},
				],
				layers: [],
			},
		],
		singleHydrobasin: [
			{
				key: 'water-presence',
				title: 'Water presence',
				attributes: ['wp'],
				charts: [
					{
						componentKey: 'WaterPresence',
						sources: [
							{
								attribute: 'swa',
								charts: ['swa'],
							},
							{
								attribute: 'wp',
								charts: ['wp'],
							},
						],
					},
				],
				layers: [],
			},
			{
				key: 'permanent-seasonal-water-extent',
				title: 'Permanent and seasonal water extent',
				attributes: ['pswe', 'sswe', 'swe'],
				charts: [
					{
						componentKey: 'SeasonalPermanentWaterExtent',
						sources: [
							{
								attribute: 'pswe',
								charts: ['pswe'],
							},
							{
								attribute: 'sswe',
								charts: ['sswe'],
							},
						],
					},
					{
						componentKey: 'SeasonalWaterExtent',
						sources: [
							{
								attribute: 'swe',
								charts: ['swe'],
							},
						],
					},
				],
				layers: [],
			},
			{
				key: 'surface-water-changes',
				title: 'Surface water changes',
				attributes: ['swcminA', 'swcmaxA', 'swcminB', 'swcmaxB'],
				charts: [
					{
						componentKey: 'SurfaceWaterChanges',
						sources: [
							{
								attribute: 'swcminA',
								label: 'Min 2017-2021',
								units: 'sqkm',
								charts: ['swcminA'],
							},
							{
								attribute: 'swcmaxA',
								label: 'Max 2017-2021',
								units: 'sqkm',
								charts: ['swcmaxA'],
							},
							{
								attribute: 'swcminB',
								label: 'Min 2020-2021',
								units: 'sqkm',
								charts: ['swcminB'],
							},
							{
								attribute: 'swcmaxB',
								label: 'Max 2020-2021',
								units: 'sqkm',
								charts: ['swcmaxB'],
							},
						],
					},
				],
				layers: [],
			},
		],
		multipleHydrobasins: [
			{
				key: 'water-presence',
				title: 'Water presence',
				attributes: ['wp'],
				charts: [
					{
						componentKey: 'WaterPresence',
						sources: [
							{
								attribute: 'swa',
								charts: ['swa'],
							},
							{
								attribute: 'wp',
								charts: ['wp'],
							},
						],
					},
				],
			},
			{
				key: 'permanent-seasonal-water-extent',
				title: 'Permanent and seasonal water extent',
				attributes: ['pswe', 'sswe', 'swe'],
				charts: [
					{
						componentKey: 'SeasonalPermanentWaterExtent',
						sources: [
							{
								attribute: 'pswe',
								charts: ['pswe'],
							},
							{
								attribute: 'sswe',
								charts: ['sswe'],
							},
						],
					},
					{
						componentKey: 'SeasonalWaterExtent',
						sources: [
							{
								attribute: 'swe',
								charts: ['swe'],
							},
						],
					},
				],
			},
			{
				key: 'surface-water-changes',
				title: 'Surface water changes',
				attributes: ['swcminA', 'swcmaxA', 'swcminB', 'swcmaxB'],
				charts: [
					{
						componentKey: 'SurfaceWaterChanges',
						sources: [
							{
								attribute: 'swcminA',
								label: 'Min 2017-2021',
								units: 'sqkm',
								charts: ['swcminA'],
							},
							{
								attribute: 'swcmaxA',
								label: 'Max 2017-2021',
								units: 'sqkm',
								charts: ['swcmaxA'],
							},
							{
								attribute: 'swcminB',
								label: 'Min 2020-2021',
								units: 'sqkm',
								charts: ['swcminB'],
							},
							{
								attribute: 'swcmaxB',
								label: 'Max 2020-2021',
								units: 'sqkm',
								charts: ['swcmaxB'],
							},
						],
					},
				],
			},
		],
		multipleCountries: [
			{
				key: 'water-presence',
				title: 'Water presence',
				attributes: ['wp'],
				charts: [
					{
						componentKey: 'WaterPresenceCountry',
						sources: [
							{
								attribute: 'swa',
								charts: ['swa'],
							},
							{
								attribute: 'wp',
								charts: ['wp'],
							},
						],
					},
				],
			},
			{
				key: 'permanent-seasonal-water-extent',
				title: 'Permanent and seasonal water extent',
				attributes: ['pswe', 'sswe', 'swe'],
				charts: [
					{
						componentKey: 'SeasonalPermanentWaterExtentCountry',
						sources: [
							{
								attribute: 'pswe',
								charts: ['pswe'],
							},
							{
								attribute: 'sswe',
								charts: ['sswe'],
							},
						],
					},
					{
						componentKey: 'SeasonalWaterExtentCountry',
						sources: [
							{
								attribute: 'swe',
								charts: ['swe'],
							},
						],
					},
				],
			},
			{
				key: 'surface-water-changes',
				title: 'Surface water changes',
				attributes: ['swcminA', 'swcmaxA', 'swcminB', 'swcmaxB'],
				charts: [
					{
						componentKey: 'SurfaceWaterChangesCountry',
						sources: [
							{
								attribute: 'swcminA',
								label: 'Min 2017-2021',
								charts: ['swcminA'],
								units: 'sqkm',
							},
							{
								attribute: 'swcmaxA',
								label: 'Max 2017-2021',
								charts: ['swcmaxA'],
								units: 'sqkm',
							},
							{
								attribute: 'swcminB',
								label: 'Min 2020-2021',
								charts: ['swcminB'],
								units: 'sqkm',
							},
							{
								attribute: 'swcmaxB',
								label: 'Max 2020-2021',
								charts: ['swcmaxB'],
								units: 'sqkm',
							},
						],
					},
				],
			},
		],
	},
};
