import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		options: Select.maps.getMapLayerStateByMapKeyAndLayerKey(
			state,
			ownProps.mapKey,
			ownProps.layerKey
		)?.options,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onLegendVisibilityChange: (option, value) => {
			dispatch(
				Action.maps.setMapLayerOption(
					ownProps.mapKey,
					ownProps.layerKey,
					option,
					value
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
