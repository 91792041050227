// import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import './style.scss';

const LevelSwitch = ({
	levels,
	activeLevelKey,
	activePlaceKeys,
	setActiveLevel,
}) => {
	return (
		<ButtonSwitch
			ghost
			className="worldWater-LevelSwitch worldWater-ButtonSwitch"
			onClick={setActiveLevel}
		>
			{levels?.map(level => (
				<ButtonSwitchOption
					key={level.key}
					value={level.key}
					active={level.key === activeLevelKey}
					disabled={
						activePlaceKeys?.length !== 1 && level.key !== activeLevelKey
					}
				>
					{level.data.nameDisplay}
					<div className="ptr-button-caption alternative">
						{level.data.nameInternal}
					</div>
				</ButtonSwitchOption>
			))}
		</ButtonSwitch>
	);
};

LevelSwitch.propTypes = {
	levels: PropTypes.array,
	activeLevelKey: PropTypes.string,
	activePlaceKeys: PropTypes.array,
	setActiveLevel: PropTypes.func,
};

export default LevelSwitch;
