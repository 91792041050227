import PropTypes from 'prop-types';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import ComponentRenderer from '../../../common/ComponentRenderer';
import {useEffect} from 'react';
import './style.scss';

const CoordinatesControl = ({
	active,
	setActive,
	coordinates,
	onMount,
	onUnmount,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	return (
		<ComponentRenderer
			component={'coordinates'}
			configurationGroupKey={'mapSetTools'}
		>
			{active ? (
				<div className="ptr-CoordinatesControl-box">
					{coordinates ? (
						`[${coordinates[1].toFixed(7)},${coordinates[0].toFixed(7)}]`
					) : (
						<span>Click on map to get coordinates...</span>
					)}
				</div>
			) : null}
			<IconTool
				tooltip={{
					text: 'Show coordinates on click',
					position: 'left',
					component: Tooltip,
				}}
				onClick={() => setActive(!active)}
				active={active}
				floating
				medium
				icon="ri-location-search"
			/>
		</ComponentRenderer>
	);
};

CoordinatesControl.propTypes = {
	active: PropTypes.bool,
	coordinates: PropTypes.array,
	setActive: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default CoordinatesControl;
