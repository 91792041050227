import {commonReducers} from '@gisatcz/ptr-state';
import ActionTypes from '../../../../constants/ActionTypes';

const INITIAL_STATE = {
	byKey: {},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.WORLD_WATER.WATER_LEVEL.POINTS.ADD:
			return commonReducers.add(state, action);
		default:
			return state;
	}
};
