import {connect} from '@gisatcz/ptr-state';

import Action from '../../../../../../../../state/Action';
import Select from '../../../../../../../../state/Select';

import Switcher from '../../../../../../../common/Switcher';

const mapStateToProps = state => {
	return {
		activeOptionKey:
			Select.worldWater.waterLevel.indicators.getActiveKey(state),
		disabledKeys: Select.worldWater.waterLevel.getDisabledIndicatorKeys(state),
		options: Select.worldWater.waterLevel.indicators.getAll(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onActiveOptionChange: key => {
			dispatch(Action.worldWater.waterLevel.indicators.setActiveKey(key));
		},
		onDisabledKeysChange: keys => {
			dispatch(
				Action.worldWater.waterLevel.indicators.handleDisabledKeys(keys)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Switcher);
