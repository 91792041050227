import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const MaximumExtent = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Yearly maps of maximum water extent" />
		<MetadataItemInfoSectionParagraph text="The maximum water extent layer includes pixels that have at least once been classified as water-covered / inundated in all available images within one year." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Yearly." />
		<MetadataItemInfoSectionLegendInfo
			items={['0: Not water', '1: Water', '255: No data']}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/maximum_extent.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default MaximumExtent;
