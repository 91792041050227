import {
	placeSelectMapSelectionKey,
	placeSelectMapLayerKey,
} from '../../constants/app';
import availableCountries from '../geometries/availableCountries_productViewer.json';
import placeSelectMapLayerStyle from '../mapStyles/placeSelectMapLayerStyle';

export default {
	key: {placeSelectMapLayerKey},
	type: 'vector',
	options: {
		features: availableCountries?.features,
		fidColumnName: 'g',
		style: placeSelectMapLayerStyle?.data?.definition,
		selectable: true,
		selected: {
			[placeSelectMapSelectionKey]: {},
		},
	},
};
