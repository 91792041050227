import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import {statisticsMapSetKey} from '../../../../../constants/app';

const mapStateToProps = () => {
	const mapSetKey = statisticsMapSetKey;

	return {
		mapSetKey,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
