import PropTypes from 'prop-types';
import {ResponsiveScatterPlot} from '@nivo/scatterplot';
import {cloneElement} from 'react';

/**
 * @param data {Array} following nivo scatter chart input data format (https://nivo.rocks/scatterplot/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo scatter chart props (https://nivo.rocks/scatterplot/)
 * @param customSettings {Object} settings customization, following nivo scatter chart props (https://nivo.rocks/scatterplot/)
 * @param Tooltip {React.FunctionComponent}
 * @returns {JSX.Element|null}
 */
const ScatterChart = ({data, metadata, customSettings, Tooltip, onHover}) => {
	const {settings} = metadata; // TODO if no settings, then basic settings?

	return data ? (
		<ResponsiveScatterPlot
			onMouseMove={onHover}
			onMouseLeave={() => {
				onHover();
			}}
			tooltip={Tooltip || (() => {})}
			key={data?.length} // TODO hot fix for multiple points selection
			data={data}
			{...settings}
			{...customSettings}
			{...(customSettings.nodeComponent
				? {
						nodeComponent: ({node}) => {
							return cloneElement(customSettings.nodeComponent, {
								node,
								hovered: customSettings.nodeComponent.props.hovered,
							});
						},
				  }
				: {})}
		/>
	) : null;
};

ScatterChart.propTypes = {
	data: PropTypes.array,
	metadata: PropTypes.object,
	customSettings: PropTypes.object,
	Tooltip: PropTypes.func,
	onHover: PropTypes.func,
};

export default ScatterChart;
