import {Action as CommonAction} from '@gisatcz/ptr-state';
import {map as mapUtils} from '@gisatcz/ptr-utils';
import {forIn as _forIn} from 'lodash';
import Select from '../Select';

import configuration from './configuration/actions';
import statistics from './statistics/actions';
import waterExtent from './waterExtent/actions';
import waterLevel from './waterLevel/actions';
import {getRouter} from '../../router';
import {
	mapCoordinatesControl,
	placeSelectMapKey,
	placeSelectMapSelectionKey,
} from '../../constants/app';

const setActiveView = (viewKey, firstRender, placeKey, Action) => {
	return (dispatch, getState) => {
		const state = getState();
		//save current map view and apply it afterview apply
		const activeSetKey = Select.maps.getActiveSetKey(state);
		const mapSet = Select.maps.getMapSetByKey(state, activeSetKey);
		const placeSelectionMap = Select.maps.getMapByKey(state, placeSelectMapKey);
		const setView = mapSet?.data?.view;
		dispatch(CommonAction.views.applyAndSetActive(viewKey, Action)).then(() => {
			const view = Select.views.getByKey(getState(), viewKey);
			if (view) {
				dispatch(
					configuration.updateStateFromView(
						view.data.state.worldWater.configuration
					)
				);
			}

			if (firstRender) {
				dispatch(setMapViewFromPlace(placeKey));
			}
			if (setView && !firstRender) {
				dispatch(CommonAction.maps.updateSetView(activeSetKey, setView));
			}

			if (placeSelectionMap) {
				dispatch(CommonAction.maps.addMap(placeSelectionMap));
			}
		});
	};
};

const setActiveViewForStatistics = (viewKey, Action) => {
	return (dispatch, getState) => {
		dispatch(CommonAction.views.applyAndSetActive(viewKey, Action)).then(() => {
			const view = Select.views.getByKey(getState(), viewKey);
			if (view) {
				dispatch(
					configuration.updateStateFromView(
						view.data.state.worldWater.configuration
					)
				);
			}
		});
	};
};

function setMapViewFromPlace(placeKey) {
	return (dispatch, getState) => {
		let place = null;
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);

		if (placeKey) {
			place = Select.places.getByKey(state, placeKey);
		} else {
			place = Select.places.getActive(state);
		}

		if (place?.data?.geometry) {
			let view = mapUtils.view.getViewFromGeometry(place.data.geometry, true);

			// TODO fix to zoom area properly
			view.boxRange /= 1.5;

			dispatch(CommonAction.maps.updateSetView(mapSetKey, view));
		}
	};
}

function setActivePlaceByFeatureKey(featureKey, callback) {
	return (dispatch, getState) => {
		if (featureKey) {
			const placeKeyByFeatureKey = Select.app.getConfiguration(
				getState(),
				'placeKeyByCountryFeatureKey'
			);
			const placeKey = placeKeyByFeatureKey?.[featureKey];
			const activeViewKey = Select.views.getActiveKey(getState());

			if (placeKey) {
				dispatch(
					CommonAction.selections.setFeatureKeysFilterKeys(
						placeSelectMapSelectionKey,
						[featureKey]
					)
				);

				setTimeout(() => {
					dispatch(CommonAction.places.setActiveKey(placeKey));

					const router = getRouter();
					const homeUrl = router.pathFor('/product-explorer/place', {
						urlPlaceKey: placeKey,
						...(activeViewKey ? {view: activeViewKey} : {}),
					});
					router.nav(homeUrl);

					if (callback) {
						callback();
					}
				}, 500);
			}
		}
	};
}

function setPlaceSelectSelectionSelectedFeatureKeysByActivePlaceKey(
	activePlaceKey
) {
	return (dispatch, getState) => {
		if (activePlaceKey) {
			const placeKeyByFeatureKey = Select.app.getConfiguration(
				getState(),
				'placeKeyByCountryFeatureKey'
			);

			if (placeKeyByFeatureKey) {
				let featureKey = null;
				_forIn(placeKeyByFeatureKey, (placeKey, key) => {
					if (activePlaceKey === placeKey) {
						featureKey = key;
					}
				});

				if (featureKey) {
					dispatch(
						CommonAction.selections.setFeatureKeysFilterKeys(
							placeSelectMapSelectionKey,
							[featureKey]
						)
					);
				}
			}
		}
	};
}

function handleOnClickCoordinates(coordinates) {
	return dispatch => {
		dispatch(
			CommonAction.components.set(
				mapCoordinatesControl,
				'coordinates',
				coordinates
			)
		);
	};
}

export default {
	setMapViewFromPlace,
	setActiveView,
	setActiveViewForStatistics,
	setActivePlaceByFeatureKey,
	setPlaceSelectSelectionSelectedFeatureKeysByActivePlaceKey,
	handleOnClickCoordinates,

	configuration,
	statistics,
	waterExtent,
	waterLevel,
};
