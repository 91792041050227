import {useState} from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';
import Modal from 'react-modal';
import ExpandableLabel, {
	ExpandableLabelBody,
	ExpandableLabelHeader,
} from '../../../../../common/ExpandableLabel';
import ModalWindow from '../../../../../common/ModalWindow';
import TooltipControl from '../../../../../common/ExpandableLabel/TooltipControl';
import LegendControl from '../../../../../common/ExpandableLabel/LegendControl';
import OpacitySlider from '../../../../../common/OpacitySlider';

import LayerLabelBodyItem from '../../../../../common/ExpandableLabel/LayerLabelTool';
import MetadataTitle from './Metadata/Title';
import MetadataInfo from './Metadata/Item/presentation';

import './style.scss';

Modal.setAppElement('#root');

const WaterExtentMapLabel = ({
	layerState,
	layerTemplate,
	period,
	zIndex,
	onOpacityChange,
	onRemove,
	downloadTiffLink,
	downloadSldLink,
	layerTooltipActive,
	onLayerTooltipActiveChange,
	mapKey,
	wmsUrl,
	layerTemplateMetadataComponentKey,
	color,
}) => {
	const [modalIsOpen, setModalOpen] = useState(false);

	const {opacity} = layerState;
	return (
		<>
			<ExpandableLabel
				floating
				className="worldWater-LayerLabel"
				zIndex={zIndex}
			>
				<ExpandableLabelHeader>
					<LayerLabelHeader
						title={layerTemplate?.data?.nameDisplay}
						period={period?.data?.nameDisplay}
						color={color}
					/>
				</ExpandableLabelHeader>
				<ExpandableLabelBody height={10}>
					<div className="worldWater-LayerLabelBody">
						<div>
							<LayerLabelBodyItem title="Set opacity">
								<OpacitySlider
									value={opacity >= 0 ? opacity * 100 : 100}
									onChange={onOpacityChange}
								/>
							</LayerLabelBodyItem>
							<TooltipControl
								active={layerTooltipActive}
								onChange={onLayerTooltipActiveChange}
							/>
							<LegendControl
								mapKey={mapKey}
								layerKey={layerState.key}
								key="legend"
							/>
							<LayerLabelBodyItem
								onClick={() => setModalOpen(true)}
								title="Metadata & downloads"
							>
								<Icon icon="info" />
							</LayerLabelBodyItem>
							<LayerLabelBodyItem onClick={onRemove} title="Remove layer">
								<Icon icon="close" />
							</LayerLabelBodyItem>
						</div>
					</div>
				</ExpandableLabelBody>
			</ExpandableLabel>
			<ModalWindow
				title={
					<MetadataTitle
						title={layerTemplate?.data?.nameDisplay}
						period={period?.data?.nameDisplay}
					/>
				}
				isOpen={modalIsOpen}
				onClose={() => setModalOpen(false)}
				className="worldWater-Modal"
			>
				<MetadataInfo
					wmsUrl={wmsUrl}
					downloadTiffLink={downloadTiffLink}
					downloadSldLink={downloadSldLink}
					layerTemplateMetadataComponentKey={layerTemplateMetadataComponentKey}
				/>
			</ModalWindow>
		</>
	);
};

WaterExtentMapLabel.propTypes = {
	layerTemplate: PropTypes.object,
	layerState: PropTypes.object,
	period: PropTypes.object,
	zIndex: PropTypes.number,
	onOpacityChange: PropTypes.func,
	onRemove: PropTypes.func,
	downloadTiffLink: PropTypes.string,
	downloadSldLink: PropTypes.string,
	layerTooltipActive: PropTypes.bool,
	onLayerTooltipActiveChange: PropTypes.func,
	mapKey: PropTypes.string,
	wmsUrl: PropTypes.string,
	layerTemplateMetadataComponentKey: PropTypes.string,
	color: PropTypes.string,
};

const LayerLabelHeader = ({period, title, color}) => {
	const finalPeriod = period;

	return (
		<div className="worldWater-LayerLabelHeader">
			<div
				className="worldWater-LayerLabelHeader-color"
				style={{...(color ? {background: color} : {})}}
			/>
			<div className="worldWater-LayerLabelHeader-body">
				<div className="worldWater-LayerLabelHeader-title">{title}</div>
				<div className="worldWater-LayerLabelHeader-period">{finalPeriod}</div>
			</div>
		</div>
	);
};

LayerLabelHeader.propTypes = {
	period: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	title: PropTypes.string,
	color: PropTypes.string,
};

export default WaterExtentMapLabel;
