import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../../state/Select';
import Action from '../../../../../../../../state/Action';
import {waterLevelChartType} from '../../../../../../../../constants/app';

const mapStateToProps = state => {
	return {
		activeKey: Select.components.get(state, waterLevelChartType, 'active'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSetActive: value => {
			dispatch(Action.components.set(waterLevelChartType, 'active', value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
