import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';

const mapStateToProps = state => {
	return {
		data: Select.worldWater.waterLevel.getDataForDownload(state),
		activeIndicatorKey:
			Select.worldWater.waterLevel.indicators.getActiveKey(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
