import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import '../../style.scss';

const Map = () => (
	<TourStepsContainer>
		<TourStepsTitle>Water extent</TourStepsTitle>
		<TourStepsSection>
			The view is dedicated to explore and interact with SWD water extent
			products.
		</TourStepsSection>
		<TourStepsSection>
			<div>
				<b>Map</b> - Main window to visualise the products and supporting
				datasets.
			</div>
			<div>
				<b>Product Tag</b> - Get the metadata information about displayed
				product and define basic visualisation parameters.
			</div>
		</TourStepsSection>
	</TourStepsContainer>
);

export default Map;
