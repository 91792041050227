import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import '../../style.scss';

const Title = () => (
	<TourStepsContainer>
		<TourStepsTitle>Statistics</TourStepsTitle>
		<TourStepsSection>
			The view is dedicated to working with WorldWater statistics.
		</TourStepsSection>
		<TourStepsSection>
			Tip: Use CTRL / ⌘ + click to select multiple features.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Title;
