import {
	statisticsMapLayerKey,
	statisticsCountrySelectionKey,
	statisticsHydrobasinSelectionKey,
} from '../../constants/app';
import availableCountries from '../geometries/availableCountries_statistics.json';
import statisticsMapLayerStyle from '../mapStyles/statisticsMapLayerStyle';

export default {
	key: statisticsMapLayerKey,
	type: 'vector',
	options: {
		features: availableCountries?.features,
		fidColumnName: 'g',
		style: statisticsMapLayerStyle?.data?.definition,
		selectable: true,
		selected: {
			[statisticsCountrySelectionKey]: {},
			[statisticsHydrobasinSelectionKey]: {},
		},
	},
};
