import PropTypes from 'prop-types';

const SectionInfoLabel = ({children}) => {
	return <h4 className="worldWater-StatisticsSection-infoLabel">{children}</h4>;
};

SectionInfoLabel.propTypes = {
	children: PropTypes.node,
};

export default SectionInfoLabel;
