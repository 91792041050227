import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Select from '../../state/Select';
import Presentation from './presentation';
import Action from '../../state/Action';
import {waterExtentTimeline} from '../../constants/app';
import {utils} from '@gisatcz/ptr-utils';
import {statisticsHydrobasinSelectionKey} from '../../constants/app';
import {waterLevelSelectionKey} from '../../constants/app';

const mapStateToProps = state => {
	setRecomputeState(state);
	const activeMapKey = Select.maps.getMapSetActiveMapKey(
		state,
		Select.maps.getActiveSetKey(state)
	);
	const activeView = Select.router.getCurrent(state);
	const views = Select.views.getAll(state);

	return {
		activeView: activeView,
		views: views,
		activeLayers: Select.maps.getLayersStateByMapKey(state, activeMapKey),
		isViewOpen: Select.components.get(state, 'ProductExplorer', 'isViewOpen'),
		isPlaceOpen: Select.components.get(state, 'ProductExplorer', 'isPlaceOpen'),
		activeMapKey: activeMapKey,
		activeMapWindows: Select.maps.getMapSetMapKeys(state, 'map-set'),
		activePlaceKeys: Select.places.getActiveKeys(state),
		timelineIsOpen: Select.components.get(state, waterExtentTimeline, 'open'),
		waterLevelSelectedPoints: Select.components.get(
			state,
			'SelectedPointSelect',
			'key'
		),
		activeMapLayers: Select.maps.getMapLayers(activeMapKey),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActivePlaceByKeyExplorer: () =>
			dispatch(Action.worldWater.setActivePlaceByFeatureKey('COL')),
		setActivePlaceForStatistics: () => {
			dispatch(
				Action.places.setActiveKeys(['c657f887-a734-4ffe-b904-725632193318'])
			);
			dispatch(
				Action.worldWater.statistics.setActiveSelectionFeatureKeysByActivePlaceKeys()
			);
			dispatch(Action.worldWater.statistics.countries.use());
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(
					statisticsHydrobasinSelectionKey,
					[]
				)
			);
		},
		setPlaceOpen: open =>
			dispatch(Action.components.set('ProductExplorer', 'isPlaceOpen', open)),
		setViewOpen: open =>
			dispatch(Action.components.set('ProductExplorer', 'isViewOpen', open)),
		addTourLayerToMap: (mapKey, layer) =>
			dispatch(Action.worldWater.waterExtent.addTourLayerToMap(mapKey, layer)),
		controlTimeline: open => {
			dispatch(Action.components.set(waterExtentTimeline, 'open', open));
		},
		addNewMapWindow: () => {
			const mapKey = utils.uuid();
			dispatch(Action.maps.addMap({key: mapKey}));
			dispatch(Action.maps.addMapToSet(mapKey, 'map-set'));
		},
		setMapWindow: () => {
			dispatch(Action.components.set('Maps', 'mode', 'set'));
		},
		openChartSection: () => {
			dispatch(Action.components.set('water-presence', 'advancedOpen', true));
		},
		setWaterLevelActiveSelection: activeMapKey => {
			dispatch(
				Action.maps.setLayerSelectedFeatureKeys(activeMapKey, 'waterLevel', [
					'6120016562',
					'61562000270401',
				])
			);
		},
		clearWaterLevelActiveSelection: () => {
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(waterLevelSelectionKey, [])
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
