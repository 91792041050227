import {createSelector} from 'reselect';
const getAllAsObject = state => state.worldWater.waterLevel.features.byPlace;

const getByPlace = createSelector(
	[getAllAsObject, (state, place) => place],
	(data, place) => {
		if (data && place) {
			return data[place] || null;
		} else {
			return null;
		}
	}
);

export default {
	getByPlace,
};
