import PropTypes from 'prop-types';
import './style.scss';
import TimeRangeChart from '../../../../../../../common/TimeRangeChart';
import {forwardRef, useState} from 'react';

// eslint-disable-next-line react/display-name
const LevelChart = forwardRef(
	(
		{
			ChartComponent,
			chartComponentSettings,
			data,
			metadata,
			overviewChartMetadata,
			onHover,
		},
		ref
	) => {
		const [dateRange, setDateRange] = useState(null);

		// useEffect(() => {
		// TODO here is the place for resetting dateRange, if some condition in incoming data was met
		// setDateRange(null);
		// }, [data, metadata]);

		return (
			<TimeRangeChart
				ref={ref}
				ChartComponent={ChartComponent}
				chartComponentSettings={chartComponentSettings}
				data={data}
				metadata={metadata}
				overviewChartMetadata={overviewChartMetadata}
				onHover={onHover}
				dateRange={dateRange}
				onDateRangeChange={setDateRange}
			/>
		);
	}
);

LevelChart.propTypes = {
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.object,
	data: PropTypes.array,
	metadata: PropTypes.object,
	onHover: PropTypes.func,
	overviewChartMetadata: PropTypes.object,
};

export default LevelChart;
