import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSection from '../components/TourStepsSection';
import TourStepsTitle from '../components/TourStepsTitle';
import TourStepsSubtitle from '../components/TourStepsSubtitle';

import '../style.scss';

const Cards = () => (
	<TourStepsContainer>
		<TourStepsTitle>Applications</TourStepsTitle>
		<TourStepsSubtitle>
			Cards provide access to different <b>applications</b>.
		</TourStepsSubtitle>
		<TourStepsSection>
			<div>
				<b>Product explorer</b> - Exploring the products of the World Water
				project
			</div>
			<div>
				<b>Statistics</b> - Exploration of statistics calculated from those
				products.
			</div>
			<div>
				<b>Processing</b> - Link to online processing system.
			</div>
		</TourStepsSection>
	</TourStepsContainer>
);

export default Cards;
