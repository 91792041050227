import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import Presentation from './presentation';
import getPlaceKey from './helpers/getPlaceKey';

const componentId = 'worldWaterProductViewer_MapPlace';

const mapStateToProps = state => {
	const activeViewKey = Select.views.getActiveKey(state);

	const currentRouter = Select.router.getCurrent(state);
	const placeKey = getPlaceKey(currentRouter?.params?.path?.urlPlaceKey);
	const activeScreen = {urlPlaceKey: currentRouter?.params?.path?.urlPlaceKey};

	const layout = Select.components.getByComponentKey(state, 'layout');
	return {
		urlPlaceKey: currentRouter?.params?.path?.urlPlaceKey,
		isDark: Select.components.get(state, 'App', 'darkMode'),
		activeScreen,
		placeKey,
		layout,
		activeViewKey,
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		return {
			loadPlaceByKey: placeKey => {
				dispatch(Action.places.useKeys([placeKey], componentId)).then(() => {
					dispatch(Action.places.setActiveKey(placeKey));
					dispatch(
						Action.worldWater.setPlaceSelectSelectionSelectedFeatureKeysByActivePlaceKey(
							placeKey
						)
					);
					dispatch(Action.worldWater.setMapViewFromPlace(placeKey));
					dispatch(Action.worldWater.waterLevel.handleReferenceLayer());
				});
			},
			//on app init and on active view key change
			ensureView: (viewKey, firstRender, placeKey) => {
				//clear current view
				dispatch(Action.components.remove('addMap'));
				dispatch(Action.components.remove('layout'));

				// set new active view
				dispatch(
					Action.worldWater.setActiveView(
						viewKey,
						firstRender,
						placeKey,
						Action
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.places.setActiveKey(null));
				dispatch(Action.views.setActiveKey(null));
				dispatch(Action.places.useKeysClear(componentId));

				//TODO clear map state
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
