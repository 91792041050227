import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';
import {useEffect, useState} from 'react';

const InfoBox = ({hideAfterSeconds, children}) => {
	const [className, setClassName] = useState('ptr-InfoBox');
	const style = {
		transitionDelay: hideAfterSeconds ? `${hideAfterSeconds}s` : 'none',
	};

	useEffect(() => {
		setTimeout(() => {
			setClassName(
				classnames(className, {
					toBeHidden: !!hideAfterSeconds,
				})
			);
		}, 100);
	}, []);

	return (
		<div style={style} className={className}>
			{children}
		</div>
	);
};

InfoBox.propTypes = {
	hideAfterSeconds: PropTypes.number,
	children: PropTypes.node,
};

export default InfoBox;
