import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../../../state/Select';
import Presentation from './StatisticItem';

const mapStateToProps = state => {
	const statistics = Select.worldWater.waterLevel.getMinimum(state);

	return {
		value: statistics?.value,
		deviation: statistics?.deviation,
		activeIndicator:
			Select.worldWater.waterLevel.indicators.getActiveKey(state),
	};
};

export default connect(mapStateToProps)(Presentation);
