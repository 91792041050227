import {createBrowserHistory, createMemoryHistory} from 'history';
import {
	createStore,
	combineReducers,
	applyMiddleware,
	compose,
	thunk,
	reduxBatch,
	logger,
	activeMetadataActions,
	baseStores,
} from '@gisatcz/ptr-state';
import {
	createAsyncMiddleware,
	createRequestCounter,
	isServer,
} from '@gisatcz/ptr-core';
import {initApp} from '../app';

import router from './router/reducers';
import configuration from './worldWater/configuration/reducers';
import points from './worldWater/waterLevel/points/reducers';
import features from './worldWater/waterLevel/features/reducers';
import indicators from './worldWater/waterLevel/indicators/reducers';
import satellites from './worldWater/waterLevel/satellites/reducers';
import valueTypes from './worldWater/waterLevel/valueTypes/reducers';
import statisticsFeatures from './worldWater/statistics/features/reducers';
import statisticsBasin from './worldWater/statistics/basin/reducers';
import statisticsCountries from './worldWater/statistics/countries/reducers';

export const history = isServer
	? createMemoryHistory()
	: createBrowserHistory();

function createMiddleware(requestCounter, withoutLogger) {
	const enhancedThunk = thunk.withExtraArgument(activeMetadataActions);

	const middlewares = [
		createAsyncMiddleware(requestCounter),
		enhancedThunk,
		process.env.NODE_ENV === 'development' &&
			!isServer &&
			!withoutLogger &&
			logger,
	];

	return applyMiddleware(...middlewares.filter(v => v !== false));
}

function createReducer() {
	const waterLevel = combineReducers({
		points,
		features,
		indicators,
		satellites,
		valueTypes,
	});

	const statistics = combineReducers({
		features: statisticsFeatures,
		basin: statisticsBasin,
		countries: statisticsCountries,
	});

	const reducers = combineReducers({
		configuration,
		waterLevel,
		statistics,
	});

	return combineReducers({
		...baseStores,
		router,
		worldWater: reducers,
	});
}

const composeEnhancers =
	(typeof window !== 'undefined' &&
		window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({})) ||
	compose;

// TODO why request counter?
function createEnhancer(requestCounter) {
	return composeEnhancers(
		reduxBatch,
		createMiddleware(requestCounter),
		reduxBatch,
		createMiddleware(requestCounter, true),
		reduxBatch
	);
}

/**
 * Returns object with keys `store`, `readyP`.
 * - `readyP` - Promise that resolves once the app is initialized (helpful with SSR).
 * - `store` - Redux store.
 */
function createAppStore(options, pregeneratedState = {}) {
	const isPreloaded = !isServer && window.__PRELOADED_STATE__ != null;
	const initialState = isPreloaded
		? window.__PRELOADED_STATE__
		: {...pregeneratedState};
	if (isPreloaded) {
		delete window.__PRELOADED_STATE__;
	}

	const requestCounter = createRequestCounter();
	const store = createStore(
		createReducer(),
		initialState,
		createEnhancer(requestCounter)
	);

	const absPath =
		options?.absPath ??
		window.location.protocol +
			'//' +
			window.location.host +
			process.env.PUBLIC_URL;

	initApp(store, {
		absPath,
		isPreloaded,
		currentUrl: options?.currentUrl,
		navHandler: options?.navHandler,
	});

	return {
		store: store,
		requestCounter: requestCounter,
	};
}

export default createAppStore;
