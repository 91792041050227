import PropTypes from 'prop-types';
import classNames from 'classnames';
import {forIn as _forIn} from 'lodash';
import MapLegendValueItem from './MapLegendValueItem';
import './style.scss';

const maximalCountForMinimize = 4;

const MapLegendAttributeValues = ({values, isExpanded}) => {
	const classes = classNames('worldWater-MapLegend-AttributeValues', {
		'is-expanded': isExpanded,
	});

	let content = [];
	let index = 0;
	_forIn(values, (value, key) => {
		const el = (
			<MapLegendValueItem
				key={key}
				color={value.fill || value.color}
				label={value.name || key}
			/>
		);
		if (!isExpanded && index < maximalCountForMinimize) {
			content.push(el);
		} else if (isExpanded) {
			content.push(el);
		}
		index++;
	});

	if (!isExpanded && index > content.length) {
		content.push(
			<div className="worldWater-MapLegendExpandInfo">
				Expand by click for more classes.
			</div>
		);
	}

	return <div className={classes}>{content}</div>;
};

MapLegendAttributeValues.propTypes = {
	values: PropTypes.object,
	isExpanded: PropTypes.bool,
};

export default MapLegendAttributeValues;
