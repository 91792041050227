import PropTypes from 'prop-types';
import Link from '../Link';
import {getRouter} from '../../../router';
import './style.scss';
import logo from '../../../assets/img/logo_icon_small.png';
import {Icon} from '@gisatcz/ptr-atoms';

const HeaderTitle = ({children}) => {
	const router = getRouter();

	return (
		<Link
			classes={'worldWater-HeaderTitle'}
			name={'home'}
			router={router}
			recoverParams={false}
			ignoreQueryString={true}
			paramsFilter={['urlPlaceKey']}
		>
			<Icon icon="ri-back" />
			<div className="worldWater-HeaderLogo">
				<img alt="logo" src={logo} />
			</div>
			<h1 className="worldWater-HeaderTitle-name">
				<span>WorldWater</span>
				<span>{children}</span>
			</h1>
		</Link>
	);
};

HeaderTitle.propTypes = {
	children: PropTypes.node,
};

export default HeaderTitle;
