import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		data: Select.worldWater.statistics.getSweChartData(
			state,
			ownProps.store,
			ownProps.chartKey,
			ownProps.sources,
			ownProps.isMultiselect
		),
		valueType: Select.components.get(state, ownProps.chartKey, 'valueType'),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
