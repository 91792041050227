import PropTypes from 'prop-types';
import {includes as _includes} from 'lodash';
import {Icon} from '@gisatcz/ptr-atoms';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import './style.scss';
import {useEffect} from 'react';
import classNames from 'classnames';

const ButtonSwitcher = ({
	icon,
	activeOptionKey,
	disabledKeys,
	options,
	onActiveOptionChange,
	onDisabledKeysChange,
	condensed,
}) => {
	useEffect(() => {
		if (onDisabledKeysChange) {
			onDisabledKeysChange(disabledKeys);
		}
	}, [disabledKeys]);

	const classes = classNames('worldWater-ButtonSwitcher', {
		'is-condensed': condensed,
	});

	return (
		<div className={classes}>
			<Icon icon={icon} />
			<ButtonSwitch
				className="worldWater-ButtonSwitcher-buttonSwitch"
				ghost
				small
				onClick={onActiveOptionChange}
			>
				{options &&
					options.map(option => (
						<ButtonSwitchOption
							key={option?.key}
							value={option?.key}
							active={option?.key === activeOptionKey}
							disabled={_includes(disabledKeys, option?.key)}
						>
							{option?.data.nameDisplay}
							{option?.data.nameInternal ? (
								<div className="worldWater-ButtonSwitcher-shortName">
									{option?.data.nameInternal}
								</div>
							) : null}
						</ButtonSwitchOption>
					))}
			</ButtonSwitch>
		</div>
	);
};

ButtonSwitcher.propTypes = {
	icon: PropTypes.string,
	condensed: PropTypes.bool,
	activeOptionKey: PropTypes.string,
	disabledKeys: PropTypes.array,
	options: PropTypes.array,
	onActiveOptionChange: PropTypes.func,
	onDisabledKeysChange: PropTypes.func,
};

export default ButtonSwitcher;
