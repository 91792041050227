import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import {Icon} from '@gisatcz/ptr-atoms';
import './style.scss';

const LevelChartSwitcher = ({activeKey, onSetActive}) => {
	useEffect(() => {
		onSetActive('lineChart');
	}, []);

	return (
		<div className="worldWater-LevelChartSwitcher">
			<ButtonSwitch
				className="worldWater-LevelChartSwitcher-buttonSwitch"
				ghost
				small
				onClick={onSetActive}
			>
				<ButtonSwitchOption
					key="lineChart"
					value="lineChart"
					active={'lineChart' === activeKey}
				>
					<Icon icon="ri-line-chart-basic" />
				</ButtonSwitchOption>
				<ButtonSwitchOption
					key="scatterChart"
					value="scatterChart"
					active={'scatterChart' === activeKey}
				>
					<Icon icon="ri-scatter-chart-basic" />
				</ButtonSwitchOption>
			</ButtonSwitch>
		</div>
	);
};

LevelChartSwitcher.propTypes = {
	activeKey: PropTypes.string,
	onSetActive: PropTypes.func,
};

export default LevelChartSwitcher;
