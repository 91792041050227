import PropTypes from 'prop-types';
import './style.scss';

const StatisticsHeaderControls = ({children}) => {
	return <div className="worldWater-StatisticsHeaderControls">{children}</div>;
};

StatisticsHeaderControls.propTypes = {
	children: PropTypes.node,
};

export default StatisticsHeaderControls;
