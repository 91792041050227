import PropTypes from 'prop-types';
import ChartWrapper from '../ChartWrapper';
import Legend from './Legend';
import {colors} from './colors';
import RadialChart from '../../../../../../../common/RadialChart';
import ValueTypeSwitcher from '../switchers/ValueTypeSwitcher';
import NivoChartHoverWrapper from '../../../../../../../common/NivoChartHover/NivoChartHoverWrapper';
import './style.scss';

const getSurfaceWaterChanges = bar => {
	return (
		<div className="worldWater-nivo-tooltip">
			<div className="worldWater-nivo-tooltip-content">
				<h4>
					<span
						className="worldWater-nivo-tooltip-color"
						style={{background: bar?.color}}
					/>
					{bar?.category}
				</h4>
				<span className="worldWater-nivo-tooltip-time"></span>
				<div>
					<span className="worldWater-nivo-tooltip-label">{bar?.groupId}:</span>
					<span className="worldWater-nivo-tooltip-value">{bar?.data?.y}</span>
					<span className="worldWater-nivo-tooltip-deviation">
						{bar?.data?.unit}
					</span>
				</div>
			</div>
		</div>
	);
};

const SurfaceWaterChanges = ({
	data,
	chartKey,
	activePlaces,
	activeOptionKey,
}) => {
	const chartTitle = (
		<>
			Surface water changes comparison{' '}
			<span>{activeOptionKey === 'rel' ? '[%]' : '[sqkm]'}</span>
		</>
	);
	return (
		<ChartWrapper
			title={chartTitle}
			className={'is-SurfaceWaterChanges'}
			data={data?.download}
			Tools={<ValueTypeSwitcher chartKey={chartKey} />}
		>
			<div className="worldWater-AdvancedStatistics-SurfaceWaterChanges-chartsContainer">
				{data?.chart
					? data?.chart.map(item => {
							return (
								<div
									className="worldWater-AdvancedStatistics-SurfaceWaterChanges-chart"
									key={item?.id}
								>
									<h5 className="worldWater-AdvancedStatistics-SurfaceWaterChanges-chartLabel">
										{activePlaces.length > 1
											? activePlaces.find(
													place => place?.data?.nameInternal === item?.id
											  )?.data?.nameDisplay
											: data?.chart?.length > 1
											? item?.id
											: null}
									</h5>
									<NivoChartHoverWrapper
										chartType={'activeChartKey'}
										tooltipPosition={'center'}
										wrapperRefQuery={'svg'}
										getHoverContent={getSurfaceWaterChanges}
									>
										<RadialChart
											data={item?.data}
											withoutLegend
											colors={colors}
										/>
									</NivoChartHoverWrapper>
								</div>
							);
					  })
					: null}
			</div>
			<Legend data={data?.chart?.[0]?.data?.[0]?.data} />
		</ChartWrapper>
	);
};

SurfaceWaterChanges.propTypes = {
	data: PropTypes.array,
	chartKey: PropTypes.string,
	activePlaces: PropTypes.array,
	activeOptionKey: PropTypes.string,
};

export default SurfaceWaterChanges;
