//import PropTypes from 'prop-types';
import './style.scss';
import SectionInfoWrapper from '.././components/Wrapper';
import SectionInfoParagraph from '../components/Paragraph';
import SectionInfoSegment from '../components/Segment';
import SectionInfoLabel from '../components/Label';

const WaterChangesSection = () => {
	return (
		<SectionInfoWrapper>
			<SectionInfoSegment>
				<SectionInfoParagraph>
					The surface water change statistics illustrate changes in the annual
					minimum and maximum water extents over the years. The changes are
					evaluated by comparing pairs of binary rasters for the first and last
					years, and the penultimate and last years, of the time series. Four
					potential scenarios can be identified: Gain Water, Loss Water,
					Unchanged Water, and Unchanged Land. However, only the most important
					first two pivotal scenarios are presented in the radial chart.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Layer</SectionInfoLabel>
				<SectionInfoParagraph>
					The calculation of surface water change statistics for basins and
					areas of interest is based on change detection between four pairs of
					10 m annual rasters. The maximum water extent layer comprises pixels
					identified as inundated at least once in all available images during a
					year. The minimum water extent layer includes pixels consistently
					classified as inundated in all images. The rasters are projected using
					the Equal-Area Cylindrical World projection to ensure accurate area
					calculations.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Attributes</SectionInfoLabel>
				<SectionInfoParagraph>
					The attributes displayed show relative and absolute changes for the
					minimum and maximum extents of water bodies. &#39;Gain Water&#39;
					represents the sum of pixels in the area of interest (or basin) where
					the value has shifted from 0 to 1, indicating the expansion of water
					bodies. &#39;Loss Water&#39; denotes the opposite scenario, when a
					pixel&#39;s value changes from 1 to 0, signifying the retreat of water
					bodies.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Charts</SectionInfoLabel>
				<SectionInfoParagraph>
					The radial chart visualizes decreases (red) or increases (green) among
					pairs of rasters (2017-2021 and 2020-2021) for annual minimum and
					maximum water extent in the selected period. The displayed values
					represent only the extent of these changes and do not indicate the
					total area of water bodies. A switcher allows the display of detected
					gains and losses of water in absolute [sqkm] and relative [%] terms.
					The data and charts for the selected period can be downloaded.
				</SectionInfoParagraph>
			</SectionInfoSegment>
		</SectionInfoWrapper>
	);
};

WaterChangesSection.propTypes = {};

export default WaterChangesSection;
