import Helmet from 'react-helmet';
import {AnalyticsProvider} from 'use-analytics';
import Favicon from 'react-favicon';

import analytics from './utils/analytics';
import {connects} from '@gisatcz/ptr-state';
import {AppContainer} from '@gisatcz/ptr-components';

import Action from './state/Action';
import {init as initCore} from './core';
import {appKey} from './constants/app';
import {initRouter} from './router';

import favicon from './assets/img/favicon.png';

// base mapStyles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import './styles/index.scss';

import Router from './components/Router';

const path = process.env.PUBLIC_URL;

function initApp(Store, {absPath, isPreloaded, currentUrl, navHandler}) {
	initCore({
		router: initRouter(absPath, currentUrl, Store, isPreloaded, navHandler),
	});

	if (isPreloaded) {
		return;
	}

	Store.dispatch(Action.init(path));
}

const ConnectedAppContainer = connects.AppContainer(AppContainer);

const AppWrapper = () => {
	return (
		<>
			<Favicon url={favicon} />
			<Helmet defaultTitle="WorldWater | Product Viewer" />
			<AnalyticsProvider instance={analytics}>
				<ConnectedAppContainer appKey={appKey}>
					<Router />
				</ConnectedAppContainer>
			</AnalyticsProvider>
		</>
	);
};

export {AppWrapper, initApp};
