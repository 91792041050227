import PropTypes from 'prop-types';

const Number = ({value}) => {
	return (
		<div className="worldWater-StatisticItem-number">
			{value > 1000
				? value?.toLocaleString(undefined, {
						maximumFractionDigits: 0,
				  })
				: value?.toLocaleString(undefined, {
						maximumFractionDigits: 2,
				  })}
		</div>
	);
};

Number.propTypes = {
	value: PropTypes.number,
};

const Text = ({value}) => {
	return <div className="worldWater-StatisticItem-text">{value || '-'}</div>;
};

Text.propTypes = {
	value: PropTypes.string,
};

const StatisticItem = ({title, value, deviation, activeIndicator}) => {
	let unit = null;
	switch (activeIndicator) {
		case 'waterLevel':
			unit = <span>m</span>;
			break;
		case 'waterExtent':
			unit = (
				<span>
					km<sup>2</sup>
				</span>
			);
			break;
		case 'waterVolume':
			unit = (
				<span>
					m<sup>3</sup>
				</span>
			);
			break;
	}

	return (
		<div className="worldWater-StatisticItem">
			<div className="worldWater-StatisticItem-title">{title}</div>
			<div className="worldWater-StatisticItem-content">
				{typeof value === 'number' ? (
					<Number value={value} />
				) : (
					<Text value={value} />
				)}
				{deviation ? (
					<div className="worldWater-StatisticItem-deviation">
						± {deviation?.toFixed(2)}
					</div>
				) : null}
				{unit}
			</div>
		</div>
	);
};

StatisticItem.propTypes = {
	title: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	deviation: PropTypes.number,
	activeIndicator: PropTypes.string,
};

export default StatisticItem;
