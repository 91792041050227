import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const WaterFrequency = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Water Occurrence during the observation period" />
		<MetadataItemInfoSectionParagraph
			text="This product describes frequency in water coverage over a selected
				period in percent. The overall water dynamics capture both intra- and
				inter- annual variations (from low to high water presence frequency)
				between 2017 and 2021."
		/>
		<MetadataItemInfoSectionParagraph
			title="Frequency:"
			text=" Variable number of years (minimum one year)."
		/>
		<MetadataItemInfoSectionLegendInfo
			items={['0: Not water', '1-100: Occurrence', '255: No data']}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/water_frequency.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default WaterFrequency;
