import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Presentation from './presentation';
import {mapCoordinatesControl} from '../../../../constants/app';

const mapStateToProps = state => {
	return {
		active: Select.components.get(state, mapCoordinatesControl, 'active'),
		coordinates: Select.components.get(
			state,
			mapCoordinatesControl,
			'coordinates'
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUnmount: () => {
			dispatch(Action.components.set(mapCoordinatesControl, 'active', false));
			dispatch(
				Action.components.set(mapCoordinatesControl, 'coordinates', null)
			);
		},
		setActive: active => {
			dispatch(Action.components.set(mapCoordinatesControl, 'active', active));
			dispatch(
				Action.components.set(mapCoordinatesControl, 'coordinates', null)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
