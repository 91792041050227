import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import Presentation from './presentation';
import Action from '../../../../../state/Action';

const mapStateToProps = state => {
	return {
		isPlaceOpen: Select.components.get(state, 'ProductExplorer', 'isPlaceOpen'),
		isViewOpen: Select.components.get(state, 'ProductExplorer', 'isViewOpen'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setPlaceOpen: isOpen =>
			dispatch(Action.components.set('ProductExplorer', 'isPlaceOpen', isOpen)),
		setViewOpen: isOpen =>
			dispatch(Action.components.set('ProductExplorer', 'isViewOpen', isOpen)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
