import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../components/Attribute';
import Select from '../../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		items: Select.places.getActiveModels(state)?.map(item => {
			return {
				key: item?.data?.nameInternal,
				name: item?.data?.nameDisplay,
			};
		}),
		attributeMetadata: Select.attributes.getByKey(state, ownProps.attributeKey),
		data: Select.worldWater.statistics.countries.getValuesForAttribute(
			state,
			ownProps.attributeKey
		),
		selectionData: Select.selections.getActive(state)?.data,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
