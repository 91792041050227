import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../state/Select';
import Action from '../../../../../../state/Action';

import Presentation from './presentation';

import './style.scss';

const mapStateToProps = (state, ownProps) => {
	const downloadTiffLink =
		Select.worldWater.waterExtent.getDownloadUrlByLayerState(
			ownProps.layerState
		);

	const layerTooltipActive = Select.worldWater.getMapLayerOptionValueByKey(
		state,
		ownProps.mapKey,
		ownProps.layerState.key,
		'pickable'
	);

	const colors = Select.worldWater.waterExtent.getLayerTemplateBadgeColors(
		state,
		ownProps.layerState?.layerTemplateKey
	);

	return {
		layerTemplate: Select.layerTemplates.getByKey(
			state,
			ownProps.layerState?.layerTemplateKey
		),
		period: Select.periods.getByKey(
			state,
			ownProps.layerState?.metadataModifiers?.periodKey
		),
		downloadTiffLink,
		wmsUrl: Select.worldWater.waterExtent.getWmsUrlByLayerState(
			ownProps.layerState
		),
		layerTooltipActive,
		color: colors.active,
		downloadSldLink: Select.worldWater.getDownloadSldLink(
			state,
			ownProps.layerState?.layerTemplateKey
		),
		layerTemplateMetadataComponentKey:
			Select.worldWater.getLayerTemplateMetadataComponentKey(
				state,
				ownProps.layerState?.layerTemplateKey
			),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onRemove: () => {
			dispatch(
				Action.maps.removeMapLayer(ownProps.mapKey, ownProps.layerState.key)
			);
		},
		onOpacityChange: opacity => {
			dispatch(
				Action.maps.setMapLayerOpacity(
					ownProps.mapKey,
					ownProps.layerState.key,
					opacity / 100
				)
			);
		},
		onLayerTooltipActiveChange: active => {
			dispatch(
				Action.maps.setMapLayerOption(
					ownProps.mapKey,
					ownProps.layerState.key,
					'pickable',
					active
				)
			);
			dispatch(
				Action.maps.setMapLayerOption(
					ownProps.mapKey,
					ownProps.layerState.key,
					'hoverable',
					active
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
