import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../components/Attribute';
import Select from '../../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		items: Select.selections
			.getActive(state)
			?.data?.featureKeysFilter?.keys.map(item => {
				return {
					key: item,
					name: item,
				};
			}),
		attributeMetadata: Select.attributes.getByKey(state, ownProps.attributeKey),
		data: Select.worldWater.statistics.basin.getValuesForAttribute(
			state,
			ownProps.attributeKey
		),
		selectionData: Select.selections.getActive(state)?.data,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
