import {ResponsiveRadialBar} from '@nivo/radial-bar';
import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import './style.scss';

// eslint-disable-next-line react/display-name
const RadialChart = forwardRef(
	({data, withoutLegend, colors, onHover, Tooltip}, ref) => {
		return (
			<div className="worldWater-RadialChart-wrapper" ref={ref}>
				<ResponsiveRadialBar
					data={data}
					onMouseEnter={onHover}
					onMouseLeave={() => onHover()}
					tooltip={Tooltip || (() => {})}
					padding={0.4}
					innerRadius={0.1}
					margin={{top: 5, right: 25, bottom: 30, left: 25}}
					radialAxisStart={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
					}}
					circularAxisOuter={{tickSize: 5, tickPadding: 13, tickRotation: 0}}
					enableTracks
					colors={colors}
					legends={
						!withoutLegend && [
							{
								anchor: 'right',
								direction: 'column',
								justify: false,
								translateX: 80,
								translateY: 0,
								itemsSpacing: 6,
								itemDirection: 'left-to-right',
								itemWidth: 100,
								itemHeight: 18,
								itemTextColor: '#999',
								symbolSize: 18,
								symbolShape: 'square',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000',
										},
									},
								],
							},
						]
					}
				/>
			</div>
		);
	}
);

RadialChart.propTypes = {
	data: PropTypes.array,
	withoutLegend: PropTypes.bool,
	colors: PropTypes.array,
	ref: PropTypes.object,
	Tooltip: PropTypes.func,
	onHover: PropTypes.func,
};

export default RadialChart;
