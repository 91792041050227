import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Presentation from './presentation';
import {statisticsHydrobasinSelectionKey} from '../../../../../constants/app';

const mapStateToProps = state => {
	return {
		isMulti: Select.worldWater.statistics.isMultiselectEnabled(),
		activeHydrobasinKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		hydrobasins:
			Select.worldWater.statistics.getHydrobasinsForActivePlace(state),
		selectionData: Select.selections.getActive(state)?.data,
	};
};

const mapDispatchToPropsFactory = dispatch => {
	return {
		onActiveHydrobasinChange: keys => {
			dispatch(
				Action.selections.setFeatureKeysFilterKeys(
					statisticsHydrobasinSelectionKey,
					keys
				)
			);
			dispatch(Action.worldWater.statistics.basin.use());
		},
		onMount: () => {
			// TODO
		},
		onUnmount: () => {},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(Presentation);
