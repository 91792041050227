import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header from './components/Header';
import SingleHydrobasinStatistics from './singleHydrobasin/SingleHydrobasinStatistics';
import MultipleHydrobasinsStatistics from './multipleHydrobasins/MultipleHydrobasinsStatistics';
import SingleCountryStatistics from './singleCountry/SingleCountryStatistics';
import MultipleCountriesStatistics from './multipleCountries/MultipleCountriesStatistics';
import './style.scss';

const StatisticsPanel = ({mode, isOpen}) => {
	let content = null;
	if (mode === 'countries') {
		content = <MultipleCountriesStatistics />;
	} else if (mode === 'hydrobasins') {
		content = <MultipleHydrobasinsStatistics />;
	} else if (mode === 'hydrobasin') {
		content = <SingleHydrobasinStatistics />;
	} else {
		content = <SingleCountryStatistics />;
	}

	return (
		<div
			className={classNames('worldWater-StatisticsPanel', {'is-open': isOpen})}
		>
			<Header />
			{content}
		</div>
	);
};

StatisticsPanel.propTypes = {
	mode: PropTypes.string,
	isOpen: PropTypes.bool,
};

export default StatisticsPanel;
