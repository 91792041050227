import {connect} from '@gisatcz/ptr-state';
// import {utils} from '@gisatcz/ptr-utils';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		levels: Select.areas.areaTreeLevels.getAll(state),
		activeLevelKey: Select.areas.areaTreeLevels.getActiveKey(state),
		activePlaceKeys: Select.places.getActiveKeys(state),
	};
};

const mapDispatchToPropsFactory = dispatch => {
	return () => {
		return {
			setActiveLevel: activeLevelKey => {
				dispatch(Action.areas.areaTreeLevels.setActiveKey(activeLevelKey));
				dispatch(Action.worldWater.statistics.handleLevelSwitch());
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(Presentation);
