//import PropTypes from 'prop-types';
import './style.scss';
import SectionInfoWrapper from '.././components/Wrapper';
import SectionInfoParagraph from '../components/Paragraph';
import SectionInfoSegment from '../components/Segment';
import SectionInfoLabel from '../components/Label';

const WaterPresenceSection = () => {
	return (
		<SectionInfoWrapper>
			<SectionInfoSegment>
				<SectionInfoParagraph>
					Water presence statistics capture seasonal fluctuations in water
					extent, displayed as monthly data over various years. These monthly
					data in selected years are compared with a long-term monthly average.
					The analysis of water presence data enables the discernment of
					long-term shifts in water surface areas, including any increases or
					decreases as well as regular seasonal oscillations.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Layer</SectionInfoLabel>
				<SectionInfoParagraph>
					The calculation of water presence statistics for basins and countries
					is based on binary monthly raster layers of water presence with a high
					resolution of 10 meters derived from aggregated SAR and optical
					imagery. The rasters distinguish between water (1) and land (0). The
					rasters are projected using the Equal-Area Cylindrical World
					projection to ensure accurate area calculations.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Attributes</SectionInfoLabel>
				<SectionInfoParagraph>
					The header of the water presence indicator provides an overview of the
					average annual extent of water bodies derived from monthly raster
					composites. The first of two values points to the average area of
					water bodies for the last year. The second value represents the same
					indicator for a long-term period calculated from all of the currently
					available data. For more detailed analysis, the data are originally
					available on a monthly basis, enabling the detection of within-year
					fluctuations in water extent. The basic comparison of the long-term
					average with the current value indicates the overall trend.
				</SectionInfoParagraph>
			</SectionInfoSegment>
			<SectionInfoSegment>
				<SectionInfoLabel>Charts</SectionInfoLabel>
				<SectionInfoParagraph>
					The water presence chart opposes monthly water extent values (in
					square kilometers) from 2017 to 2021 (depicted in blue) with the
					long-term average values for the same period (in gray). The
					chart&#39;s interactive section allows the selection of any time
					period within the reference period, with options to download the data
					and charts for the selected period.
				</SectionInfoParagraph>
			</SectionInfoSegment>
		</SectionInfoWrapper>
	);
};

WaterPresenceSection.propTypes = {};

export default WaterPresenceSection;
