import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import '../../style.scss';

const Panel = () => (
	<TourStepsContainer>
		<TourStepsTitle>Side panel</TourStepsTitle>
		<TourStepsSection>
			Panel that shows charts and attributes divided to sections.
		</TourStepsSection>
		<TourStepsSection>
			Control the sections, download chart data or look for detailed information
			about each section.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Panel;
