import {commonSelectors} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';

const getSubstate = state => state.worldWater.waterLevel.valueTypes;
const getActiveKey = commonSelectors.getActiveKey(getSubstate);
const getActive = commonSelectors.getActive(getSubstate);
const getAll = commonSelectors.getAll(getSubstate);

const isRelative = createSelector([getActiveKey], activeKey => {
	return activeKey === 'relative';
});

export default {
	getActiveKey,
	getActive,
	getAll,

	isRelative,
};
