import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import {Children} from 'react';

export const AdvancedStatisticsSection = ({children}) => {
	return <div className="worldWater-AdvancedStatisticsSection">{children}</div>;
};

AdvancedStatisticsSection.propTypes = {
	children: PropTypes.node,
};

const AdvancedStatistics = ({isOpen, children}) => {
	const className = classNames(
		'worldWater-StatisticsSection-AdvancedStatistics',
		{isOpen: isOpen}
	);
	return (
		<div className={className}>
			{children
				? Children.map(children, (child, i) => {
						if (child) {
							return (
								<AdvancedStatisticsSection key={i}>
									{child}
								</AdvancedStatisticsSection>
							);
						} else {
							return null;
						}
				  })
				: null}
		</div>
	);
};

AdvancedStatistics.propTypes = {
	isOpen: PropTypes.bool,
	children: PropTypes.node,
};

export default AdvancedStatistics;
