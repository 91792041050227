import PropTypes from 'prop-types';
import StatisticsPanelAttribute from './StatisticsPanelAttribute';
import utils from '../../../../../../utils';

const Attribute = ({
	attributeKey,
	attributeMetadata,
	items,
	data,
	selectionData,
}) => {
	const title = attributeMetadata?.data?.nameDisplay;
	const unit = attributeMetadata?.data?.unit;

	if (items?.length && data?.length) {
		const values = [];
		const numOfValues = data?.[0]?.length;

		if (numOfValues) {
			for (let i = 0; i < numOfValues; i++) {
				values.push(
					items.map((item, index) => {
						return {
							key: item?.key,
							name: item?.name,
							data: data?.[index]?.[i],
							color: utils.getSelectedFeaturePrimaryColor(
								item?.key,
								selectionData
							),
						};
					})
				);
			}

			if (values?.length) {
				return values.map((item, i) => {
					return (
						<StatisticsPanelAttribute
							key={`${attributeKey}-${i}`}
							title={title}
							unit={unit}
							data={item}
							attributeKey={attributeKey}
						/>
					);
				});
			} else {
				return null;
			}
		} else {
			return null;
		}
	} else {
		return null;
	}
};

Attribute.propTypes = {
	attributeKey: PropTypes.string,
	attributeMetadata: PropTypes.object,
	items: PropTypes.array,
	data: PropTypes.array,
	selectionData: PropTypes.object,
};

export default Attribute;
