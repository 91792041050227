import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.worldWater.waterLevel.satellites;
const getActiveKey = commonSelectors.getActiveKey(getSubstate);
const getActive = commonSelectors.getActive(getSubstate);
const getAll = commonSelectors.getAll(getSubstate);

export default {
	getActiveKey,
	getActive,
	getAll,
};
