import ActionTypes from '../../../../constants/ActionTypes';

const INITIAL_STATE = {
	byPlaceKey: {},
};

const add = (state, placeKey, basinKey, data) => {
	if (placeKey && basinKey && data) {
		return {
			...state,
			byPlaceKey: {
				...state.byPlaceKey,
				[placeKey]: {
					...(state.byPlaceKey?.[placeKey] ? state.byPlaceKey[placeKey] : {}),
					[basinKey]: data,
				},
			},
		};
	} else {
		return state;
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.WORLD_WATER.STATISTICS.BASINS.ADD:
			return add(state, action.placeKey, action.basinKey, action.data);
		default:
			return state;
	}
};
