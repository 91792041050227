export default {
	key: 'statisticsMapLayerStyle',
	data: {
		nameDisplay: 'Countries',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						{
							fill: '#1e7aad',
							fillOpacity: 0.05,
							outlineColor: '#4997bd',
							outlineWidth: 1,
							outlineOpacity: 1,
						},
						{
							attributeKey: 's',
							attributeValues: {
								y: {
									fillOpacity: 0.5,
								},
							},
						},
						{
							attributeKey: 'noData',
							attributeValues: {
								true: {
									outlineColor: '#bdbdbd',
									fill: '#9f9f9f',
									fillOpacity: 0.2,
								},
							},
						},
					],
				},
			],
		},
	},
};
