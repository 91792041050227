import {filter as _filter} from 'lodash';

/**
 * Get config for layer by layerKey of layerTemplateKey
 * @param configs {Array}
 * @param layerState {Object} Panther's layerState
 * @return {null|Object}
 */
function getConfigByLayerState(configs, layerState) {
	if (configs?.length && layerState) {
		const {layerTemplateKey} = layerState;
		return _filter(configs, component => {
			return (
				component.layerTemplateKey &&
				component.layerTemplateKey === layerTemplateKey
			);
		});
	} else {
		return null;
	}
}

function getValueAndLabelByStyle(value, style) {
	const isClasses = style?.data?.definition?.rules?.[0]?.styles?.[1]?.values;
	const isAttributeValues =
		style?.data?.definition?.rules?.[0]?.styles?.[1]?.attributeValues;
	const isScale =
		style?.data?.definition?.rules?.[0]?.styles?.[1]?.attributeScale;

	if (isClasses) {
		const styleClass =
			style?.data?.definition?.rules?.[0]?.styles?.[1]?.values?.[value];
		const label = styleClass?.name;

		return {
			label: label,
			class: styleClass,
		};
	}

	if (isAttributeValues) {
		const styleClass =
			style?.data?.definition?.rules?.[0]?.styles?.[1]?.attributeValues?.[
				value
			];
		const label = styleClass?.name;

		return {
			label: label,
			class: styleClass,
		};
	}

	if (isScale) {
		return {
			label: style?.data?.nameDisplay,
			value,
			class: style?.data?.definition?.rules?.[0]?.styles?.[1]?.attributeScale,
		};
	}

	return {
		value: value,
	};
}

export default {
	getConfigByLayerState,
	getValueAndLabelByStyle,
};
