import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		activePlaceKeys: Select.places.getActiveKeys(state),
		activeLevel: Select.areas.areaTreeLevels.getActive(state)?.data?.level,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
