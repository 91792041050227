import {
	Select as CommonSelect,
	createRecomputeSelector,
	createRecomputeObserver,
} from '@gisatcz/ptr-state';
import {stateManagement} from '@gisatcz/ptr-utils';
import {createSelector} from 'reselect';
import {includes as _includes} from 'lodash';
import helpers from './helpers';

import configuration from './configuration/selectors';
import statistics from './statistics/selectors';
import waterExtent from './waterExtent/selectors';
import waterLevel from './waterLevel/selectors';
import config from '../../config';

const mergeLegendConfigurations = (
	configuration1 = [],
	configuration2 = []
) => {
	let merged = [...configuration1];

	configuration2.forEach(c2 => {
		const styleKey = c2.styleKey;
		const layerTemplateKeys = c2.layerTemplateKeys;

		const styleIncludedIndex = merged.findIndex(c => c.styleKey === styleKey);
		if (styleIncludedIndex === -1) {
			merged.push(c2);
		} else {
			merged = stateManagement.replaceItemOnIndex(merged, styleIncludedIndex, {
				...merged[styleIncludedIndex],
				layerTemplateKeys: [
					...new Set([
						...merged[styleIncludedIndex].layerTemplateKeys,
						...layerTemplateKeys,
					]),
				],
			});
		}
	});
	return merged;
};

const getMapLayerOptionValueByKey = createSelector(
	[
		CommonSelect.maps.getMapLayersStateByMapKey,
		(state, mapKey, layerKey) => layerKey,
		(state, mapKey, layerKey, optionKey) => optionKey,
	],
	(layers, layerKey, optionKey) => {
		const layer = layers.find(layer => _includes(layerKey, layer.key));

		if (layer) {
			return layer.options?.[optionKey];
		} else {
			return null;
		}
	}
);

const getCompleteConfigurationObserver = createRecomputeObserver(
	CommonSelect.app.getCompleteConfiguration
);

const getStyleKeyByLayerTemplateKey = createRecomputeSelector(
	layerTemplateKey => {
		CommonSelect.app.getCompleteLocalConfigurationObserver;
		const configuration = getCompleteConfigurationObserver();
		return configuration?.configurationByLayerTemplateKeys?.[layerTemplateKey]
			?.styleKey;
	}
);

const getLayerTemplateConfigurations = createSelector(
	[CommonSelect.app.getCompleteConfiguration],
	configuration => {
		if (configuration?.configurationByLayerTemplateKeys) {
			return configuration.configurationByLayerTemplateKeys;
		} else {
			return {};
		}
	}
);

const getLayerTemplateConfigurationByKey = createSelector(
	[
		getLayerTemplateConfigurations,
		(state, layerTemplateKey) => layerTemplateKey,
	],
	(configuration, layerTemplateKey) => {
		return configuration?.[layerTemplateKey] || {};
	}
);

const getLayerTemplateMetadataComponentKey = createSelector(
	[getLayerTemplateConfigurationByKey],
	layerTemplateConfigurationByKey => {
		return layerTemplateConfigurationByKey?.metadataComponentKey;
	}
);

const getDownloadSldLink = createSelector(
	[getLayerTemplateConfigurationByKey],
	layerTemplateConfigurationByKey => {
		return `${config.waterExtentStylesDataRepository}/${layerTemplateConfigurationByKey?.sldFileName}.sld`;
	}
);

const getMergedLegendConfiguration = createSelector(
	[
		CommonSelect.views.getActive,
		CommonSelect.maps.getLayersStateByMapKey,
		(state, mapKey, componentsByLayer) => componentsByLayer,
	],
	(view, layersState = [], componentsByLayer) => {
		if (view?.data?.nameInternal === 'extent') {
			const configuration = layersState?.reduce((acc, layerState) => {
				const layerTemplateKey = layerState.layerTemplateKey;
				const isHidden = layerState?.options?.legendHidden;
				if (!isHidden) {
					const components = helpers.getConfigByLayerState(
						componentsByLayer,
						layerState
					);
					const componentsElms = components.reduce((accu, component) => {
						const legend = component?.legend;
						if (legend) {
							const styleKey = getStyleKeyByLayerTemplateKey(layerTemplateKey);

							const styleIncludedIndex = accu.findIndex(
								c => c.styleKey === styleKey
							);
							if (styleIncludedIndex === -1) {
								return [
									...accu,
									{
										legend,
										layerTemplateKeys: [layerTemplateKey],
										styleKey,
									},
								];
							} else {
								return stateManagement.replaceItemOnIndex(
									accu,
									styleIncludedIndex,
									{
										...accu[styleIncludedIndex],
										layerTemplateKeys: [
											...accu[styleIncludedIndex].layerTemplateKeys,
											layerTemplateKey,
										],
									}
								);
							}
						} else {
							return accu;
						}
					}, []);
					return mergeLegendConfigurations(acc, componentsElms);
				} else {
					return acc;
				}
			}, []);
			return configuration;
		} else {
			return null;
		}
	}
);

export default {
	getMapLayerOptionValueByKey,
	getLayerTemplateConfigurations,
	getLayerTemplateMetadataComponentKey,
	getDownloadSldLink,
	getMergedLegendConfiguration,
	getStyleKeyByLayerTemplateKey,

	configuration,
	statistics,
	waterExtent,
	waterLevel,
};
