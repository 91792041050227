import {distinctColours} from './constants/app';

/**
 * Get color of selected feature
 * @param featureKey {string}
 * @param selectionData {Object}
 * @returns {string} hex colour code
 */
function getSelectedFeaturePrimaryColor(featureKey, selectionData) {
	const fallbackColor =
		selectionData?.style?.fill ||
		selectionData?.style?.outlineColor ||
		'#aaaaaa';
	const distinctItems = selectionData?.distinctItems;
	if (distinctItems) {
		const colorIndex =
			selectionData?.featureKeysFilter?.keyColourIndexPairs?.[featureKey];
		return distinctColours[colorIndex] || fallbackColor;
	} else {
		return fallbackColor;
	}
}

export default {
	getSelectedFeaturePrimaryColor,
};
