import PropTypes from 'prop-types';

const SectionInfoParagraph = ({children}) => {
	return (
		<p className="worldWater-StatisticsSection-infoParagraph">{children}</p>
	);
};

SectionInfoParagraph.propTypes = {
	children: PropTypes.node,
};

export default SectionInfoParagraph;
