import {connect} from '@gisatcz/ptr-state';

import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';

import Presentation from './presentation';
import {
	waterLevelSelectionKey,
	waterLevelPanel,
	distinctColours,
} from '../../../../../constants/app';

const mapStateToProps = state => {
	const selection = Select.selections.getActive(state);
	const selectedFeatureKeys =
		selection?.key === waterLevelSelectionKey
			? selection?.data?.featureKeysFilter?.keys
			: null;

	return {
		isOpen: Select.components.get(state, waterLevelPanel, 'open'),
		selectedFeatureKeys,
		activePlaceKey: Select.places.getActiveKey(state),
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		return {
			onUnmount: () => {
				dispatch(Action.components.set(waterLevelPanel, 'open', false));
			},
			onMount: () => {
				dispatch(
					Action.selections.add([
						{
							key: waterLevelSelectionKey,
							data: {
								colourPalette: distinctColours,
								distinctItems: true,
								featureKeysFilter: {
									keys: [],
								},
								style: {
									outlineWidth: 3,
									outlineColor: '#ff0000',
									fill: '#ff8888',
								},
							},
						},
					])
				);
				dispatch(Action.selections.setActiveKey(waterLevelSelectionKey));
			},
			onSelectedFeaturesChange: featureKeys => {
				dispatch(Action.components.set(waterLevelPanel, 'open', true));
				dispatch(Action.worldWater.waterLevel.points.use(featureKeys));
			},
			onActivePlaceChange: () => {
				dispatch(Action.components.set(waterLevelPanel, 'open', false));
				dispatch(
					Action.selections.setFeatureKeysFilterKeys(waterLevelSelectionKey, [])
				);
			},
			setOpen: value => {
				dispatch(Action.components.set(waterLevelPanel, 'open', value));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
