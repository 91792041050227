import PropTypes from 'prop-types';
import WaterLevelPanelConfig from './WaterLevelPanelConfig';
import WaterLevelDownload from './WaterLevelDownload';

import './style.scss';

const WaterLevelPanelHeader = ({chartRef}) => {
	return (
		<div className="worldWater-WaterLevelPanelHeader">
			<WaterLevelPanelConfig />
			<div className="worldWater-WaterLevelPanelTools">
				<WaterLevelDownload chartRef={chartRef} />
			</div>
		</div>
	);
};

WaterLevelPanelHeader.propTypes = {
	chartRef: PropTypes.object,
};

export default WaterLevelPanelHeader;
