import {connect} from '@gisatcz/ptr-state';

import Presentation from './presentation';
import Select from '../../../../../../state/Select';
import Action from '../../../../../../state/Action';
import {getRouter} from '../../../../../../router';
import {mapCoordinatesControl} from '../../../../../../constants/app';

// const filterByActive = {application: true, place: true};
const filterByActive = {application: true};
// const filterByActive = {};
// const filter = {key: {in: ['7ab44fb2-63a6-439b-ab38-a5ec9e75d0c2', 'dbf9f849-163b-43a0-9ab8-cab2d6c0e3dc']}}; //TODO temporary
const filter = {};
const order = [['nameDisplay', 'ascending']];
const start = 1;
const length = 1000;
const componentId = 'worldWaterProductViewer_ViewControl';

const mapStateToProps = state => {
	return {
		activeView: Select.views.getActive(state),
		activePlaceKey: Select.places.getActiveKey(state),
		activeViewKey: Select.views.getActiveKey(state),
		views:
			Select.views.getIndexed(
				state,
				filterByActive,
				filter,
				order,
				start,
				length
			) || [],
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		return {
			onUnmount: () => {
				//clear load views indexed
				dispatch(Action.views.useIndexedClear(componentId));
			},
			onViewChange: (activePlaceKey, viewKey) => {
				dispatch(Action.components.set('Maps', 'mode', 'mapSet'));
				dispatch(Action.components.set(mapCoordinatesControl, 'active', false));

				//change route to the view.value

				const router = getRouter();
				const homeUrl = router.pathFor('/product-explorer/place', {
					urlPlaceKey: activePlaceKey,
					...(viewKey ? {view: viewKey} : {}),
				});
				router.nav(homeUrl);
			},
			loadViews: () => {
				//TODO load view based on active placeKey
				dispatch(
					Action.views.useIndexed(
						filterByActive,
						filter,
						order,
						start,
						length,
						componentId
					)
				);
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
