import {connect} from '@gisatcz/ptr-state';

import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const componentKey = 'waterExtentPanel';

const mapStateToProps = state => {
	return {
		activePlaceKey: Select.places.getActiveKey(state),
		componentKey,
	};
};

const mapDispatchToProps = dispatch => {
	return () => {
		return {
			onPlaceChange: () => {
				dispatch(Action.worldWater.waterExtent.clearMapsLayers());
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
