import {Select as CommonSelect} from '@gisatcz/ptr-state';
import router from './router/selectors';
import WorldWaterSelect from './worldWater/selectors';

export default {
	...CommonSelect,
	router,
	worldWater: {
		...WorldWaterSelect,
	},
};
