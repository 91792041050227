import config from '../../../../config';
import request from '../../../../utils/request';
import Select from '../../../Select';
import ActionTypes from '../../../../constants/ActionTypes';

const actionAdd = (data, placeNameInternal) => {
	return {
		type: ActionTypes.WORLD_WATER.STATISTICS.COUNTRIES.ADD,
		data,
		placeNameInternal,
	};
};
/**
 * Loads and add statistics for given place
 * @param {string} placeNameInternal
 * @returns
 */
const loadStatisticsForPlace = placeNameInternal => {
	return dispatch => {
		let url = `${config.statisticsDataRepository}/countries/${placeNameInternal}/country.json`;

		return request(url, 'GET', null, null).then(data => {
			if (data) {
				dispatch(
					actionAdd({...data, key: placeNameInternal}, placeNameInternal)
				);
			} else {
				console.warn(`No data for place ${placeNameInternal}`);
			}
		});
	};
};

/**
 * If statistics are not loaded, then loads and add statistics for active place
 * @param {string} placeNameInternal
 * @returns
 */
function ensureCountryStatistics(placeNameInternal) {
	return (dispatch, getState) => {
		// check if statistics are loaded
		const statistics =
			Select.worldWater.statistics.countries.getStatsByPlaceNameInternal(
				getState(),
				placeNameInternal
			);

		if (!statistics) {
			// load statistics for country
			dispatch(loadStatisticsForPlace(placeNameInternal));
		}
	};
}

const use = () => {
	return (dispatch, getState) => {
		const state = getState();
		const places = Select.places
			.getActiveModels(state)
			?.map(place => place?.data?.nameInternal);
		if (places?.length) {
			places.forEach(place => {
				dispatch(ensureCountryStatistics(place));
			});
		}
	};
};

export default {
	use,
};
