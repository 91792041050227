import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSection from '../components/TourStepsSection';
import TourStepsTitle from '../components/TourStepsTitle';

import '../style.scss';

const Map = () => (
	<TourStepsContainer>
		<TourStepsTitle>World map</TourStepsTitle>
		<TourStepsSection>
			This map shows available areas of interest (AOI) in which you can explore
			the products.
		</TourStepsSection>
	</TourStepsContainer>
);

export default Map;
