import {Action as CommonAction} from '@gisatcz/ptr-state';
import Select from '../../Select';
import {appKey as applicationKey} from '../../../constants/app';

function clearMapsLayers() {
	return (dispatch, getState) => {
		const mapSetKey = Select.maps.getActiveSetKey(getState());
		const mapKeys = Select.maps.getMapSetMapKeys(getState(), mapSetKey);
		if (mapKeys.length) {
			mapKeys.forEach(mapKey => {
				const layers = Select.maps.getLayersStateByMapKey(getState(), mapKey);
				if (layers?.length) {
					dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
				}
			});
		}
	};
}

function addTourLayerToMap(mapKey, layer) {
	return dispatch => {
		dispatch(CommonAction.maps.addMapLayerToIndex(mapKey, layer, 0));
	};
}

function useRelationsForTimeline(start, length, componentKey) {
	return (dispatch, getState) => {
		const state = getState();
		const layerTemplateKeys = Select.components.get(
			state,
			componentKey,
			'layerTemplateKeys'
		);
		const placeKey = Select.places.getActiveKey(state);

		if (placeKey && layerTemplateKeys?.length) {
			layerTemplateKeys.forEach(layerTemplateKey =>
				dispatch(
					CommonAction.data.spatialRelations.ensureIndexed(
						{
							applicationKey,
							placeKey,
							layerTemplateKey,
						},
						null,
						start,
						length
					)
				)
			);
		}
	};
}

export default {
	clearMapsLayers,
	useRelationsForTimeline,
	addTourLayerToMap,
};
