import PropTypes from 'prop-types';
import './style.scss';
import ModalWindow from '../../../../../../../common/ModalWindow';
import Modal from 'react-modal';
import WaterPresenceSection from './sections/WaterPresence';
import WaterExtentSection from './sections/WaterExtent';
import WaterChangesSection from './sections/WaterChanges';

Modal.setAppElement('#root');

const SectionInfo = ({
	sectionKey,
	sectionInfoIsOpen,
	setSectionInfoIsOpen,
	title,
}) => {
	const activeSection = () => {
		switch (sectionKey) {
			case 'water-presence':
				return <WaterPresenceSection />;
			case 'permanent-seasonal-water-extent':
				return <WaterExtentSection />;
			case 'surface-water-changes':
				return <WaterChangesSection />;
			default:
				<></>;
		}
	};
	return (
		<ModalWindow
			title={<h3>{title}</h3>}
			isOpen={sectionInfoIsOpen}
			onClose={() => setSectionInfoIsOpen(false)}
			className="worldWater-Modal"
		>
			{activeSection()}
		</ModalWindow>
	);
};

SectionInfo.propTypes = {
	sectionKey: PropTypes.string,
	sectionInfoIsOpen: PropTypes.bool,
	setSectionInfoIsOpen: PropTypes.func,
	title: PropTypes.string,
};

export default SectionInfo;
