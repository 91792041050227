export const appKey = 'esaWorldWater';

export const MAX_MAPS_IN_MAP_SET = 2;
export const gaId = window?.runtimeConfig?.googleAnalyticsKey;

// Components
export const waterLevelPanel = 'WaterLevelPanel';
export const waterLevelChartWrapperKey = 'waterLevelChartWrapper';
export const waterLevelChartType = 'WaterLevelChartType';
export const waterExtentTimeline = 'WaterExtentTimeline';
export const mapCoordinatesControl = 'mapCoordinatesControl';

// Maps
export const worldCountriesMapLayerKey = 'world-countries-layer';
export const placeSelectMapKey = 'PlaceSelectMap';
export const placeSelectMapLayerKey = 'place-select-layer';
export const statisticsMapSetKey = 'StatisticsMapSet';
export const statisticsMapKey = 'StatisticsMap';
export const statisticsMapLayerKey = 'statistics-map-layer';
export const hydrobasinsLevelFidColumnName = 'HYBAS_ID';

// Map views
export const defaultStatisticsMapView = {
	center: {
		lat: 20,
		lon: -10,
	},
	boxRange: 20000000,
};

// Selections
export const placeSelectMapSelectionKey = 'placeSelectMapSelection';
export const waterLevelSelectionKey = 'waterLevel';
export const statisticsCountrySelectionKey = 'statistics-country-selection';
export const statisticsHydrobasinSelectionKey =
	'statistics-hydrobasin-selection';
export const distinctColours = [
	'#42d4f4',
	'#e15dda',
	'#ffe119',
	'#aaffc3',
	'#9A6324',
	'#dcbeff',
	'#800000',
	'#fffac8',
	'#ff9ec1',
	'#f58231',
	'#11bda3',
	'#25b537',
	'#e6194B',
	'#911eb4',
	'#bfef45',
	'#808000',
	'#ffd8b1',
	'#4363d8',
	'#282892',
];

// Attributes
export const waterLevelAttribute = 'waterLevelAttribute';
export const deviationAttribute = 'deviationAttribute';

// timeline
export const timelineLayerLineHeight = 18; //px
export const timelineLayerElementHeight = 10; //px, rest is for line padding

//colors
export const multiplePointSelectionColors = [
	'var(--accent50)',
	'#ff7f00',
	'#2ca02c',
	'#d62728',
	'#9467bd',
	'#67bd94',
	'#735F32',
	'#d35fd3',
];
