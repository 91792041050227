import PropTypes from 'prop-types';
import Layer from '../Layer';
import {utils} from '@gisatcz/ptr-utils';

const defaultLineHeight = utils.getRemSize();

const MapTimelineLegend = ({layers, lineHeight = defaultLineHeight}) => {
	const layersElms =
		layers?.map(layer => {
			return (
				<Layer
					{...{lineHeight}}
					className={'ptr-maptimeline-legenditem'}
					key={layer.legend.layerTemplateKey}
					layerTemplateKey={layer.legend.layerTemplateKey}
				/>
			);
		}) || [];

	return <div className={'ptr-maptimelinelegend'}>{layersElms}</div>;
};

MapTimelineLegend.propTypes = {
	layers: PropTypes.array,
	lineHeight: PropTypes.number,
};

export default MapTimelineLegend;
