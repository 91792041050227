import {useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import './style.scss';
import utils from '../../../../../../../../utils';

const dot = (color = 'transparent') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		height: 10,
		minWidth: 10,
	},
});

const SelectedPointSelect = ({
	points,
	selectedKey,
	selectionData,
	onSelectedKeyChange,
}) => {
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: state.isFocused ? 'var(--accent10)' : 'var(--accent05)',
			padding: '0 10px 0 0',
			borderWidth: '0',
			borderRadius: '0',
			boxShadow: '0',
			color: 'var(--accent80)',
			'&:hover': {
				color: 'var(--accent85)',
				background: 'var(--accent10)',
			},
			width: '100%',
			cursor: 'pointer',
			minHeight: '2.25rem',
		}),
		container: provided => ({
			...provided,
			fontSize: '1rem',
			borderRadius: '0',
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		dropdownIndicator: (provided, state) => {
			return {
				...provided,
				padding: '0',
				cursor: 'pointer',
				color: 'var(--accent85)',
				opacity: state.isDisabled ? 0.1 : 0.7,
				transition: 0,
				transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
				'&:hover': {
					opacity: 1,
					color: 'inherit',
				},
			};
		},
		input: provided => ({
			...provided,
			padding: '0',
			margin: 0,
			cursor: 'pointer',
			...dot(),
		}),
		singleValue: (styles, {data}) => ({
			...styles,
			fontWeight: 'bold',
			fontSize: '1rem',
			fontFamily: 'Sen, Roboto, sans-serif',
			letterSpacing: '-.5px',
			color: 'inherit',
			cursor: 'pointer',
			lineHeight: 1.15,
			...dot(data.color),
		}),
		valueContainer: provided => ({
			...provided,
			color: provided.color,
			padding: '0px 7px',
			cursor: 'text',
		}),
		menu: provided => ({
			...provided,
			zIndex: 1000,
			minWidth: '11.15rem',
			border: '1px solid var(--base30)',
			borderRadius: 0,
			background: 'rgba(var(--base0rgb),.75)',
			backdropFilter: 'blur(6px) saturate(180%)',
		}),
		option: (styles, {data, isDisabled, isSelected}) => {
			return {
				...styles,
				pointerEvents: isDisabled ? 'none' : 'auto',
				cursor: 'pointer',
				color: isSelected ? 'var(--accent80)' : 'var(--accent80)',
				fontSize: '.875rem',
				fontFamily: 'Roboto, sans-serif',
				padding: '10px',
				'&:hover': {
					background: isSelected ? 'var(--accent15)' : 'var(--accent05)',
				},
				...dot(data.color),
			};
		},
	};

	useEffect(() => {
		onSelectedKeyChange();
	}, [points]);

	const options = points?.map(point => {
		const name = point?.data?.name;
		const id = point?.key;
		const label = name && name !== id ? `${name} (${id})` : id;

		return {
			value: point?.key,
			label,
			color: utils.getSelectedFeaturePrimaryColor(point?.key, selectionData),
		};
	});
	const value = options?.find(option => option.value === selectedKey);

	const setActiveKey = item => {
		onSelectedKeyChange(item.value);
	};

	return (
		<ReactSelect
			className="worldWater-SelectedPointSelect"
			styles={customStyles}
			theme={theme => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: 'var(--accent15)',
					primary50: 'var(--accent15)',
					primary: 'var(--accent15)',
					neutral0: 'var(--base10)',
					neutral20: 'var(--base50)',
					neutral80: 'var(--base80)',
					neutral90: 'var(--base90)',
				},
			})}
			isMulti={false}
			isClearable={false}
			isSearchable={false}
			isDisabled={points?.length === 1}
			options={options}
			value={value}
			onChange={setActiveKey}
		/>
	);
};

SelectedPointSelect.propTypes = {
	points: PropTypes.array,
	selectedKey: PropTypes.string,
	selectionData: PropTypes.object,
	onSelectedKeyChange: PropTypes.func,
};

export default SelectedPointSelect;
