import PropTypes from 'prop-types';

import '../style.scss';

const TourStepsTitle = ({children}) => (
	<h3 className="worldWater-Tour-steps-title">{children}</h3>
);

TourStepsTitle.propTypes = {
	children: PropTypes.node,
};

export default TourStepsTitle;
