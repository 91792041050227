import ActionTypes from '../../../../constants/ActionTypes';

const INITIAL_STATE = {
	byPlace: {},
};

const add = (state, place, features) => {
	if (place && features) {
		return {
			...state,
			byPlace: {
				...state.byPlace,
				[place]: features,
			},
		};
	} else {
		return state;
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.WORLD_WATER.WATER_LEVEL.FEATURES.ADD:
			return add(state, action.place, action.features);
		default:
			return state;
	}
};
