import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const WaterClassification = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Yearly maps of surface water with indication of the permanent and seasonal water" />
		<MetadataItemInfoSectionParagraph text="This product displays the composite of the twelve monthly water occurrence products put into classes of permanent surface water (>75%), seasonal surface water and no surface water." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Yearly." />
		<MetadataItemInfoSectionLegendInfo
			items={[
				'0: Not Water',
				'1: Permanent water',
				'2: Seasonal water',
				'255: No data',
			]}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/water_classification.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default WaterClassification;
