import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const ChangeWaterClassification = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Yearly changes of surface waters in extent" />
		<MetadataItemInfoSectionParagraph text="Change classification of surface water types between permanent, seasonal water and no water. The seasonality products of two consecutive years (here 2017 and 2018) were compared against each other to derive the increases and decreases of permanent, seasonal waters and no water." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Yearly." />
		<MetadataItemInfoSectionLegendInfo
			items={[
				'0: Unchanged not water',
				'1: Gain permanent water',
				'2: Loss permanent water',
				'3: Unchanged permanent water',
				'4: Gain seasonal water',
				'5: Loss seasonal water',
				'6: Unchanged seasonal water',
				'7: Permanent to seasonal',
				'8: Seasonal to permanent',
				'255: No data',
			]}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/change_water_classification.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default ChangeWaterClassification;
