import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactSelect, {components} from 'react-select';
import ReactResizeDetector from 'react-resize-detector';
import './style.scss';
import {useState} from 'react';

const MultiValueLabel = ({data, ...props}) => {
	const color = data?.color;

	return (
		<div className="worldWater-StatisticsSelect-valueContainer">
			<div
				className="worldWater-StatisticsSelect-colorIndicator"
				style={{backgroundColor: color}}
			/>
			<components.MultiValueLabel {...props} />
		</div>
	);
};

MultiValueLabel.propTypes = {
	data: PropTypes.object,
};

const StatisticsSelect = ({
	placeholder,
	className,
	options,
	value,
	isMulti,
	isSmall,
	isSearchable,
	isDisabled,
	onChange,
}) => {
	const [screenIsSmall, setScreenIsSmall] = useState();

	const colors = {
		background_active: 'var(--accent05)',
		background_focused: 'var(--accent10)',
		background_hovered: 'var(--accent10)',
		background_disabled: 'var(--base05)',
		background_accented: 'var(--accent50)',
		background_accented_hover: 'var(--accent60)',

		text_base: 'var(--accent80)',
		text_secondary: 'var(--accent50)',
		text_placeholder: 'var(--accent40)',
		text_disabled: 'var(--base40)',
		text_inverted: 'var(--accent0)',

		icon_base: 'var(--accent75)',
		icon_secondary: 'var(--accent50)',
		icon_disabled: 'var(--base30)',
	};

	// const bp = {
	// 	xsmall: 575.98,
	// 	small: 767.98,
	// 	medium: 991.98,
	// 	large: 1199.98,
	// 	xlarge: 1399.98,
	// 	xxlarge: 1599.98,
	// 	xxxlarge: 2599.98,
	// };

	const getScreenSize = () => {
		window.matchMedia('(max-width: 450px)').matches
			? setScreenIsSmall(true)
			: setScreenIsSmall(false);
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: state.isFocused
				? colors.background_focused
				: state.isDisabled
				? colors.background_disabled
				: colors.background_active,
			padding: '0 10px 0 0',
			borderWidth: '0',
			borderRadius: '0',
			boxShadow: '0',
			color: state.isFocused ? 'var(--base100)' : colors.text_base,
			'&:hover': {
				color: state.isFocused ? colors.text_base : colors.text_inverted,
				background: state.isFocused ? 'var(--accent10)' : 'var(--accent10)',
			},
			width: '100%',
			cursor: 'pointer',
			minHeight: '100%',
			'@media only screen and (max-width: 800px)': {
				padding: '0',
			},
			height: state.isFocused ? 'auto' : '2rem',
			overflowY: 'auto',
		}),
		container: provided => ({
			...provided,
			fontSize: '1rem',
			borderRadius: '0',
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		IndicatorsContainer: provided => {
			return {
				...provided,
				'@media only screen and (max-width: 800px)': {
					width: '2rem',
				},
			};
		},
		dropdownIndicator: (provided, state) => {
			return {
				...provided,
				display: 'flex',
				justifyContent: 'center',
				padding: '0',
				cursor: 'pointer',
				color: isDisabled
					? colors.icon_disabled
					: state.isFocused
					? colors.icon_base
					: colors.icon_secondary,
				transition: 0,
				transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
				'&:hover': {
					color: colors.icon_base,
				},
				'@media only screen and (max-width: 600px)': {
					width: '1.3rem',
				},
			};
		},
		clearIndicator: (provided, state) => {
			return {
				...provided,
				padding: '5px',
				cursor: 'pointer',
				color: state.isFocused ? colors.icon_base : colors.icon_secondary,
				transition: 0,
				'&:hover': {
					color: colors.icon_base,
				},
			};
		},
		input: provided => ({
			...provided,
			padding: isMulti ? '0 7px' : '0',
			margin: 0,
			cursor: 'pointer',
			flex: 0,
			'@media only screen and (max-width: 1200px)': {
				padding: '0 3px',
			},
			'@media only screen and (max-width: 800px)': {
				padding: '0',
				width: 0,
			},
		}),
		singleValue: provided => ({
			...provided,
			fontWeight: 'bold',
			fontSize: '1.25rem',
			fontFamily: 'Sen, Roboto, sans-serif',
			letterSpacing: '-.5px',
			color: colors.text_base,
			cursor: 'pointer',
			padding: '2px 0 0',
			display: 'flex',
			flexDirection: 'column',
			lineHeight: 1.15,
		}),
		multiValue: provided => ({
			...provided,
			background: colors.background_accented,
			'@media only screen and (max-width: 800px)': {
				margin: 0,
			},
		}),
		multiValueLabel: provided => ({
			...provided,
			color: colors.text_inverted,
			fontWeight: isSmall ? 'normal' : 'bold',
			fontSize: isSmall ? '.9rem' : '1rem',
			padding: isSmall
				? activeValue?.length > 1
					? '2px 2px 1px 4px'
					: '1px 2px 1px 4px'
				: activeValue?.length > 1
				? '2px 2px 1px 6px'
				: '2px 3px 2px 6px',
			fontFamily: 'Sen, Roboto, sans-serif',
			height: isSmall ? '1.4rem' : '1.8rem',
			'@media only screen and (max-width: 1200px)': {
				fontSize: '.85rem',
				height: '1.3rem',
				padding:
					activeValue?.length > 1 ? '1px 2px 1px 4px' : '1px 2px 1px 6px',
			},
			'@media only screen and (max-width: 800px)': {
				padding: activeValue?.length > 1 ? '1px 0 1px 2px' : '1px 0 1px 4px',
			},
			'@media only screen and (max-width: 400px)': {
				fontSize: '0.7rem',
			},
		}),
		multiValueRemove: (provided, state) => ({
			...provided,
			color: colors.text_inverted,
			opacity: state.isDisabled ? 0.3 : 1,
			cursor: 'pointer',
			'&:hover': {
				color: colors.text_inverted,
				background: colors.background_accented_hover,
			},
			'@media only screen and (max-width: 800px)': {
				padding: '0 1px',
			},
		}),
		valueContainer: provided => ({
			...provided,
			padding: isMulti ? (isSmall ? '3px 5px' : '3px 7px') : '0px 10px',
			cursor: 'text',
			'@media only screen and (max-width: 1200px)': {
				padding: '2px 4px',
			},
			'@media only screen and (max-width: 800px)': {
				padding: '1px 0px',
			},
		}),
		placeholder: provided => ({
			...provided,
			padding: isMulti ? '0 7px' : '0px',
			fontFamily: 'Sen, Roboto, sans-serif',
			color: isDisabled ? colors.text_disabled : colors.text_placeholder,
			'@media only screen and (max-width: 800px)': {
				fontSize: '.8rem',
			},
			'@media only screen and (max-width: 400px)': {
				fontSize: '0.7rem',
			},
		}),
		menu: provided => ({
			...provided,
			zIndex: 1000,
			borderRadius: '.15rem',
			background: 'rgba(var(--base0rgb),.75)',
			backdropFilter: 'blur(6px) saturate(180%)',
		}),
		option: provided => ({
			...provided,
			cursor: 'pointer',
			color: colors.text_base,
			fontSize: '.875rem',
			fontFamily: 'Roboto, sans-serif',
			padding: '10px',
			'&:hover': {
				background: colors.background_hovered,
			},
			'@media only screen and (max-width: 400px)': {
				fontSize: '0.7rem',
			},
		}),
	};

	const classes = classnames('worldWater-StatisticsSelect', {}, className);

	const activeValue = value ? value : null;

	return (
		<>
			<ReactSelect
				className={classes}
				styles={customStyles}
				theme={theme => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: colors.background_hovered,
						primary50: colors.background_hovered,
						primary: colors.background_focused,
					},
				})}
				placeholder={placeholder || 'Select...'}
				isMulti={isMulti}
				isClearable={false}
				isSearchable={isSearchable}
				options={options}
				value={activeValue}
				isDisabled={isDisabled}
				onChange={onChange}
				hideSelectedOptions
				components={
					activeValue?.length > 1 && !screenIsSmall ? {MultiValueLabel} : <></>
				}
			/>
			<ReactResizeDetector onResize={getScreenSize} />
		</>
	);
};

StatisticsSelect.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	isMulti: PropTypes.bool,
	isSmall: PropTypes.bool,
	isSearchable: PropTypes.bool,
	isDisabled: PropTypes.bool,
	options: PropTypes.array,
	value: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.bool,
	]),
	small: PropTypes.bool,
	menuPosition: PropTypes.string,
	menuWidth: PropTypes.string,
	onChange: PropTypes.func,
};

export default StatisticsSelect;
