import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../../../../state/Action';
import Select from '../../../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const layerTemplate = Select.layerTemplates.getByKey(
		state,
		ownProps.layerTemplateKey
	);
	return {
		title: layerTemplate?.data?.nameDisplay,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const componentKey = `tileline_legend_layername_${utils.uuid()}`;

	return {
		onMount: () => {
			dispatch(
				Action.layerTemplates.useKeys([ownProps.layerTemplateKey], componentKey)
			);
		},
		onUnmount: () => {
			dispatch(Action.layerTemplates.useKeysClear(componentKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
