import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	WORLD_WATER: {
		CONFIGURATION: {
			OPEN: {
				ADD: null,
				REMOVE: null,
			},
			UPDATE: null,
		},
		STATISTICS: {
			FEATURES: {
				ADD: null,
			},
			BASINS: {
				ADD: null,
			},
			COUNTRIES: {
				ADD: null,
			},
		},
		WATER_LEVEL: {
			INDICATORS: {
				SET_ACTIVE_KEY: null,
			},
			SATELLITES: {
				SET_ACTIVE_KEY: null,
			},
			VALUE_TYPES: {
				SET_ACTIVE_KEY: null,
			},
			POINTS: {
				ADD: null,
			},
			FEATURES: {
				ADD: null,
			},
		},
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
