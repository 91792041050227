export default {
	key: 'waterLevelMapLayerStyle',
	data: {
		nameDisplay: '',
		description: '',
		definition: {
			rules: [
				{
					styles: [
						{
							fill: '#d3d3d3',
							fillOpacity: 0.7,
							outlineColor: '#a4a4a4',
							outlineWidth: 1,
							outlineOpacity: 1,
							size: 10,
						},
						{
							attributeKey: 'type',
							attributeValues: {
								lake: {
									fill: '#71baf1',
									outlineColor: '#2f7db0',
								},
								river: {
									fill: '#17619a',
									outlineColor: '#05395e',
								},
							},
						},
					],
				},
			],
		},
	},
};
