import classnames from 'classnames';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';
import ExpandableLabelsContainer from '../../../../../common/ExpandableLabel/ExpandableLabelsContainer';
import WaterExtentMapLabel from '../WaterExtentMapLabel';
import MapLegends from '../../../../../common/Maps/MapLegends/';
import WaterLevelMapLegend from '../WaterLevelMapLegend';
import MapComponentsGroup from '../../../../../common/Maps/MapComponentsGroup';
import './style.scss';

const MapWrapper = ({
	activeMapKey,
	mapKey,
	mapSetMapKeys,
	removeMap,
	removeAllLayers,
	isWaterExtentView,
	layersState,
	labelsRight,
	noTools,
	children,
	componentsByLayer,
	chartOpen,
	timelineOpen,
}) => {
	const wrapperClasses = classnames('ptr-map-wrapper worldWater-mapWrapper', {
		active: mapKey === activeMapKey,
		'one-map': mapSetMapKeys?.length === 1,
	});

	const classes = classnames('worldWater-MapSetTools', {
		'has-chart-open': chartOpen,
		'has-timeline-open': timelineOpen,
	});

	const labelContainerClasses = classnames(
		'worldWater-MapExtentLabelsContainer',
		{
			'is-right': labelsRight,
		}
	);

	const legendClasses = classnames('worldWater-MapLegends', {
		'is-left': !labelsRight,
		'is-right': labelsRight,
	});

	return (
		<div className={wrapperClasses}>
			{layersState?.length && isWaterExtentView ? (
				<ExpandableLabelsContainer className={labelContainerClasses}>
					{layersState.map(layerState => (
						<WaterExtentMapLabel
							key={layerState?.key}
							layerState={layerState}
							mapKey={mapKey}
						/>
					))}
				</ExpandableLabelsContainer>
			) : null}
			{!noTools ? (
				<div className="worldWater-mapWrapper-mapTools">
					{!_isEmpty(layersState) && isWaterExtentView ? (
						<IconTool
							tooltip={{
								text: 'Remove all layers',
								position: 'left',
								component: Tooltip,
							}}
							onClick={() => removeAllLayers(mapKey)}
							floating
							medium
							icon="ri-remove-layers"
						/>
					) : null}
					{mapSetMapKeys?.length > 1 ? (
						<IconTool
							tooltip={{
								text: 'Remove map',
								position: 'left',
								component: Tooltip,
							}}
							onClick={() => removeMap(mapKey)}
							floating
							medium
							icon="ri-close"
						/>
					) : null}
				</div>
			) : null}
			<MapComponentsGroup className={classes} key={'MapComponentsGroup_4'}>
				{!isWaterExtentView ? (
					<WaterLevelMapLegend />
				) : (
					<MapLegends
						componentsByLayer={componentsByLayer}
						className={legendClasses}
						mapKey={mapKey}
					/>
				)}
			</MapComponentsGroup>
			{children}
		</div>
	);
};

MapWrapper.propTypes = {
	removeMap: PropTypes.func,
	removeAllLayers: PropTypes.func,
	activeMapKey: PropTypes.string,
	mapKey: PropTypes.string,
	mapSetMapKeys: PropTypes.array,
	layersState: PropTypes.array,
	children: PropTypes.element,
	isWaterExtentView: PropTypes.bool,
	labelsRight: PropTypes.bool,
	noTools: PropTypes.bool,
	componentsByLayer: PropTypes.array,
	timelineOpen: PropTypes.bool,
	chartOpen: PropTypes.bool,
};

export default MapWrapper;
