import TourIcon from '../../../../../common/TourIcon';
//import DarkModeTool from './DarkModeTool';
import './style.scss';

const Toolbar = () => {
	return (
		<div className="worldWater-Toolbar">
			{/* <DarkModeTool /> */}
			<TourIcon />
		</div>
	);
};

export default Toolbar;
