import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import classNames from 'classnames';

import PanelControlButton from '../../../../common/PanelControlButton';
import WaterLevelPanelHeader from './WaterLevelPanelHeader';
import WaterLevelDashboard from './WaterLevelDashboard';

import './style.scss';

const WaterLevelPanel = ({
	isOpen,
	setOpen,
	selectedFeatureKeys,
	activePlaceKey,
	onActivePlaceChange,
	onSelectedFeaturesChange,
	onMount,
	onUnmount,
}) => {
	const chartRef = useRef();
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	useEffect(() => {
		if (selectedFeatureKeys?.length && onSelectedFeaturesChange) {
			onSelectedFeaturesChange(selectedFeatureKeys);
		}
	}, [selectedFeatureKeys]);

	useEffect(() => {
		if (onActivePlaceChange) {
			onActivePlaceChange();
		}
	}, [activePlaceKey]);

	const classes = classNames('worldWater-WaterLevelPanel', {
		'is-open': isOpen,
	});

	return (
		<div className={classes}>
			<PanelControlButton
				collapsed={!isOpen}
				onClick={() => setOpen(!isOpen)}
			/>
			<div className="worldWater-WaterLevelPanel-content">
				<WaterLevelPanelHeader chartRef={chartRef} />
				<WaterLevelDashboard
					noData={!selectedFeatureKeys?.length}
					chartRef={chartRef}
				/>
			</div>
		</div>
	);
};

WaterLevelPanel.propTypes = {
	activePlaceKey: PropTypes.string,
	isOpen: PropTypes.bool,
	setOpen: PropTypes.func,
	selectedFeatureKeys: PropTypes.array,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	onSelectedFeaturesChange: PropTypes.func,
	onActivePlaceChange: PropTypes.func,
};

export default WaterLevelPanel;
