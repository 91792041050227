import PropTypes from 'prop-types';
import './style.scss';

export const MetadataItemInfoSection = ({children}) => {
	return (
		<div className="worldWater-Metadata-LayerTemplateItemSection">
			{children}
		</div>
	);
};

MetadataItemInfoSection.propTypes = {
	children: PropTypes.node,
};

export const MetadataItemInfoSectionTitle = ({title}) => {
	return (
		<h4 className="worldWater-Metadata-LayerTemplateItemSection-title">
			{title}
		</h4>
	);
};

MetadataItemInfoSectionTitle.propTypes = {
	title: PropTypes.string,
};

export const MetadataItemInfoSectionParagraph = ({title, text}) => {
	return (
		<p className="worldWater-Metadata-LayerTemplateItemSection-paragraph">
			<span className="worldWater-Metadata-LayerTemplateItemSection-paragraphTitle">
				{title}
			</span>
			{text}
		</p>
	);
};

MetadataItemInfoSectionParagraph.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
};

export const MetadataItemInfoSectionLegendInfo = ({items}) => {
	return (
		<div className="worldWater-Metadata-LayerTemplateItemSection-legendInfo">
			<span className="worldWater-Metadata-LayerTemplateItemSection-legendInfoTitle">
				Legend description:
			</span>
			{items.map((item, index) => {
				return <span key={index}>{item} </span>;
			})}
		</div>
	);
};

MetadataItemInfoSectionLegendInfo.propTypes = {
	items: PropTypes.array,
};

export const MetadataItemInfoSectionImage = ({imgUrl}) => {
	return (
		<img
			className="worldWater-Metadata-LayerTemplateItemSection-image"
			src={imgUrl}
			alt="Image data example"
		/>
	);
};

MetadataItemInfoSectionImage.propTypes = {
	imgUrl: PropTypes.string,
};
