import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import classnames from 'classnames';

import Header from './components/ProductExplorerHeader';
import WaterExtentPanel from './components/WaterExtentPanel';
import WaterLevelPanel from './components/WaterLevelPanel';
import Maps from './components/Maps';

import './style.scss';

const getComponentStyle = component => {
	return {
		...(component.size ? {height: component.size} : {}),
	};
};

const getLayoutComponentByKey = (componentKey, style) => {
	switch (componentKey) {
		case 'WaterExtentPanel':
			return <WaterExtentPanel key="waterExtentPanel" style={style} />;
		case 'WaterLevelPanel':
			return <WaterLevelPanel key="waterLevelPanel" style={style} />;
		default:
			return null;
	}
};

const getCenterColumn = layout => {
	let rows = [<Maps key={'centerMapContent'} />];
	if (layout) {
		for (const part of Object.keys(layout)) {
			const style = getComponentStyle(layout[part]);
			const Component = layout[part]?.component
				? getLayoutComponentByKey(layout[part].component, style)
				: null;

			if (Component && part === 'top') {
				rows = [Component, ...rows];
			}

			if (Component && part === 'bottom') {
				rows = [...rows, Component];
			}
		}
	}
	return <div className={'ptr-layout-columns ptr-center-column'}>{rows}</div>;
};

const getContent = layout => {
	let columns = [getCenterColumn(layout)];
	if (!layout) {
		return columns;
	} else {
		for (const part of Object.keys(layout)) {
			const Component = layout[part]?.component
				? getLayoutComponentByKey(layout[part].component)
				: null;
			const column = Component ? Component : null;

			if (part === 'left') {
				columns = [column, ...columns];
			}

			if (part === 'right') {
				columns = [...columns, column];
			}
		}
		return columns;
	}
};

const ProductExplorer = ({
	placeKey,
	isDark,
	loadPlaceByKey,
	layout,
	onUnmount,
	activeViewKey,
	ensureView,
}) => {
	const firstRender = useRef();

	useEffect(() => {
		if (placeKey) {
			loadPlaceByKey(placeKey);
		}
	}, [placeKey]);

	useEffect(() => {
		return onUnmount;
	}, []);

	useEffect(() => {
		if (activeViewKey) {
			ensureView(activeViewKey, firstRender.current === undefined, placeKey);

			if (firstRender.current === undefined) {
				firstRender.current = false;
			}
		}
	}, [activeViewKey]);

	const content = getContent(layout);
	const classes = classnames('worldWater-productExplorer', {
		'ptr-dark': isDark,
	});

	return (
		<div className={classes}>
			<Header />
			<div className={'worldWater-productExplorer-content'}>{content}</div>
		</div>
	);
};

ProductExplorer.propTypes = {
	activeViewKey: PropTypes.string,
	isDark: PropTypes.bool,
	ensureView: PropTypes.func,
	layout: PropTypes.object,
	loadPlaceByKey: PropTypes.func,
	onUnmount: PropTypes.func,
	placeKey: PropTypes.string,
};

export default ProductExplorer;
