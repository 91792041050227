import PropTypes from 'prop-types';
import './style.scss';

const StatisticsLayerControl = ({name}) => {
	return (
		<div className="worldWater-StatisticsLayerControl">
			<input
				disabled
				type="checkbox"
				className="worldWater-StatisticsLayerControl-checkboxInput"
			></input>
			<span>{name}</span>
		</div>
	);
};

StatisticsLayerControl.propTypes = {
	name: PropTypes.string,
};

export default StatisticsLayerControl;
