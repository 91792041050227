import {
	Children,
	cloneElement,
	isValidElement,
	useContext,
	useRef,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Context from '@gisatcz/cross-package-react-context';
import './style.scss';

const defaultHoverContext = 'HoverContext';

const getHoverContentCommon = content => {
	if (content?.points) {
		if (!content?.points?.find(point => point?.data?.noHover)) {
			return (
				<div className="worldWater-nivo-tooltip">
					<div className="worldWater-nivo-tooltip-content">
						<h3 className="worldWater-nivo-tooltip-time-alternative">
							{moment(content?.points?.[0]?.data?.x).format('MMMM YYYY')}{' '}
						</h3>
						{content?.points.map(point => (
							<div
								className="worldWater-nivo-tooltip-item-alternative"
								key={point?.id}
							>
								<span
									className="worldWater-nivo-tooltip-color-alternative"
									style={{
										background: point?.data?.color || point?.color,
									}}
								/>
								<span className="worldWater-nivo-tooltip-label">
									{point?.serieId}:{' '}
								</span>
								<span className="worldWater-nivo-tooltip-value">
									{point?.data?.y?.toFixed(2)}
								</span>
								<span className="worldWater-nivo-tooltip-deviation">
									{'[sqkm]'}
								</span>
							</div>
						))}
					</div>
				</div>
			);
		} else {
			return null;
		}
	} else {
		if (!content?.data?.noHover) {
			const deviation = content.data[content?.data?.deviationType];
			return (
				<div className="worldWater-nivo-tooltip">
					<div className="worldWater-nivo-tooltip-content">
						<h2>
							<span
								className="worldWater-nivo-tooltip-color"
								style={{background: content?.data?.color || content.color}}
							/>
							{content?.data?.pointId}
						</h2>
						<span className="worldWater-nivo-tooltip-time">
							{moment(content?.data?.x).format('D MMMM YYYY')}
						</span>
						<div>
							<span className="worldWater-nivo-tooltip-label">
								{content?.data?.type}:
							</span>
							<span className="worldWater-nivo-tooltip-value">
								{content?.data?.y?.toLocaleString(undefined, {
									maximumFractionDigits: 2,
								})}
								<span>{deviation ? null : content?.data?.typeUnit}</span>
							</span>
							{deviation ? (
								<span className="worldWater-nivo-tooltip-deviation">
									{'±'} {deviation?.toFixed(2)} {content?.data?.typeUnit}
								</span>
							) : null}
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
};

const NivoChartHover = ({children, getHoverContent}) => {
	const hoverContext = Context.getContext(defaultHoverContext);
	const context = useContext(hoverContext);
	const hoverPointIdRef = useRef(null);

	const onHover = evt => {
		if (evt && hoverPointIdRef.current !== evt.id) {
			const popupContent =
				(evt && getHoverContent && getHoverContent(evt, context)) ||
				(evt && getHoverContentCommon(evt, context)) ||
				null;
			if (
				popupContent &&
				(isValidElement(popupContent) || popupContent?.popup?.content)
			) {
				const hoverItem = {
					key: 'nivoChartHover',
					x: evt.x,
					y: evt.y,
					point: evt,
				};

				context.hoveredItems = [hoverItem];
				context.onHover([hoverItem], {
					popup: {
						x: evt.x,
						y: evt.y,
						content: popupContent,
					},
				});
			} else {
				context.onHoverOut();
			}
			hoverPointIdRef.current = evt.id;
		} else if (!evt) {
			hoverPointIdRef.current = null;
			context.hoveredItems = [];
			context.onHoverOut();
		}
	};
	const childrenElms = Children.map(children, child =>
		cloneElement(child, {
			...child.props,
			onHover,
			hovered: context?.hoveredItems,
		})
	);

	return <>{childrenElms}</>;
};

NivoChartHover.propTypes = {
	children: PropTypes.node,
	getHoverContent: PropTypes.func,
};

export default NivoChartHover;
