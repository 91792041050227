import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSection from '../components/TourStepsSection';
import TourStepsTitle from '../components/TourStepsTitle';

import '../style.scss';

const Header = () => (
	<TourStepsContainer>
		<TourStepsTitle>Header</TourStepsTitle>
		<TourStepsSection>
			Header serves for navigation in the application and indication of selected
			options.
		</TourStepsSection>
		<TourStepsSection>
			<div>
				<b>Title</b> - Go back to the home page.
			</div>
			<div>
				<b>Area of interest</b> - Open the world map to select an area of
				interest.
			</div>
			<div>
				<b>View</b> - Go to the view selection.
			</div>
			<div>
				<b>Tour icon</b> - Open the tour.
			</div>
		</TourStepsSection>
	</TourStepsContainer>
);

export default Header;
