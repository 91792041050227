import classNames from 'classnames';

const WaterLevelMapLegend = () => {
	return (
		<div className={classNames('worldWater-MapLegends', {}, 'is-left')}>
			<div
				className={'worldWater-StyleBasedLegend'}
				style={{width: 'fit-content', padding: '0.7rem 0.9rem'}}
			>
				<div className={'worldWater-MapLegendTitle'}>
					<span className={'worldWater-MapLegendTitle-name'}>
						<div className={'worldWater-MapLegendTitle-name'}>
							Body of water
						</div>
					</span>
				</div>
				<div className={'worldWater-MapLegendContent'}>
					<div className={'worldWater-MapLegend-AttributeValues'}>
						<div className={'worldWater-MapLegendValueItem'}>
							<div
								style={{
									background: '#17619a',
									border: '1px solid #05395e',
								}}
							></div>
							<span>River</span>
						</div>
						<div className={'worldWater-MapLegendValueItem'}>
							<div
								style={{
									background: '#71baf1',
									border: '1px solid #2f7db0',
								}}
							></div>
							<span>Lake</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

WaterLevelMapLegend.propTypes = {};

export default WaterLevelMapLegend;
