import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const activeLevel = Select.areas.areaTreeLevels.getActive(state)?.data?.level;
	const featureProps = ownProps.vector?.[0]?.object?.properties;
	const featureKey = featureProps?.g;
	const name = activeLevel === 1 ? featureProps?.c : featureProps?.HYBAS_ID;
	const showTooltip =
		activeLevel === 1
			? Select.worldWater.statistics.isCountryAvailableByFeatureKey(
					state,
					featureKey
			  )
			: true;
	return {
		showTooltip: showTooltip && !featureProps?.noData,
		name,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
