import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = state => {
	return {
		mode: Select.worldWater.statistics.getStatsMode(state),
		isOpen: Select.components.get(state, 'StatisticsPanel', 'isOpen'),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
