import config from '../../../../config';
import Select from '../../../Select';
import {Action as CommonAction} from '@gisatcz/ptr-state';
import request from '../../../../utils/request';
import ActionTypes from '../../../../constants/ActionTypes';
import waterLevelMapLayerStyle from '../../../../data/mapStyles/waterLevelMapLayerStyle';

/**
 * Use data for WaterLevel vector layer
 */
const use = placeName => {
	return dispatch => {
		dispatch(ensureDataForPlace(placeName)).then(() => {
			dispatch(refreshMapLayer(placeName));
		});
	};
};

const refreshMapLayer = placeName => {
	return (dispatch, getState) => {
		const state = getState();
		const layerKey = Select.app.getConfiguration(
			state,
			'waterLevel.dataLayerKey'
		);
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
		const features = Select.worldWater.waterLevel.features.getByPlace(
			state,
			placeName
		);
		const mapLayerDefinition = Select.maps.getLayerStateByLayerKeyAndMapKey(
			state,
			mapKey,
			layerKey
		);

		if (mapKey && layerKey && features) {
			dispatch(
				CommonAction.maps.setMapLayerOption(
					mapKey,
					layerKey,
					'features',
					features || []
				)
			);
		}

		if (!mapLayerDefinition?.options?.style) {
			dispatch(
				CommonAction.maps.setMapLayerOption(
					mapKey,
					layerKey,
					'style',
					waterLevelMapLayerStyle.data.definition
				)
			);
		}
	};
};

/**
 * @param place {string} Panther place's nameInternal, which is used as directory name
 */
const ensureDataForPlace = place => {
	return dispatch => {
		// TODO for now, request allways for data
		let url = `${config.productExplorerDataRepository}/${place}/reference.geojson`;

		return request(url, 'GET', null, null).then(data => {
			if (data) {
				const features = data?.features.filter(feature => {
					const coord = feature?.geometry.coordinates;
					return (
						coord[0] &&
						coord[0] >= -180 &&
						coord[0] <= 180 &&
						coord[1] &&
						coord[1] >= -90 &&
						coord[1] <= 90
					);
				});

				dispatch(actionAdd(place, features));
			} else {
				console.warn(`No data for place ${place}!`);
			}
		});
	};
};

const actionAdd = (place, features) => {
	return {
		type: ActionTypes.WORLD_WATER.WATER_LEVEL.FEATURES.ADD,
		place,
		features,
	};
};

export default {use};
