import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const Card = ({children, className, floating, disabled}) => {
	const classes = classnames(
		'ptr-Card',
		{
			'is-disabled': disabled,
			'is-floating': floating,
		},
		className
	);

	return <div className={classes}>{children}</div>;
};

Card.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	floating: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default Card;
