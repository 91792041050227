import {connect} from '@gisatcz/ptr-state';

import Action from '../../../../../../../../state/Action';
import Select from '../../../../../../../../state/Select';

import Index from '../../../../../../../common/Switcher';

const mapStateToProps = state => {
	return {
		activeOptionKey:
			Select.worldWater.waterLevel.valueTypes.getActiveKey(state),
		options: Select.worldWater.waterLevel.valueTypes.getAll(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onActiveOptionChange: key => {
			dispatch(Action.worldWater.waterLevel.valueTypes.setActiveKey(key));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
