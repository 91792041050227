import {
	MetadataItemInfoSection,
	MetadataItemInfoSectionImage,
	MetadataItemInfoSectionLegendInfo,
	MetadataItemInfoSectionParagraph,
	MetadataItemInfoSectionTitle,
} from '../components';

const ChangeWaterPresence = () => (
	<MetadataItemInfoSection>
		<MetadataItemInfoSectionTitle title="Change maps of monthly surface water presence (between same months of the year)" />
		<MetadataItemInfoSectionParagraph text="For every pixel, a change (gain / loss) – no change analysis is performed within the same month of two consecutive years (e.g. January 2019 vs. January 2020). This was calculated for every month between 2017-2018, 2018-2019, 2019-2020 and 2020-2021." />
		<MetadataItemInfoSectionParagraph title="Frequency:" text=" Monthly." />
		<MetadataItemInfoSectionLegendInfo
			items={[
				'0: Unchanged not water',
				'1: Gain water',
				'2: Loss water',
				'3: Unchanged water',
				'255: No data',
			]}
		/>
		<MetadataItemInfoSectionParagraph
			title="Image:"
			text=" Rio Magdalena and swamp complex near Magangué, Colombia."
		/>
		<MetadataItemInfoSectionImage
			imgUrl={require('../../../../../../../../../../data/metadata/img/change_water_presence.jpg')}
		/>
	</MetadataItemInfoSection>
);

export default ChangeWaterPresence;
