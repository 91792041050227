import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import '../../style.scss';

const Header = () => (
	<TourStepsContainer>
		<TourStepsTitle>Header</TourStepsTitle>
		<TourStepsSection>
			Header serves for configuration of the application and indication of
			selected options.
		</TourStepsSection>
		<TourStepsSection>
			<div>
				<b>Title</b> - Go back to the home page.
			</div>
			<div>
				<b>Area of interest</b> - Select area of interest (you can choose
				multiple areas for comparison).
			</div>
			<div>
				<b>Hydrobasins</b> - Select hydrobasin (you can choose multiple
				hydrobasins for comparison).
			</div>
			<div>
				<b>AOI/Hydrobasins switch</b> - Switch between levels.
			</div>
			<div>
				<b>Tour icon</b> - Open the tour.
			</div>
		</TourStepsSection>
	</TourStepsContainer>
);

export default Header;
