import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.worldWater.waterLevel.points;
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

export default {
	getAllAsObject,
	getByKey,
};
