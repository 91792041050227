import {createElement} from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const MapLegends = ({componentsByLayer, mapKey, className, configuration}) => {
	if (componentsByLayer) {
		return (
			<div className={className}>
				{configuration?.map(legendConfiguration => {
					return createElement(legendConfiguration?.legend?.component, {
						...legendConfiguration?.legend.props,
						mapKey,
						layerTemplateKeys: legendConfiguration.layerTemplateKeys,
						styleKey: legendConfiguration.styleKey,
						key: legendConfiguration.styleKey,
					});
				})}
			</div>
		);
	} else {
		return null;
	}
};

MapLegends.propTypes = {
	className: PropTypes.string,
	componentsByLayer: PropTypes.array,
	mapKey: PropTypes.string,
	configuration: PropTypes.array,
};

export default MapLegends;
