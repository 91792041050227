import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import {
	waterExtentTimeline,
	waterLevelPanel,
} from '../../../../../constants/app';

const mapStateToProps = state => {
	const mapSetKey = Select.maps.getActiveSetKey(state);
	const waterLevelPanelOpen = Select.components.get(
		state,
		waterLevelPanel,
		'open'
	);
	const timelineOpen = Select.components.get(
		state,
		waterExtentTimeline,
		'open'
	);

	return {
		mapSetKey,
		maps: Select.maps.getMapSetMaps(state, mapSetKey),
		mode: Select.components.get(state, 'Maps', 'mode'),
		// TODO better, special selector?
		waterLevelPanelOpen,
		layerTemplateKeysForLegend:
			Select.worldWater.getLayerTemplateConfigurations(state),
		timelineOpen,
		activeView: Select.views.getActive(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
