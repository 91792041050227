import TourStepsContainer from '../../components/TourStepsContainer';
import TourStepsSection from '../../components/TourStepsSection';
import TourStepsTitle from '../../components/TourStepsTitle';

import '../../style.scss';

const Map = () => (
	<TourStepsContainer>
		<TourStepsTitle>Water level and volume</TourStepsTitle>
		<TourStepsSection>
			This view is dedicated to working with WorldWater surface water elevation,
			extent and volume products. It is possible to select either one (click on
			point) or multiple features (press CTRL / ⌘ + click on points).
		</TourStepsSection>
	</TourStepsContainer>
);

export default Map;
