import PropTypes from 'prop-types';
import './style.scss';
import DownloadChart from '../../../../../../common/DownloadChart';

const WaterLevelDownload = ({chartRef, data, activeIndicatorKey}) => {
	let label = 'Water level over time [m]';
	if (activeIndicatorKey === 'waterVolume') {
		label = 'Water volume over time [m3]';
	} else if (activeIndicatorKey === 'waterExtent') {
		label = 'Water extent over time [km2]';
	}
	return (
		<DownloadChart
			chartRef={chartRef?.current ? chartRef : null}
			data={data}
			label={label}
		/>
	);
};

WaterLevelDownload.propTypes = {
	data: PropTypes.array,
	chartRef: PropTypes.object,
	activeIndicatorKey: PropTypes.string,
};

export default WaterLevelDownload;
