import {createSelector} from 'reselect';
import {
	commonHelpers,
	commonSelectors,
	createRecomputeObserver,
	createRecomputeSelector,
	Select as CommonSelect,
} from '@gisatcz/ptr-state';

import {
	timelineLayerElementHeight,
	timelineLayerLineHeight,
} from '../../../constants/app';

const getColorsForLayertemplate = (layerTemplateKey, configuration) => {
	return (
		configuration?.configurationByLayerTemplateKeys?.[layerTemplateKey]
			?.timelineColor || {}
	);
};

const getLayers = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		CommonSelect.places.getActiveKey,
		CommonSelect.app.getCompleteConfiguration,
	],
	(timelineComponent, activePlaceKey, configuration) => {
		const timelineLayers = [];

		if (
			activePlaceKey &&
			timelineComponent &&
			timelineComponent[activePlaceKey]
		) {
			const layerTemplateKeys =
				timelineComponent[activePlaceKey].layerTemplateKeys;
			layerTemplateKeys?.forEach((layerTemplateKey, i) => {
				const colors = getColorsForLayertemplate(
					layerTemplateKey,
					configuration
				);

				timelineLayers.push({
					lineHeight: timelineLayerLineHeight,
					elementHeight: timelineLayerElementHeight,
					legend: {
						layerTemplateKey,
					},
					items: [
						{
							periods: {
								filter: {
									layerTemplateKey,
								},
								filterByActive: {
									application: true,
									place: true,
								},
							},
							colors: {
								basic: colors.basic,
								active: colors.active,
							},
							states: ['basic', 'active', 'hover', 'disabled'],
							activeStates: ['basic'],
							mapZIndex: i,
							layerState: {
								layerTemplateKey,
								filterByActive: {
									application: true,
									place: true,
								},
							},
						},
					],
					controlMapState: true,
					allowNonActiveLayer: true,
				});
			});
		}

		return timelineLayers;
	}
);

const isWaterExtentView = createSelector(
	[CommonSelect.views.getActive],
	activeView => {
		return activeView?.data?.nameInternal === 'extent';
	}
);

const getSpatialDataSourceByLayerStateObserver = createRecomputeObserver(
	(state, layerState) => {
		// modifiers defined by key
		let metadataDefinedByKey = layerState.metadataModifiers
			? {...layerState.metadataModifiers}
			: {};

		// add layerTemplate od areaTreeLevelKey
		if (layerState.layerTemplateKey) {
			metadataDefinedByKey.layerTemplateKey = layerState.layerTemplateKey;
			// TODO use layerTemplate here?
		} else if (layerState.areaTreeLevelKey) {
			metadataDefinedByKey.areaTreeLevelKey = layerState.areaTreeLevelKey;
			// TODO use areaTreeLevelKey here?
		}

		// Get actual metadata keys defined by filterByActive
		const activeMetadataKeys = layerState.filterByActive
			? commonSelectors.getActiveKeysByFilterByActive(
					state,
					layerState.filterByActive
			  )
			: null;

		// Merge metadata, metadata defined by key have priority
		const mergedMetadataKeys = commonHelpers.mergeMetadataKeys(
			metadataDefinedByKey,
			activeMetadataKeys
		);

		// Decouple modifiers from templates
		// eslint-disable-next-line no-unused-vars
		const {areaTreeLevelKey, layerTemplateKey, ...modifiers} =
			mergedMetadataKeys;

		// It converts modifiers from metadataKeys: ["A", "B"] to metadataKey: {in: ["A", "B"]}
		const modifiersForRequest =
			commonHelpers.convertModifiersToRequestFriendlyFormat(modifiers);

		const commonRelationsFilter = {
			...(modifiersForRequest && {modifiers: modifiersForRequest}),
			layerTemplateKey,
		};

		const order = null;

		const spatialDataSources = CommonSelect.data.spatialDataSources.getIndexed(
			state,
			commonRelationsFilter,
			order
		);

		return spatialDataSources?.[0];
	}
);
/**
 *
 */
const getDownloadUrlByLayerState = createRecomputeSelector(layerState => {
	const sds = getSpatialDataSourceByLayerStateObserver(layerState);
	return sds?.data?.configuration?.source;
});

const getWmsUrlByLayerState = createRecomputeSelector(layerState => {
	const sds = getSpatialDataSourceByLayerStateObserver(layerState);
	return sds?.data?.url;
});

const getLayerTemplateBadgeColors = createSelector(
	[
		CommonSelect.app.getCompleteConfiguration,
		(state, layerTemplateKey) => layerTemplateKey,
	],
	(configuration, layerTemplateKey) => {
		return getColorsForLayertemplate(layerTemplateKey, configuration);
	}
);

export default {
	getDownloadUrlByLayerState,
	getLayerTemplateBadgeColors,
	getWmsUrlByLayerState,
	getLayers,
	isWaterExtentView,
};
