import PropTypes from 'prop-types';
import StatisticsPanelSection from '../../components/StatisticsPanelSection';
import AdvancedStatistics from '../../components/StatisticsPanelSection/AdvancedStatistics';
import SingleCountryCharts from '../SingleCountryCharts';
import SingleCountryAttribute from '../SingleCountryAttribute';

import './style.scss';
import StatisticsLayers from '../../components/StatisticsLayers';
import SwcAttribute from '../../components/SwcAttribute';

const SingleCountryStatistics = ({sections}) => {
	return (
		<>
			{sections.map(section => {
				const {key, title, attributes, charts, layers} = section;
				const advancedStatistics =
					charts || layers ? (
						<AdvancedStatistics>
							{layers?.length ? <StatisticsLayers layers={layers} /> : null}
							{charts ? <SingleCountryCharts charts={charts} /> : null}
						</AdvancedStatistics>
					) : null;

				return (
					<StatisticsPanelSection
						key={key}
						sectionKey={key}
						heading={title}
						AdvancedStatistics={advancedStatistics}
					>
						{attributes.map(attribute => {
							return (
								<SingleCountryAttribute
									key={attribute}
									attributeKey={attribute}
								/>
							);
						})}
						{key === 'surface-water-changes' ? (
							<>
								<SwcAttribute
									attributeMetadata={{
										data: {nameDisplay: 'Gain water - max', unit: 'sqkm'},
									}}
									attributeKey="swcmaxA"
									attributeLatestKey="swcmaxB"
									type="gain"
									store="countries"
								/>
								<SwcAttribute
									attributeMetadata={{
										data: {nameDisplay: 'Gain water - min', unit: 'sqkm'},
									}}
									attributeKey="swcminA"
									attributeLatestKey="swcminB"
									type="gain"
									store="countries"
								/>
								<SwcAttribute
									attributeMetadata={{
										data: {nameDisplay: 'Loss water - max', unit: 'sqkm'},
									}}
									attributeKey="swcmaxA"
									attributeLatestKey="swcmaxB"
									type="loss"
									store="countries"
								/>
								<SwcAttribute
									attributeMetadata={{
										data: {nameDisplay: 'Loss water - min', unit: 'sqkm'},
									}}
									attributeKey="swcminA"
									attributeLatestKey="swcminB"
									type="loss"
									store="countries"
								/>
							</>
						) : null}
					</StatisticsPanelSection>
				);
			})}
		</>
	);
};

SingleCountryStatistics.propTypes = {
	sections: PropTypes.array,
};

export default SingleCountryStatistics;
