import PropTypes from 'prop-types';
import classnames from 'classnames';
import HeaderTitle from '../../../common/HeaderTitle';
import Header from '../../../common/Header';
import PlaceSelect from './PlaceSelect';
import StatisticsHeaderControls from './StatisticsHeaderControls';
import LevelSwitch from './LevelSwitch';
import PeriodSwitch from './PeriodSwitch';
import HydrobasinSelect from './HydrobasinSelect';

import './style.scss';
import TourIcon from '../../../common/TourIcon/presentation';

const StatisticsHeader = ({activePlaceKeys, activeLevel}) => {
	const selectedPlaceControls = classnames('worldWater-selectedPlaceControls', {
		'is-open': activePlaceKeys?.length,
	});

	return (
		<Header>
			<HeaderTitle>Statistics</HeaderTitle>
			<StatisticsHeaderControls>
				<PlaceSelect />
				<div className={selectedPlaceControls}>
					<HydrobasinSelect
						disabled={activeLevel === 1 || activePlaceKeys > 1}
					/>
					<LevelSwitch />
					<PeriodSwitch />
				</div>
			</StatisticsHeaderControls>
			<div className="worldWater-Statistics-Tools">
				<TourIcon />
			</div>
		</Header>
	);
};

StatisticsHeader.propTypes = {
	activeLevel: PropTypes.number,
	activePlaceKeys: PropTypes.array,
};

export default StatisticsHeader;
