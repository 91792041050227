import {createSelector} from 'reselect';
import {forIn as _forIn, includes as _includes} from 'lodash';
import {Select, Select as CommonSelect} from '@gisatcz/ptr-state';
import features from './features/selectors';
import basin from './basin/selectors';
import countries from './countries/selectors';
import statisticsConfig from '../../../data/statisticsConfig';
import helpers from './helpers';
import classes_enumerations from '../../../data/classes_enumerations';
import utils from '../../../utils';
import {multiplePointSelectionColors} from '../../../constants/app';

const getSectionsByType = type => statisticsConfig.getSectionsByType[type];
const isMultiselectEnabled = () => statisticsConfig.multiselectEnabled;

const getStatsMode = createSelector(
	[
		CommonSelect.places.getActiveKeys,
		CommonSelect.areas.areaTreeLevels.getActive,
		CommonSelect.selections.getActive,
	],
	(activePlaceKeys, activeAreaLevelModel, activeSelectionModel) => {
		const numOfActivePlaces = activePlaceKeys?.length;
		const activeLevel = activeAreaLevelModel?.data?.level;
		const numOfSelectedFeatures =
			activeSelectionModel?.data?.featureKeysFilter?.keys?.length;

		if (numOfActivePlaces > 1) {
			return 'countries';
		} else {
			if (activeLevel === 2) {
				if (numOfSelectedFeatures > 1) {
					return 'hydrobasins';
				} else if (numOfSelectedFeatures === 1) {
					return 'hydrobasin';
				} else {
					return 'country';
				}
			} else {
				return 'country';
			}
		}
	}
);

const isMultiselectMode = createSelector([getStatsMode], mode => {
	return !!(mode === 'countries' || mode === 'hydrobasins');
});

const getHydrobasinsForActivePlace = createSelector(
	[
		features.getAllAsObject,
		CommonSelect.places.getActiveKeys,
		state =>
			CommonSelect.app.getConfiguration(state, 'placeKeyByCountryFeatureKey'),
	],
	(featuresByCountryFeatureKey, activePlaceKeys, config) => {
		if (
			featuresByCountryFeatureKey &&
			activePlaceKeys?.length === 1 &&
			config
		) {
			const activePlaceKey = activePlaceKeys[0];
			let featureKey = null;
			_forIn(config, (value, key) => {
				if (value === activePlaceKey) {
					featureKey = key;
				}
			});
			return (
				featuresByCountryFeatureKey[featureKey]?.filter(hydrobasin => {
					return !hydrobasin.properties.noData;
				}) || null
			);
		} else {
			return null;
		}
	}
);

const isCountryAvailableByFeatureKey = createSelector(
	[
		() => statisticsConfig.availableCountries,
		state =>
			CommonSelect.app.getConfiguration(state, 'placeKeyByCountryFeatureKey'),
		(state, featureKey) => featureKey,
	],
	(availablePlaces, placeByFeatureKey, featureKey) => {
		if (availablePlaces?.length && placeByFeatureKey && featureKey) {
			const placeKey = placeByFeatureKey[featureKey];
			return placeKey && _includes(availablePlaces, placeKey);
		} else {
			return false;
		}
	}
);

const getAvailableCountries = createSelector(
	[CommonSelect.places.getIndexed, () => statisticsConfig.availableCountries],
	(places, availablePlaces) => {
		if (places && availablePlaces) {
			return places.filter(place => {
				return _includes(availablePlaces, place.key);
			});
		} else {
			return null;
		}
	}
);

// Charts
const getWpChartData = createSelector(
	[
		Select.selections.getActive,
		(state, store) =>
			store === 'countries'
				? countries.getStatsForActiveSelection(state)
				: basin.getStatsForActiveSelection(state), // TODO find better solution
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'valueType'),
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'dataType'),
		(state, store, chartKey, sources) => sources,
		(state, store, chartKey, sources, isMultiselect) => isMultiselect,
	],
	(activeSelection, data, valueType, dataType, sources, isMultiselect) => {
		if (data?.[0] && sources) {
			let finalChartData = [];
			let finalDownloadData = [];

			data.forEach(area => {
				if (area) {
					const {key: featureKey, data: featureData} = area;
					const chartData = helpers.getPreparedData(
						featureData,
						sources,
						valueType,
						isMultiselect
					);

					chartData.map(item => {
						const {chart, download} = item;
						let id = featureKey;

						finalChartData.push({
							id: id,
							data: chart?.data,
							color: utils.getSelectedFeaturePrimaryColor(
								featureKey,
								activeSelection?.data
							),
						});

						finalDownloadData.push({
							areaId: id,
							data: download?.data,
						});
					});
				}
			});

			return {chart: finalChartData, download: finalDownloadData};
		} else {
			return null;
		}
	}
);

const getSpweChartData = createSelector(
	[
		Select.selections.getActive,
		(state, store) =>
			store === 'countries'
				? countries.getStatsForActiveSelection(state)
				: basin.getStatsForActiveSelection(state), // TODO find better solution
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'valueType'),
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'selectedClass'),
		(state, store, chartKey, sources) => sources,
		(state, store, chartKey, sources, isMultiselect) => isMultiselect,
	],
	(activeSelection, data, valueType, selectedClass, sources, isMultiselect) => {
		if (data?.[0] && sources) {
			let filteredSources;
			let finalChartData = [];
			let finalDownloadData = [];

			if (isMultiselect && selectedClass) {
				filteredSources = sources.filter(source => {
					if (selectedClass === 'permanent') {
						return source.attribute === 'pswe';
					} else if (selectedClass === 'seasonal') {
						return source.attribute === 'sswe';
					}
				});
			}

			data.forEach(area => {
				if (area) {
					const {key: featureKey, data: featureData} = area;

					const chartData = helpers.getPreparedData(
						featureData,
						filteredSources || sources,
						valueType,
						isMultiselect
					);

					const numOfSeries = chartData?.length;
					if (numOfSeries) {
						chartData?.map((item, i) => {
							const {chart, download} = item;
							const cls = chart?.data?.[0]?.class;

							const classId = classes_enumerations[chart?.attribute]?.[cls];
							let id = featureKey;

							if (numOfSeries > 1 && !isMultiselect) {
								if (cls) {
									id = classId || featureKey;
								}
							}

							finalChartData.push({
								id,
								data: chart?.data,
								color: isMultiselect
									? utils.getSelectedFeaturePrimaryColor(
											id,
											activeSelection?.data
									  )
									: multiplePointSelectionColors[i],
							});

							finalDownloadData.push({
								class: classId,
								areaId: featureKey,
								data: download?.data,
							});
						});
					} else {
						return null;
					}
				}
			});

			return {chart: finalChartData, download: finalDownloadData};
		} else {
			return null;
		}
	}
);

const getSweChartData = createSelector(
	[
		Select.selections.getActive,
		(state, store) =>
			store === 'countries'
				? countries.getStatsForActiveSelection(state)
				: basin.getStatsForActiveSelection(state), // TODO find better solution
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'valueType'),
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'selectedClass'),
		(state, store, chartKey, sources) => sources,
		(state, store, chartKey, sources, isMultiselect) => isMultiselect,
	],
	(activeSelection, data, valueType, selectedClass, sources, isMultiselect) => {
		if (data?.[0] && sources?.[0]) {
			let finalChartData = [];
			let finalDownloadData = [];
			data.forEach(area => {
				if (area) {
					const {key: featureKey, data: featureData} = area;
					const chartData = helpers.getPreparedData(
						featureData,
						sources,
						valueType,
						isMultiselect
					);

					if (chartData) {
						chartData.map(item => {
							const {chart, download} = item;
							if (chart?.data?.length) {
								chart.data.map((cls, i) => {
									const {attribute} = chart;
									const id = classes_enumerations[attribute]?.[cls.class];
									if (
										!isMultiselect ||
										(isMultiselect && cls.class == selectedClass)
									) {
										finalChartData.push({
											data: cls.data,
											id: isMultiselect ? featureKey : id,
											color: isMultiselect
												? utils.getSelectedFeaturePrimaryColor(
														featureKey,
														activeSelection?.data
												  )
												: multiplePointSelectionColors[i],
										});
									}
								});
							}
							if (download?.data?.length) {
								download.data.map(cls => {
									const {attribute} = chart;
									const id = classes_enumerations[attribute]?.[cls.class];
									if (
										!isMultiselect ||
										(isMultiselect && cls.class == selectedClass)
									) {
										finalDownloadData.push({
											period: id,
											areaId: featureKey,
											data: cls.data,
										});
									}
								});
							}
						});
					} else {
						return null;
					}
				}
			});

			return {chart: finalChartData, download: finalDownloadData};
		} else {
			return null;
		}
	}
);

const getSwcChartData = createSelector(
	[
		(state, store) =>
			store === 'countries'
				? countries.getStatsForActiveSelection(state)
				: basin.getStatsForActiveSelection(state),
		(state, store, chartKey) =>
			Select.components.get(state, chartKey, 'valueType'),
		(state, store, chartKey, sources) => sources,
		(state, store, chartKey, sources, isMultiselect) => isMultiselect,
	],
	(data, valueType, sources, isMultiselect) => {
		if (data?.[0] && sources) {
			let finalChartData = [];
			let finalDownloadData = [];
			data.forEach(area => {
				if (area) {
					const {key: featureKey, data: featureData} = area;

					const chartData = helpers.getSwcPreparedData(
						featureData,
						sources,
						valueType,
						isMultiselect
					);

					finalChartData.push({
						id: featureKey,
						data: chartData?.chart,
					});
					finalDownloadData.push({
						areaId: featureKey,
						data: chartData?.download,
					});
				}
			});

			return {chart: finalChartData, download: finalDownloadData};
		} else {
			return null;
		}
	}
);

const getSwcAttributeData = createSelector(
	[
		(state, store) =>
			store === 'countries'
				? countries.getStatsForActiveSelection(state)
				: basin.getStatsForActiveSelection(state),
		(state, store, attributeKey) => attributeKey,
		(state, store, attributeKey, attributeLatestKey) => attributeLatestKey,
		(state, store, attributeKey, attributeLatestKey, type) => type,
	],
	(data, attributeKey, attributeLatestKey, type) => {
		if (data && attributeKey && attributeLatestKey && type) {
			const typeIndex = type === 'gain' ? 0 : 1;
			const areas = [];

			data.forEach(area => {
				if (area) {
					const latestValue =
						area.data[attributeLatestKey].charts[attributeLatestKey].data[
							typeIndex
						].value;
					const averageValue =
						area.data[attributeKey].charts[attributeKey].data[typeIndex].value;
					areas.push([
						{
							latest: {name: '2020-2021', value: latestValue},
							wholePeriod: {name: '2017-2021', value: averageValue},
						},
					]);
				}
			});

			return areas?.length ? areas : null;
		} else {
			return null;
		}
	}
);

export default {
	features,
	basin,
	countries,

	getAvailableCountries,
	getHydrobasinsForActivePlace,
	getSectionsByType,
	getStatsMode,

	isCountryAvailableByFeatureKey,
	isMultiselectEnabled,
	isMultiselectMode,

	getSpweChartData,
	getSweChartData,
	getWpChartData,
	getSwcChartData,

	getSwcAttributeData,
};
