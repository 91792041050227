/**
 * DEFAULT CONFIG VALUES FOR ALL APP INSTANCES
 *
 * Default valueTypes only.
 * Do not use this file for development, per-instance config, etc.
 */

export default {
	apiBackendPath: '',
	timeSerieDataUrl:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaWorldWater/productExplorer/timeseries',
	productExplorerDataRepository:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaWorldWater/productExplorer/timeseries',
	statisticsDataRepository:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaWorldWater/statistics',
	waterExtentStylesDataRepository:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaWorldWater/productExplorer/waterExtentStyles',
};
