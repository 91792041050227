import {createSelector} from 'reselect';
import {Select} from '@gisatcz/ptr-state';
import {statisticsCountrySelectionKey} from '../../../../constants/app';
import {isEmpty as _isEmpty} from 'lodash';

const getAllAsObject = state =>
	state.worldWater.statistics.countries.byPlaceNameInternal;

/**
 * @param {string} placeNameInternal
 * @returns {object}
 */
const getStatsByPlaceNameInternal = createSelector(
	[getAllAsObject, (state, placeNameInternal) => placeNameInternal],
	(data, placeNameInternal) => {
		if (data && placeNameInternal) {
			return data?.[placeNameInternal] || null;
		} else {
			return null;
		}
	}
);

const getStatsForActiveSelection = createSelector(
	[getAllAsObject, Select.selections.getAllAsObject],
	(data, selections) => {
		const countryKeys =
			selections[statisticsCountrySelectionKey]?.data.featureKeysFilter?.keys;
		if (!_isEmpty(data) && countryKeys?.length) {
			return countryKeys.map(countryKey => data?.[countryKey] || null);
		} else {
			return null;
		}
	}
);

const getDataForAttribute = createSelector(
	[getStatsForActiveSelection, (state, attribute) => attribute],
	(data, attribute) => {
		if (data?.length && attribute) {
			return data.map(item => item?.data?.[attribute]);
		} else {
			return null;
		}
	}
);

const getValuesForAttribute = createSelector([getDataForAttribute], data => {
	return data?.map(item => item?.values || null);
});

export default {
	getStatsForActiveSelection,
	getStatsByPlaceNameInternal,
	getValuesForAttribute,
};
